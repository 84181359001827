import { geturlPrefix } from "@src/utils/functions";
import React from "react";
import {Link} from "react-router-dom";

interface IProps {

}

let urlPrefix = geturlPrefix();

export const Banner = (props: IProps) => {

    return (
        <div className="ms-ai-hero-wrapper">
            <div className="ms-ai-hero-container">
                <div className="ms-hero-bg-img">
                    <img src={urlPrefix + "assets/images/main-large.png"} alt=""/>
                </div>
                <div className="ms-ai-hero-slider-wrapper ai-container">
                    <div className="ms-ai-hero-slider-container">
                        <div className="ms-ai-hero-slider-item">
                            {/* <div className="ms-cat-small-title">featured use case</div> */}
                            {/* <div className="ms-page-big-title">AI Design Wins</div> */}
                            {/* <div className="ms-page-small-title">Material Science Discovery
                                    Usecase
                                </div> */}
                            <div className="ms-page-desc">
                                Transform customer interactions, boost efficiency, and drive sales forward with
                                Microsoft AI.
                            </div>
                            {/* <Link className="ms-ai-btn"
                                  to="aidesignsub">Learn
                                more</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
