import React from "react";
import {useForm} from 'react-hook-form';
import OtpInput from 'react-otp-input';
import {Loader} from "../loader";
import {loginVerify, tryLogin} from "../../services/api";
import Cookies from 'universal-cookie';
import "../../assets/css/login.css";

interface IProps {
    setLoginVerified: (verified: boolean) => void
}

export const Login = (props: IProps) => {
    const {setLoginVerified} = props;
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loginStatus, setLoginStatus] = React.useState<string>('login');
    const [otp, setOtp] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [errorMsg, setErrorMsg] = React.useState<string>('');
    const formSubmitRef = React.useRef<HTMLInputElement>(null);
    const cookies = new Cookies();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const submitForm = async (data: any) => {
        let params = data;
        params.profile_path = "edo-segal";
        setLoading(true);
        if (loginStatus == 'login') {
            setEmail(params.email);
            const result = await tryLogin(params);
            if (result.data.success) {
                setLoginStatus('enter-code');
            } else {
                setErrorMsg('Please enter valid email');
            }

        } else if (loginStatus == 'enter-code') {
            if (otp.length === 0) {
                setErrorMsg('Please enter an access code');
                (document as any).querySelector("input").focus();
            } else if (otp.length < 5) {
                setOtp('');
                setErrorMsg('Please enter a valid access code');
                (document as any).querySelector("input").focus();
            } else {
                params = {
                    pin: otp,
                    email
                };
                const result = await loginVerify(params);

                if (result.data && result.data.success === true && result.data.user_access_token !== '') {
                    cookies.set('microsoft-user-access-token', result.data.user_access_token);
                    setLoginStatus('loginned');
                    setLoginVerified(true);
                }
            }

        }
        setLoading(false);

    }
    const handleChange = (otp: string) => {
        setErrorMsg('');
        setOtp(otp);
    };

    React.useEffect(() => {
        if (otp.length === 5) {
            formSubmitRef.current?.click();
        }
    }, [otp])

    return (
        <div className={`tc-msft-main-container tc-login-wrapper`}>
            {loading && <Loader/>}

            {loginStatus == 'login' && <div className="tc-msft-upload-wrapper di-box-style tc-login-small">
                <div className="tc-msft-upload-container">
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit((data) => submitForm(data))}>
                        <div className="di-form-field-wrapper">
                            <div className="di-form-field-item">
                                <p>Email</p>
                                <input type="email" {...register('email', {required: true})} required name="email"
                                       className={`company-field ${errors.fullName ? 'error-input' : ''}`}
                                       placeholder="Email"/>
                            </div>
                        </div>

                        <div>
                            {errorMsg != '' &&
                                <p className='error'>
                                    <svg width="30px" height="30px" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" stroke="#bf1650" strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M12,3 L22,21 L2,21 L12,3 Z M12,9 L12,15 M12,16 L12,18"/>
                                    </svg>
                                    {errorMsg}
                                </p>
                            }
                        </div>
                        <input disabled={loading} type='submit' className="di-btn company-btn submit-btn"
                               value={"Submit"}/>
                    </form>
                </div>
            </div>}

            {loginStatus == 'enter-code' && <div className="tc-msft-upload-wrapper di-box-style tc-login-small">
                <div className="tc-msft-upload-container">
                    <h2>Enter Access Code</h2>
                    <form onSubmit={handleSubmit((data) => submitForm(data))}>
                        <div className="di-form-field-wrapper">
                            <div className="di-form-field-item">
                                <p>Please check your inbox for your access code.</p>
                                <OtpInput
                                    key={'otpinput'}
                                    value={otp}
                                    shouldAutoFocus={true}
                                    onChange={handleChange}
                                    numInputs={5}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}

                                />
                            </div>
                        </div>
                        <div>
                            {errorMsg != '' &&
                                <p className='error'>
                                    <svg width="30px" height="30px" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" stroke="#bf1650" strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M12,3 L22,21 L2,21 L12,3 Z M12,9 L12,15 M12,16 L12,18"/>
                                    </svg>
                                    {errorMsg}
                                </p>
                            }
                        </div>
                        <input ref={formSubmitRef} disabled={loading} type='submit'
                               className="di-btn company-btn submit-btn"
                               value={"Submit"}/>
                    </form>
                </div>
            </div>}


        </div>
    )
}