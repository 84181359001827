import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import "./audioplayer.css";
import mslogo from "@assets/images/podcast/microsoft-white.png";
import playImg from "@assets/images/podcast/play.svg";
import pauseImg from "@assets/images/podcast/pause.svg";
import aiImage from "@assets/images/ai-black.png";
import podcastThumbnail from "@assets/images/podcast.png";
import { setPodcastInfo } from "@src/store/reducers/commonReducer";
import { Podcastinfo } from "@src/types";

interface IProps {
    podcast: any,
    title: string
}

const AudioPlayer = (props: IProps) => {
    const { podcast, title } = props;
    const podcastInfo = useSelector((state: RootState) => state.common.podcastInfo);
    const dispatch = useDispatch();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const progressRef = useRef<HTMLDivElement | null>(null);
    const progressContainerRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null); // Reference for the canvas element
    const animationIdRef = useRef<number | null>(null); // Animation frame ID
    const analyserRef = useRef<AnalyserNode | null>(null); // Reference for the AnalyserNode
    const audioContextRef = useRef<AudioContext | null>(null); // Reference for the AudioContext

    const playAudio = (podcastData: any) => {
        if (podcastData?.audio) {
            const audioData = {
                date: '',
                title: '',
                subtitle: 'Industry Vision',
                url: podcastData?.audio
            }
            dispatch(setPodcastInfo(audioData));
        }
    }
   
    useEffect(() => {
        const filePath = podcastInfo ? podcastInfo.url : '';
        if (filePath) {
            const audio = new Audio(filePath);
            audioRef.current = audio;

            const updateProgress = () => {
                setCurrentTime(audio.currentTime);
                const progress = (audio.currentTime / audio.duration) * 100;
                if (progressRef.current) {
                    progressRef.current.style.width = `${progress}%`;
                }
            };

            const handleMetadata = () => {
                setDuration(audio.duration);
            };

            // Set duration when metadata loads
            audio.addEventListener("loadedmetadata", handleMetadata);
            audio.addEventListener("timeupdate", updateProgress);
            audio.addEventListener("ended", () => {
                setIsPlaying(false);
            });

            // Setup Web Audio API for waveform animation
            const audioContext = new AudioContext();
            const analyser = audioContext.createAnalyser();
            const source = audioContext.createMediaElementSource(audio);

            analyser.fftSize = 256; // Size of the Fast Fourier Transform
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            source.connect(analyser);
            analyser.connect(audioContext.destination);

            audioContextRef.current = audioContext;
            analyserRef.current = analyser;

            // drawWaveform();
            if (!isPlaying) {
                setIsPlaying(true);
                audio.play().catch((error) => {
                    console.error("Audio play failed:", error);
                });
            }

            return () => {
                if (audioContextRef.current) {
                    audioContextRef.current.close();
                }
                if (animationIdRef.current) {
                    cancelAnimationFrame(animationIdRef.current);
                }
                audio.pause();
                audio.currentTime = 0;
                audioRef.current = null;
                audio.removeEventListener("loadedmetadata", handleMetadata);
                audio.removeEventListener("timeupdate", updateProgress);
            };
        }
    }, [podcastInfo]);

    const handlePlayPause = () => {
        const audio = audioRef.current;
        if (audio) {
            if (isPlaying) {
                audio.pause();
            } else {
                
                    audio.play();
       
                
            }
            setIsPlaying(!isPlaying);
        } else if(!podcastInfo) {
                playAudio(podcast);
            
        }
    };
    const handleBackward = () => {
        const audio = audioRef.current;
        if (audio) {
            audio.currentTime = Math.max(0, audio.currentTime - 5); // Go back 5 seconds
        }
    };

    const handleForward = () => {
        const audio = audioRef.current;
        if (audio) {
            audio.currentTime = Math.min(audio.duration, audio.currentTime + 5); // Go forward 5 seconds
        }
    };
    const handleSeek = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const audio = audioRef.current;
        const container = progressContainerRef.current;
        if (audio && container) {
            const rect = container.getBoundingClientRect();
            const clickX = event.clientX - rect.left; // Click position relative to the container
            const width = rect.width;
            const seekTime = (clickX / width) * audio.duration; // Calculate seek time
            audio.currentTime = seekTime; // Update audio time
        }
    };

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    };
    const handleMuteUnmute = () => {
        const audio = audioRef.current;
        if (audio) {
            audio.muted = !audio.muted; // Toggle mute state
            setIsMuted(audio.muted); // Update state
        }
    };
    const closePlayer = () => {
        const audio = audioRef.current;
        if (audio) {
            if (isPlaying) {
                audio.pause();
            }
            setIsPlaying(false);
        }
        dispatch(setPodcastInfo(null));
    }

    return (
        <div className="ms-ai-audio-player-wrapper">
            <div className="ms-ai-audio-player-thumbnail">
                <img src={podcastThumbnail} />
            </div>
            <div className="ms-ai-audio-content-area">
                <div className="ms-ai-audio-content-text">
                    <div className="ms-ai-audio-player-title">Podcast</div>
                    <div className="ms-ai-audio-player-subtitle">{title}</div>
                </div>
                <div className="ms-ai-audio-player-controls">
                    <div className="ms-ai-time">
                        <span className="ms-ai-time-current">{formatTime(currentTime)}</span>  <span className="ms-ai-time-total">{formatTime(duration)}</span>
                    </div>
                    <div
                        className="ms-ai-progress-container"
                        ref={progressContainerRef}
                        onClick={handleSeek} // Handle seek
                    >
                        <div className="ms-ai-progress-bar">
                            <div className="ms-ai-progress" ref={progressRef}></div>
                        </div>

                    </div>
                    <div className="ms-ai-audio-player-buttons">
                        <button className="ms-ai-audio-player-button nav-icon" onClick={handleBackward}>
                            <svg
                                width="25"
                                height="25"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.52494 18.0021C7.12145 19.5987 9.32704 20.5861 11.7633 20.5861C16.6357 20.5861 20.5857 16.6362 20.5857 11.7638C20.5857 6.89133 16.6357 2.94141 11.7633 2.94141C9.32704 2.94141 7.12145 3.92888 5.52494 5.52543C4.7123 6.33807 2.94092 8.33285 2.94092 8.33285"
                                    stroke="#702573"
                                    strokeWidth="1.96053"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M2.94092 4.41016V8.33121H6.86197"
                                    stroke="#702573"
                                    strokeWidth="1.96053"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <button className="ms-ai-audio-player-button play-btn" onClick={handlePlayPause}>
                            {isPlaying ? <svg
                                width="7"
                                height="8"
                                viewBox="0 0 7 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.5 1H2.5V7H1.5V1ZM4.5 1H5.5V7H4.5V1Z"
                                    fill="white"
                                    stroke="white"
                                    strokeWidth="1.17632"
                                />
                            </svg> : <svg
                                width="7"
                                height="8"
                                viewBox="0 0 7 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.4786 7.2548V0.745105L6.01294 3.98257C6.01452 3.99073 6.01508 4.00018 6.01419 4.00944C6.01391 4.01236 6.01352 4.01496 6.01308 4.01724L1.4786 7.2548Z"
                                    fill="white"
                                    stroke="white"
                                    strokeWidth="1.17632"
                                />
                            </svg>}
                        </button>
                        <button className="ms-ai-audio-player-button nav-icon" onClick={handleForward}>
                            <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.0015 18.0015C16.405 19.5981 14.1995 20.5855 11.7632 20.5855C6.89076 20.5855 2.94083 16.6356 2.94083 11.7632C2.94083 6.89072 6.89076 2.9408 11.7632 2.9408C14.1995 2.9408 16.405 3.92827 18.0015 5.52482C18.8142 6.33746 20.5856 8.33224 20.5856 8.33224" stroke="#702573" stroke-width="1.96053" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20.5856 4.41125V8.33231H16.6645" stroke="#702573" strokeWidth="1.96053" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </button>
                        <button className="ms-ai-audio-player-button volumn-button" onClick={handleMuteUnmute}>
                            {isMuted ? <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="1" d="M13 3L7 8H5C3.89543 8 3 8.89543 3 10V14C3 15.1046 3.89543 16 5 16H7L13 21V3Z" fill="#000000" /><path d="M16 9L22 15M22 9L16 15M13 3L7 8H5C3.89543 8 3 8.89543 3 10V14C3 15.1046 3.89543 16 5 16H7L13 21V3Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                : <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="1" d="M13 3L7 8H5C3.89543 8 3 8.89543 3 10V14C3 15.1046 3.89543 16 5 16H7L13 21V3Z" fill="#000000" /><path d="M16 8.99998C16.5 9.49999 17 10.5 17 12C17 13.5 16.5 14.5 16 15M19 6C20.5 7.5 21 10 21 12C21 14 20.5 16.5 19 18M13 3L7 8H5C3.89543 8 3 8.89543 3 10V14C3 15.1046 3.89543 16 5 16H7L13 21V3Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
       
    );
};

export default AudioPlayer;
