import React from 'react';
import '../../../assets/css/storiesarch.css';

const ArchitectureDiagram = () => {
    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }
    return (
        <div className="mi-ai-stories-arch-container">
           
            
            <section className="mi-ai-stories-arch-section">
                <div className="mi-ai-stories-arch-content">
                <h1 className="mi-ai-stories-arch-title">Architecture Diagram</h1>
                    <h2 className="mi-ai-stories-arch-subtitle">The Solution with Provisioned Throughput Units (PTU)</h2>
                    <ul className="mi-ai-stories-arch-list">
                        <li>The solution is built into Tom Tom’s Digital Cockpit, an open, modular in-vehicle infotainment platform.</li>
                        <li>TomTom is using <b>Azure OpenAI Service, Azure Cosmos DB, and Azure Kubernetes Service</b> for seamless control and conversations by the drivers.</li>
                        <li>The voice assistant can be integrated into other automotive infotainment systems, enabling automotive customers to accelerate time-to-market on a customizable interface while retaining ownership of their branding and the driver experience.</li>
                        <li>Will use Azure OpenAI in the form of Managed Provisioned Throughput Units (PTU) as core GenAI technology in different layers of the architecture.</li>
                    </ul>
                </div>
                <div className="mi-ai-stories-arch-image-container">
                    <img src={urlPrefix + 'assets/images/stories/1.jpg'} alt="TomTom Digital Cockpit Architecture" className="mi-ai-stories-arch-image" />
                </div>
            </section>

            <section className="mi-ai-stories-arch-section">
                <div className="mi-ai-stories-arch-content">
                    <h2 className="mi-ai-stories-arch-subtitle">TomTom Digital Cockpit+ Fabric Cloud Analytics</h2>
                    <ul className="mi-ai-stories-arch-list">
                        <li>Enhancing the user experience requires understanding the usage patterns of the digital assistant and the applications. TomTom has <b>implemented feedback mechanisms that collect and analyze data using Microsoft Fabric with Real Time Analytics</b>. This enables the continuous improvement of the answers provided, creation of user profiles and ML predictive models. Data collection also enables Automotive OEMs to drive decisions that influence product features.</li>
                        <li>The <b>geospatial analytics & visualization capabilities of Fabric</b> simplify understanding large amounts of information.</li>
                    </ul>
                </div>
                <div className="mi-ai-stories-arch-image-container">
                    <img src={urlPrefix + 'assets/images/stories/2.jpg'} alt="TTDC App Insights Dashboard" className="mi-ai-stories-arch-image" />
                    <img src={urlPrefix + 'assets/images/stories/3.jpg'} alt="TTDC App Insights Dashboard" className="mi-ai-stories-arch-image" />
                </div>
            </section>

            <section className="mi-ai-stories-arch-section">
                <div className="mi-ai-stories-arch-content">
                    <h2 className="mi-ai-stories-arch-subtitle">TomTom Connected Fleet & Logistics</h2>
                    <ul className="mi-ai-stories-arch-list">
                        <li>TomTom and Microsoft collaborate on an E2E solution template (Power Platform, TomTom Navigation and Dynamics 365 Field Service) that can be customized to build logistics and fleet operations scenarios, catering to a broad set of users. The focus of our scenario is about the front-line worker, improving their day-to-day experience. Hence, we have created a front-line worker application for delivery of goods and services, that requires specialized navigation (e.g., turn restrictions) and ETA calculation using external factors such as traffic</li>
                    </ul>
                </div>
                <div className="mi-ai-stories-arch-image-container">
                    <img src={urlPrefix + 'assets/images/stories/4.jpg'} alt="Connected Fleet & Logistics" className="mi-ai-stories-arch-image" />
                </div>
            </section>
        </div>
    );
};

export default ArchitectureDiagram;
