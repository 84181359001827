import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import ArchitectureDiagram from "./Architechture";
import TeamPage from "./TeamPage";
// import ArchitectureDiagram from "./TomtomArchitechture";
// import TeamPage from "./TomtomTeamPage";
export const Dana = () => {

    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }

    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">Dana-Farber Cancer Institute create & deploy in 6 months its secure, compliance and auditable internal AI-powered assistant</div>

                <div className="innerpage-sub-title">Win summary & Customer Impact</div>
                <p>Dana-Farber Cancer Institute, a leading medical research center and a renowned cancer treatment is located in Boston, USA. It's affiliated with Harvard Medical School and is known for its comprehensive cancer care, research initiatives, and contributions to advancements in cancer treatment and understanding.</p>

                <p>Cancer centers like Dana Farber Cancer Center (DFCI) rely heavily on unstructured text for various functions, including clinical notes, reports, communications, reimbursements, regulatory reporting, and administrative tasks. Recognizing the success of large language models (LLMs) in other industries and their potential value in healthcare, DFCI aimed to explore applications that could assist their workforce, researchers, and patients. This initiative was not only to understand the benefits but also to comprehend the associated risks, liabilities, and biases.</p>

                <p>Although DFCI has conducted several LLM-based research studies, testing LLMs for clinical and operational use has been challenging due to the need to protect patient privacy under HIPAA, which prevents using public LLMs for queries involving protected health information. Training their own LLM would be costly, so DFCI aimed to deploy a commercial LLM institution wide in a secure, HIPAA-compliant manner for safe use with sensitive information called GPT4DFCI.</p>

                <p>Dana-Farber Cancer Institute, sought to enhance operational efficiency and accelerate research endeavors amid growing data complexities and resource constraints. To address these challenges, Dana-Farber embarked on a pioneering initiative to deploy its own GPT-4, an advanced AI model, institute-wide called GPT4DFCI. By leveraging AOAI for operational and research tasks, they aimed to boost productivity, facilitate data analysis, and ensure compliance, ultimately advancing their mission to combat cancer.</p>
                <div className="mi-ai-stories-center-quote">
                    <div className="mi-ai-stories-center-quote-title">"Clinical AI is both high-ROI and high-risk. By focusing on AI for research and operations we are pursuing use cases that are high-ROI and lower-risk." </div>
                    <div className="mi-ai-stories-center-quote-subtitle">Renato Umeton, PhD, Director, AI Intelligence Operations & Data Science Services, Dana-Farber Cancer Institute</div>
                </div>
            </div>
            <div className="mi-ai-stories-container">
                <div className="mi-ai-stories-content">
                    <div className="mi-ai-stories-win-snapshot">
                        <h2>Win Snapshot</h2>
                        <div className="mi-ai-stories-win-content">
                            <strong>Win Date:</strong> <p>May 14, 2024</p>
                            <strong>Area:</strong><p>Americas</p>
                            <strong>Country:</strong><p>USA </p>
                            <strong>Segment:</strong> <p>Strategic Commercial </p>
                            <strong>Industry:</strong> <p>Healthcare</p>
                            <strong>Technical Pattern:</strong><p>
                                <ul className="mi-ai-stories-list">
                                    <li>Chat with your own data </li>
                                    <li>Content Generation</li>
                                </ul>
                            </p>
                            <strong>Azure Impact ($ Montly):</strong>
                            <p>
                                <ul className="mi-ai-stories-list">
                                    <li>AOAI ACR: $12K </li>
                                    <li>App Services ACR: $26K</li>
                                    <li>Cosmos DB ACR: $2K</li>
                                </ul>

                            </p>
                            <strong>Opportunity ID:</strong><p>7-36PQ2SYHSL</p>
                            <strong>Programs/Offers:</strong> <p>VBD </p>
                            <strong>Partner/ISV:</strong> <p>N/A</p>
                            <strong>Compete:</strong> <p>GCP/ Gemini</p>
                        </div>
                    </div>
                    <div className="mi-ai-stories-main-content">
                        <div className="mi-ai-stories-solution">
                            <h2>Solution</h2>
                            <p>Dana-Farber Cancer Institute implemented GPT4DFCI app, a private and secure deployment of GPT4, available across the organization, to harness the potential of LLM while mitigating unique risks in healthcare. Their goal was to provide compliant, secure, and auditable GPT (Generative Pre-Trained) access to their 12.500-member workforce.</p>

                            <div className='mi-ai-stories-arch-image-container-santender'><img src={urlPrefix + 'assets/images/stories/dana-farber-slide.png'} alt="TomTom Digital Cockpit Architecture" className="mi-ai-stories-arch-image" /></div>
                            <p>
                                Customer has implemented GPT-4 in GPT4DFCI to automate administrative processes like data entry, scheduling, and documentation and is saving time and resources while improving accuracy and efficiency. Also, by Leveraging GPT-4 for data analysis, literature reviews, and knowledge discovery Customer is expediting research cycles, identifying patterns, and generating insights faster, advancing cancer treatment and understanding.
                            </p>
                        </div>
                        <div className="mi-ai-stories-why-we-won">
                            <h2>Why we won?</h2>
                            <p><b>Technical Expertise:</b> Microsoft provided expertise in AI technologies, particularly in deploying LLM.</p>
                            <p><b>Azure's Capabilities:</b> Dana-Farber leveraged Microsoft's Azure platform thanks to comprehensive, reliable, and scalable services.</p>
                            <p><b>Security and Compliance:</b> Azure offers robust security features and compliance certifications, including HIPAA compliance. This ensured that GPT4DFCI could be deployed in a secure, auditable, and HIPAA-compliant manner, addressing Dana-Farber's requirements for protecting patient data.</p>
                        </div>
                        <div className="mi-ai-stories-business-impact">
                            <h2>Business Impact</h2>
                            <ul className="mi-ai-stories-list">
                                <li><b>Operational Efficiency:</b> Dana-Farber streamlined workflows and increased productivity by deploying GPT4DFCI.</li>
                                <li><b>Improved Compliance:</b> Secure, auditable logs ensure compliance with privacy and security regulations.</li>
                                <li><b>Enhanced Research:</b> Dana-Farber is expediting research cycles, identifying patterns, and generating insights faster.</li>
                                <li><b>Competitive Advantage:</b> Dana-Farber's early adoption of GPT4DFCI showcased its commitment to innovation, positioning the institution as a leader in healthcare AI.</li>
                            </ul>
                            <p>
                                From Dana-Faber perspective, It is too early to determine if GPT4DFCI will improve patient outcomes, staff well-being, equity in clinical care and research, or cost-effectiveness. However, by allowing their workforce to test LLMs in compliance with policies and laws, and in a fully auditable manner, DFCI aims to manage risks while advancing their discovery and clinical missions to better serve patients.
                            </p>
                        </div>
                        <div className="mi-ai-stories-repeatable-ip-pattern">
                            <h2>Repeatable IP & Pattern</h2>
                            <ul className="mi-ai-stories-list">
                                <li><b>Content Generation:</b> The system generates content such as reports, documentation, and responses to queries, automating content creation processes.</li>
                                <li><b>Data Extraction and Search:</b> GPT4DFCI extracts and searches information within documents, reports, and other textual data sources, streamlining information retrieval.</li>
                                <li><b>Source code, infrastructure-as-code, application documentation, and user training materials:</b> are available at <a href="https://github.com/Dana-Farber-AIOS/GPT4DFCI">https://github.com/Dana-Farber-AIOS/GPT4DFCI</a></li>
                            </ul>
                        </div>
                        <div className="mi-ai-stories-expansion">
                            <h2>Expansion</h2>
                            <ul className="mi-ai-stories-list">
                                <li>GPT4DFCI was initially rolled out to a small group of advanced users and gradually extended to more employees via a ticketing system, starting with a large computational research department and an operations department.</li>
                                <li>The deployment was socialized on Microsoft Teams Channels and email broadcasts, indicating a wider audience outreach and communication strategy.</li>
                                <li>Expansion efforts include continued development and refinement of AI-Specific policies.</li>
                                <li>Expansion of clinical and operational applications based on usage data and user feedback.</li>

                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <ArchitectureDiagram />
            <TeamPage />


        </div>

    )
}