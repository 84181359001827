import React from 'react';
import '../../../assets/css/storiesarch.css';

const ArchitectureDiagram = () => {
    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }
    return (
        <div className="mi-ai-stories-arch-container">
            <section className="mi-ai-stories-arch-section">
                <div className="mi-ai-stories-arch-content">
                <h1 className="mi-ai-stories-arch-title">Architecture Diagram</h1>
                <p className="mi-ai-stories-arch-subtitle">MAYA is a comprehensive solution integrating Azure's Al, Apps, and Databases services: This includes Azure OpenAl and Azure Al Studio for prototyping, Azure SQL Database as the primary technical storage layer, with documents stored on both Azure Data Lake Gen2 and Azure Al Studio. Additionally, it utilizes serverless solutions such as Azure Functions for enhanced acceleration.</p>
                <div className='mi-ai-stories-arch-image-container-santender'><img src={urlPrefix + 'assets/images/stories/marelli-architecture.jpg'} alt="TomTom Digital Cockpit Architecture" className="mi-ai-stories-arch-image" /></div>
                </div>
            </section>
        </div>
    );
};

export default ArchitectureDiagram;
