import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import ArchitectureDiagram from "./Architechture";
import TeamPage from "./TeamPage";
// import ArchitectureDiagram from "./TomtomArchitechture";
// import TeamPage from "./TomtomTeamPage";
export const Santender = () => {


    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">Santander introduces a pioneering Virtual Agent, utilizing Azure OpenAl, to support and enhance the capabilities of branch and contact center staff</div>
                <div className="innerpage-sub-title">Win summary & Customer Impact</div>
                <p>
                    <b>Santander Group is a Tier 1 GSIFI leading retail and commercial bank with global presence,</b> headquartered in Spain. Banco Santander serves <b>166 million customers with 13,000 branches</b> and 212,000 employees and operates in more than <b>30 countries.</b>
                </p>
            
                <p>
                Looking ahead, Santander is in a new phase of profitability and growth underpinned by three tenets: "Think Value, Think Customer and Think Global". With Azure Open Al + Intelligent Apps, Santander takes a step forward building a <b>global solution to deliver the best customer experience while reducing cost to serve.</b>
               <br/>
                This initiative is driven by the need to <b>enhance operational efficiency in customer support services</b> by reducing the number of interactions required with the Branches Backoffice and decreasing the Average Handling Time (AHT) in the Contact Center.
                </p>

                        <div className="mi-ai-stories-container">
                            <div className="mi-ai-stories-content">
                                <div className="mi-ai-stories-win-snapshot">
                                    <h2>Win Snapshot</h2>
                                    <div className="mi-ai-stories-win-content">
                                        <strong>Win Date:</strong> <p> May, 31, 2024 (purchase of first
100 PTUs GPT4T for MVP)</p>
                                        <strong>Area:</strong> <p>Western Europe</p>
                                        <strong>Country:</strong> <p>Spain</p>
                                        <strong>Segment::</strong> <p>Enterprise</p>
                                        <strong>Industry::</strong> <p>FSI</p>
                                        <strong>Azure Impact ($ Montly):</strong><p><strong>Current ACR: $25,489</strong><br/><strong>Potential:</strong></p><p>
                                            <ul className="mi-ai-stories-list">
                                                <li>$65.4K AOAI PTUS</li>
                                                <li>300 PTUs</li>
                                                <li>$11K Apps</li>
                                                <li>$2K Data ACR</li>
                                                <li>$1.5K AI ACR</li>
                                            </ul>
                                        </p>

                                        <strong>Programs/Offers:</strong><br/><br/><strong>Parter-led funding</strong><p>Azure Innovate - AOAI Tiered PTU<br/>ACO Offer</p>
                                        <strong>Partner/ISV:</strong> <p>ISD & Accenture/Avanade</p>
                                        <strong>Compete:</strong> <p>AWS, Google, IBM</p>
                                    </div>
                                </div>
                                <div className="mi-ai-stories-main-content">
                                    <div className="mi-ai-stories-solution">
                                        <h2>Solution</h2>
                                        <p>The primary objective is to implement a <b>Virtual Agent designed to assist branch and contact center agents in managing customer inquiries.</b></p>
                                        <p>The Virtual Agent will serve as an intelligent assistant, capable of quickly retrieving and delivering accurate information from a comprehensive knowledge base. This functionality aims to streamline the workflow of customer support agents by providing instant access to the information they need to resolve customer queries effectively. The anticipated outcome is al more efficient and responsive customer service process, which can handle a higher volume of inquiries without compromising on quality.</p>
                                        <p>The technical pattern combines the capabilities of document retrieval and response synthesis to create a conversational experience for Santander agents.</p>
                                        <p>The architecture is based on <b>Azure OpenAI GPT4-Turbo</b> to provide the intelligence together with <b>Azure App Gateway</b> with WAF for robust security, <b>Azure API Management</b> as an Al Gateway to ensure seamless and reliable deployment of intelligent applications, <b>and Azure Kubernetes Service</b> for its cloud-native platform capabilities, providing scalable and resilient infrastructure.</p>
                                        <p>Additionally, we utilize <b>CosmosDB</b>, a multi-region and high-throughput NoSQL database, to store chat messages and agent context provider. Last, the solution uses <b>Azure Al Search</b> and <b>Azure Storage</b> to store and index internal documents facilitating RAG capabilities in the conversation.</p>
                                        <p>The solution has already been deployed in Santander Mexico for a small group and then it will be expanded to all ~ 1,350 branches.</p>
                                    </div>
                                    <div className="mi-ai-stories-why-we-won">
                                        <h2>Why we won?</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li>Santander is testing other Generative Al models (Google, AWS, IBM) but Microsoft <b>Azure OpenAl has been one step ahead</b></li>
                                            <li>Microsoft can provide an end-to-end platform beyond Azure OpenAl, to deploy, maintain and monitor complex applications.
                                            <li>Microsoft Azure offers <b>enterprise-grade security, scalability, and reliability as well as data security.</b> These are critical requirements for Santander.</li>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-business-impact">
                                        <h2>Business Impact</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li><b>Improved Customer Service Efficiency:</b> significant reduction in the number of interactions needed with Branch Backoffice and the Average Handling Time (AHT) in the Contact Center, allowing for quicker and more efficient resolution of customer inquiries.</li>
                                            <li><b>Resource Optimization:</b> freeing up human resources in branches and contact centers, enabling employees to focus on higher-value tasks.</li>
                                            <li><b>Enhanced Customer Experience:</b> providing quick and accurate responses to customer inquiries, improving customer satisfaction and perception of the bank's services.</li>
                                            <li><b>Scalability and Adaptability:</b> a cutting-edge technology| solution that can be easily scaled and adapted to new needs and data volumes, ensuring the bank is prepared for future demands.</li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-repeatable-ip-pattern">
                                        <h2>Repeatable IP & Pattern</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li>PTU Calculator has been key to prepare several quick estimations following Santander requests: Azure OpenAl Solution Sizing Tool (gbbptucalc.azurewebsites.net).</li>
                                            <li>Reference architectures and patterns available in Github: GitHub - Azure-Samples/Al-Gateway: APIM S OpenAl - this repo contains a set of experiments on using GenAl capabilities of Azure API Management with Azure OpenAl and other services
                                            <li>ESI (Enterprise Skills Initiative) to equip the teams with the knowledge and skills necessary to effectively implement and leverage Microsoft Al
Platform: 400 Santander FTE have been trained in Data&Al and Digital Innovative Applications in FY24.</li>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-expansion">
                                        <h2>Expansion</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li>The objetive is to replicate the <b>same use case in other banks</b> within Santander Group, all sharing a common partner and architecture:Santander Brazil, Santander Spain, Santander Poland and Santander Portugal.</li>
                                            <li>Next phase will extend the <b>virtual assistant to final customers</b>, providing a quick response to any question.</li>
                                            <li>Third phase will include the <b>integration with core banking systems to execute actions</b>. i.e, customers will be able to manage their finances with ease and confidence.</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>

                    <ArchitectureDiagram />
                    <TeamPage/>
                    
                    
            </div>
                    
    </div>
    )
}