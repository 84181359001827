import React from 'react';
import datas from '../../../datas';
import {Context} from "../../../context/menu";
import {Link} from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '@src/store/store';
import { MenuItem } from './MenuItem';


interface IProps {
    pageData: any
    tabindex: number
}

export const LeftBar = (props: IProps) => {
    let {pageData, tabindex} = props;
    const {isClicked,} = React.useContext(Context);
    const menuItems = useSelector((state: RootState) => state.common.menu_items);
    const mainMenus = menuItems.filter((x:any) => !x.parent_id );

    return (
        <div className="ms-ai-inner-page-left">
            <Link tabIndex={tabindex} to="/" aria-label="Back">
                <div
                    className="ms-ai-inner-page-back-btn"
                >
                    <svg
                        width={26}
                        height={26}
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.344 13.2803L5.55999 13.2803"
                            stroke="#676A6C"
                            strokeWidth="2.112"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12.9521 20.6729L5.56015 13.2809L12.9521 5.88885"
                            stroke="#676A6C"
                            strokeWidth="2.112"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </Link>
            <ul className={`ms-ai-nav-menu-wrapper`}>
                {mainMenus.map((menuItem: any) => {
                    return (
                        <MenuItem item={menuItem} />
                    );
                })}
            </ul>

            

        </div>
    )
}