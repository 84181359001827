import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import ArchitectureDiagram from "./Architechture";
import TeamPage from "./TeamPage";
// import ArchitectureDiagram from "./TomtomArchitechture";
// import TeamPage from "./TomtomTeamPage";
export const Marelli = () => {

    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }

    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">Marelli's Al Journey: Empowering 14K Employees with MAYA Assistant at maximum speed with PTUs</div>
                <p>
                Dear Team,<br/>
It is with great excitement and pride that we celebrate this incredible Al win.
You may be wondering why an SSP of MW is sending a WinWire for Al Desing Wins. As an SSP of MW, I took on a dual role at Marelli as an Account Executive. This win is a reward for me and my team's hard work.
                </p>
                <div className="innerpage-sub-title">Win summary & Customer Impact</div>
                <p>Marelli - a child company of CNH Industrial (S500) global account- is a leading global automotive supplier that specializes in designing and manufacturing advanced systems and components for vehicles.</p>
<p>Marelli is a company with innovation coursing through its DNA. Much like a chapter straight out of the Hitchhiker's Guide to the Galaxy, this partnership showcases Marelli's relentless pursuit of excellence. From pioneering the KERS technology that laid the foundation for mild-hybrid engines to leading the way with the first Xenon and Laser headlights for Audi, Marelli is a company that embodies innovation.</p>
<p>In the digital realm, they've always been trailblazers, adopting cutting-edge technologies like Databricks and becoming the first customer in Europe before transitioning to Synapse and now to Fabric. Their adept integration of the best Azure services has been nothing short of</p>
<p>In the Al space, Marelli has been at the forefront since 2016, experimenting with a tool based on Azure Cognitive Search to assist the HR department. By 2021, they were leveraging Azure Machine Learning to assess contractual risk in competition law breaches. With this Win we are celebrating the achievement of completing <b>MAYA - Marelli's Assistant for Your Awareness</b> with 100 PTUs.</p>

                        <div className="mi-ai-stories-container">
                            <div className="mi-ai-stories-content">
                                <div className="mi-ai-stories-win-snapshot">
                                    <h2>Win Snapshot</h2>
                                    <div className="mi-ai-stories-win-content">
                                        <strong>Win Date:</strong> <p>May 10, 2024</p>
                                        <strong>Segment:</strong> <p>Enterprise commercial</p>
                                        <strong>Industry:</strong> <p>Manufacturing and Automotive</p>
                                        <strong>Technical Pattern:</strong><p>
                                            <ul className="mi-ai-stories-list">
                                                <li>Chat with your data</li>
                                            </ul>
                                        </p>
                                        <strong>Azure Impact ($ Montly):</strong>
                                        <p>
                                            <ul className="mi-ai-stories-list">
                                                <li>PTU ADS: $31.2K month<br/><small>(amendment for 1yr commitment)</small></li>
                                                <li>Al services: $3K month</li>
                                                <li>Data services: $7K month</li>
                                                <li>App services: $4K month</li>
                                            </ul>
                                        </p>
                                        <strong>Opportunity ID:</strong><p>7-3644DFRM7F</p>
                                        <strong>Programs/Offers:</strong> <p>Azure Innovate, Go Big program</p>
                                        <strong>Partner/ISV:</strong> <p>ISD - Reply</p>
                                        <strong>Compete:</strong> <p>AWS</p>
                                    </div>
                                </div>
                                <div className="mi-ai-stories-main-content">
                                    <div className="mi-ai-stories-solution">
                                        <h2>Solution</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li><b>MAYA - Marelli's Assistant for Your Awareness</b>- is a co-pilot assistant. powered by Azure OpenAl that helps employees navigate various documents. It is a real implementation based on Azure Best of Breed services (architecture for details). It just went live and used by 5,000 employees, with plans to roll it out to all 14,000 employees by the end of June.</li>
                                            <li>Marelli is a multi-cloud customer. Since the beginning we compete with AWS, the risk was that they would adopt bedrock but our commitment and our customer focused was the key to the success.
                                               
                                               <div className='mi-ai-stories-arch-image-container-santender'><img src={urlPrefix + 'assets/images/stories/marelli-solution.jpg'} alt="TomTom Digital Cockpit Architecture" className="mi-ai-stories-arch-image" /></div>
                                            </li>
                                        </ul>
                                        
                                    </div>
                                    <div className="mi-ai-stories-why-we-won">
                                        <h2>Why we won?</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li><b>One Microsoft approach</b> Contribution of all the Microsoft stakeholders was a key factor to win</li>
                                            <li><b>Never Give up attitude</b></li>
                                            <li><b>Customer focus</b> both business and technical experts were at the table with us designing the end-to-end solution</li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-business-impact">
                                        <h2>Business Impact</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li><b>Enhanced employee productivity</b> while reinventing the end-to-end employees experience by creating a culture within the company that was well versed in Al used by every business unit</li>
                                            <li><b>Better legal risk mitigation</b> using Azure Machine Learning to assess contractual risk in cases of competition law breaches</li>
                                            <li><b>Enhanced employee satisfaction</b> - Maya was rated 5 out of 5 when asked whether they wanted to continue using it.</li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-repeatable-ip-pattern">
                                        <h2>Repeatable IP & Pattern</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li>Chat with your data</li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-expansion">
                                        <h2>Expansion</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li>Marelli remains on the cutting edge, aiming to develop an assistant for responding to RFQs. As a B2B company, Marelli doesn't have off-the-shelf products but rather customizes them to client RFQs.</li>
                                            <li>Considering the journey started by Marelli in the GENAI area, they will not only develop new Use Cases, with Azure Open Al, they have started also piloting <b>M365Copilot</b>.</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>

                    <ArchitectureDiagram />
                    <TeamPage/>
                    
                    
            </div>
                    
    </div>
    )
}