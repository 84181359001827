import React from 'react';
import '../../../assets/css/storiesteam.css';

const TeamPage = () => {
  return (
    <div className="mi-ai-stories-win-team-container">
      <h1 className="mi-ai-stories-win-team-title">Winning Team</h1>
      <div className="mi-ai-stories-win-team-sections">
        <div className="mi-ai-stories-win-team-section">
          <strong>Core Team</strong>
          <ul className="mi-ai-stories-win-team-list">
            <li>Megan Anderson (ATU)</li>
            <li>Alexey Zakharzhevskiy (ATU)</li>
            <li>Mark Kindree (ATU)</li>
            <li>Laryssa Sirko (STU)</li>
            <li>Tanner Kotsopoulos (STU)</li>
            <li>Kashan Jafri (STU)</li>
            <li>Jimmy Shah (STU)</li>
            <li>Jungho Kim (CSU)</li>
            <li>Ayoub Kebaili (CSU)</li>
            <li>Rob Beaudoin (CSU)</li>
            <li>Greg Mowat (CSU)</li>
            <li>Martin De Bast (Industry Solutions)</li>
            <li>Frank Potocnik (AI Pod GBB)</li>
            <li>Asmita Usturge (AI Pod GBB)</li>
            <li>Owen van Valkenburg (AI Pod GBB)</li>
            <li>Zach Katsof (SPT)</li>
            <li>Harj Dodd (SPT)</li>
          </ul>
        </div>

        <div className="mi-ai-stories-win-team-section">
          <strong>ISE Team:</strong>
          <ul className="mi-ai-stories-win-team-list">
            <li>Jeoffrey Macaspac</li>
            <li>Etienne Margraff</li>
            <li>Adrian Bethune</li>
            <li>Scott Grosenick</li>
            <li>Ivan Shaporov</li>
            <li>Elmira Vildanova</li>
            <li>Jeff Ding</li>
            <li>Sara Sexton</li>
            <li>Sean Miller</li>
            <li>Shawn Gaul</li>
            <li>Zakra Toure</li>
            <li>P.J. Little</li>
          </ul>
        </div>


        <div className="mi-ai-stories-win-team-section">
          <strong>Leadership Team</strong>
          <ul className="mi-ai-stories-win-team-list">
            <li>Chris Barry (CVP)</li>
            <li>Tom Kubik (ATU GM)</li>
            <li>Sean Laughlin (ATU)</li>
            <li>Stephen Tanaka (STU GM)</li>
            <li>Jeff Mastin (STU)</li>
            <li>Peter Kelcey (Customer Success)</li>
            <li>Andrey Cavalcante (STU)</li>
            <li>Bill Baldasti (STU)</li>
            <li>Deb Harding (AI Pod GBB)</li>

          </ul>
        </div>

      </div>

      <div className="mi-ai-stories-win-team-resources">
        <h3>Winwire Resources</h3>
        <ul className="mi-ai-stories-win-team-list">
          <li><a target='_blank' href="https://nam06.safelinks.protection.outlook.com/?url=https%3A%2F%2Fidwebelements.microsoft.com%2FGroupManagement.aspx%3FGroup%3DAIDesignWinWires%26Operation%3Djoin&data=05%7C02%7Cardiez%40microsoft.com%7C7339994a2d75479bcfc608dc866b159b%7C72f988bf86f141af91ab2d7cd011db47%7C1%7C0%7C638533041861232565%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=1nJgykSzsr3ZfvshcGU67x9fFTg4H%2BfRwZ5gSKomia8%3D&reserved=0">Subscribe to Al Design Win - WinWire/LiveWire</a></li>
          <li><a target='_blank' href="https://nam06.safelinks.protection.outlook.com/?url=https%3A%2F%2Fforms.office.com%2FPages%2FResponsePage.aspx%3Fid%3Dv4j5cvGGr0GRqy180BHbR73cFOuUO9lBjFi3ibuxgopUNlpNSTZQMExSSTlaRFpaWEdLUFozODNENC4u&data=05%7C02%7Cardiez%40microsoft.com%7C7339994a2d75479bcfc608dc866b159b%7C72f988bf86f141af91ab2d7cd011db47%7C1%7C0%7C638533041861239507%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=G8o78wV9JV%2BfsJfzbRZrwcDvq1Ld1STMveOer2GlaF4%3D&reserved=0">Submit an AI Design Win - WinWire/LiveWire</a></li>
          <li><a target='_blank' href='https://microsofteur.sharepoint.com/:w:/t/DAI/ERl8QzNPwBpJqn5MFRJHHr0BnVKK7byXr8uJPalTiUeFeQ?e=R2e3Zh&xsdata=MDV8MDJ8YXJkaWV6QG1pY3Jvc29mdC5jb218NzMzOTk5NGEyZDc1NDc5YmNmYzYwOGRjODY2YjE1OWJ8NzJmOTg4YmY4NmYxNDFhZjkxYWIyZDdjZDAxMWRiNDd8MXwwfDYzODUzMzA0MTg2MTI0NTE1M3xVbmtub3dufFRXRnBiR1pzYjNkOGV5SldJam9pTUM0d0xqQXdNREFpTENKUUlqb2lWMmx1TXpJaUxDSkJUaUk2SWsxaGFXd2lMQ0pYVkNJNk1uMD18MHx8fA%3d%3d&sdata=anp2MEpyU2ZsYzZueGdIcUtoWVUzTW9iQngxWmlUcC9aYnpIZWp1L2dxbz0%3d'>Al Design Win - WinWire Template</a></li>
          <li><a target='_blank' href='https://microsofteur.sharepoint.com/:w:/t/DAI/EfFxT1DuOF9CkH-heE36xY8BCsTUW_v43wSiL_JMTOHE9w?e=EcH2Wp&xsdata=MDV8MDJ8YXJkaWV6QG1pY3Jvc29mdC5jb218NzMzOTk5NGEyZDc1NDc5YmNmYzYwOGRjODY2YjE1OWJ8NzJmOTg4YmY4NmYxNDFhZjkxYWIyZDdjZDAxMWRiNDd8MXwwfDYzODUzMzA0MTg2MTI1MzczMnxVbmtub3dufFRXRnBiR1pzYjNkOGV5SldJam9pTUM0d0xqQXdNREFpTENKUUlqb2lWMmx1TXpJaUxDSkJUaUk2SWsxaGFXd2lMQ0pYVkNJNk1uMD18MHx8fA%3d%3d&sdata=SzlMeEJwZDFleSs5TjNqMzVCeWRCOUVHUTBHK1BuTW0yRFM2MldIUWp0RT0%3d'>Al Design Win - LiveWire</a></li>
        </ul>
      </div>
    </div>
  );
};

export default TeamPage;

