import React from 'react'

export interface IMenu {
    isClicked: boolean;
    setIsClicked: (isClicked: boolean) => void;
}

export const initialContext: IMenu = {
    isClicked: false,
    setIsClicked: () => {
    },
}

export const Context = React.createContext(initialContext)
