import React from 'react';
import '../../../assets/css/storiesteam.css';

const TeamPage = () => {
  return (
    <div className="mi-ai-stories-win-team-container">
      <h1 className="mi-ai-stories-win-team-title">Winning Team</h1>
      <div className="mi-ai-stories-win-team-sections">
        <div className="mi-ai-stories-win-team-section">
          <ul className="mi-ai-stories-win-team-list">
            <li>Data & Al Specialist, Mónica G. Cerveró: <a target='_blank' href='monica.garciacervero@microsoft.com'>monica.garciacervero@microsoft.com</a></li>
            <li>Technical Sales Specialist, Luis A. Rodriguez: <a target='_blank' href='luisrb@microsoft.com'>luisrb@microsoft.com</a></li>
            <li>Apps Specialist, David Mangas: <a target='_blank' href='dmangas@microsoft.com'>dmangas@microsoft.com</a></li>
            <li>Technical Sales Specialist, David Sancho: <a target="_blank" href="mailto:dsanchoruiz@microsoft.com">dsanchoruiz@microsoft.com</a></li>
            <li>Al Pod GBB, Almudena Fernández: <a target="_blank" href="mailto:almudena.fernandez@microsoft.com">almudena.fernandez@microsoft.com</a></li>
            <li>ISD Architect Data & Al, Israel Garcia Mesa: <a target="_blank" href="mailto:israelg@microsoft.com">israelg@microsoft.com</a></li>
            <li>Cloud Solution Architect, Carlos Mendible: <a target="_blank" href="mailto:cmendible@microsoft.com">cmendible@microsoft.com</a></li>
            <li>Global Business Manager, Antonio Hernández: <a target="_blank" href="mailto:anhernandez@microsoft.com">anhernandez@microsoft.com</a></li>
            <li>Data & Al Specialist, Felipe Tomazini: <a target="_blank" href="mailto:ftomazini@microsoft.com">ftomazini@microsoft.com</a></li>
            <li>Account Executive, Isabel Alonso: <a target="_blank" href="mailto:isalonso@microsoft.com">isalonso@microsoft.com</a> & Claudia Stringel: <a target="_blank" href="mailto:stringe@microsoft.com">stringe@microsoft.com</a></li>
            <li>Account Technology Strategist, Jose Andrés Bermejo: <a target="_blank" href="mailto:jbermejo@microsoft.com">jbermejo@microsoft.com</a></li>
            <li>Data & Al STU Manager, Agustin Santamaria: <a target="_blank" href="mailto:agustin.santamaria@microsoft.com">agustin.santamaria@microsoft.com</a></li>

          </ul>
        </div>

      </div>

      <div className="mi-ai-stories-win-team-resources">
        <h3>Winwire Resources</h3>
        <ul className="mi-ai-stories-win-team-list">
          <li><a href="#">Subscribe to Al Design Win - WinWire/LiveWire</a></li>
          <li><a href="#">Submit an AI Design Win - WinWire/LiveWire</a></li>
          <li><a href='%'>Al Design Win - WinWire Template</a></li>
          <li><a href='#'>Al Design Win - LiveWire</a></li>
        </ul>
      </div>
    </div>
  );
};

export default TeamPage;

