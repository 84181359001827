import { useDispatch, useSelector } from "react-redux";
import { Header } from "../header"
import { RootState } from "@src/store/store";
import { LeftBar } from "../page/leftbar";
import { useEffect, useRef, useState } from "react";
import { aiSearchCall, fetchContents } from "@src/utils/api";
import { setAiAnswer, setAiQuestionUid, setContents } from "@src/store/reducers/commonReducer";
import AiSearch from ".";
import { Loader } from "../loader";
import "@assets/css/aisearchpage.css";
import DocumentIcon from "../icons/DocumentIcon";
import StarIcon from "../icons/StarIcon";
import SearchIcon from "../icons/Searchicon";
import MSIcon from "../icons/MsIcon";
import { v4 as uuidv4 } from 'uuid';
import BackIcon from "../icons/BackIcon";
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "@src/utils/functions";
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { config } from "@src/config";

interface IProps {
    tabindex: number
}

export const AiPage = (props: IProps) => {
    let isStream = config.isStream;
    const { tabindex } = props;
    const dispatch = useDispatch();
    const contents = useSelector((state: RootState) => state.common.contents);
    const menuItems = useSelector((state: RootState) => state.common.menu_items);
    const accountInfo = useSelector((state: RootState) => state.common.accountInfo);
    const aiQuestionUid = useSelector((state: RootState) => state.common.aiQuestionUid);
    const aiQuestion = useSelector((state: RootState) => state.common.aiQuestion);
    const aiAnswer = useSelector((state: RootState) => state.common.aiAnswer);
    const thread = useSelector((state: RootState) => state.common.thread);
    const isFirstRender = useRef(true);
    const aiQuestionUidRef = useRef<string>(aiQuestionUid);
    const [retry, setRetry] = useState<number>(0);
    const [answer, setAnswer] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [typing, setTyping] = useState<boolean>(false);
    const typingRef = useRef<HTMLSpanElement | null>(null);
    const BASE_URL = `${window.location.protocol}//${window.location.hostname}`;
    const navigate = useNavigate();

    const loadContents = async () => {
        const result = await fetchContents(accountInfo);
        if (result?.data) {
            dispatch(setContents(result.data));
        }
    }
    useEffect(() => {
        aiQuestionUidRef.current = aiQuestionUid
    }, [aiQuestionUid])
    useEffect(() => {
        if (contents.length === 0) {
            loadContents();
        }
    }, [accountInfo])
   
    function extractObjectsWithRegex(rawText: string): object[] {
        const objectArray: object[] = []; // Array to hold parsed objects
        const objectRegex = /\{[^}]*\}/g; // Regex to match JSON-like objects
    
        let match;
        while ((match = objectRegex.exec(rawText)) !== null) {
            try {
                const parsedObject = JSON.parse(match[0]); // Parse the matched object
                objectArray.push(parsedObject); // Add to the result array
            } catch (error) {
                console.error("Failed to parse JSON object:", match[0], error);
            }
        }
    
        return objectArray;
    }
   
    function updateAnswerState(key: string, value: string): void {
        setAnswer((prevState: any) => {
            // Check if the key is one of the array fields
            if (key === "key_points" || key === "sources") {
                const result = extractObjectsWithRegex(value);
                // Append new items to the existing array
                return {
                    ...prevState,
                    [key]: result,
                };
            } else {
                // For other keys, update or set the value
                return {
                    ...prevState,
                    [key]: value,
                };
            }
        });
    }
    
    const searchFromAi = async () => {
        setLoading(true);
        const quid = uuidv4();
        dispatch(setAiAnswer(null));
        dispatch(setAiQuestionUid(quid));
        if (isStream === true) {
            let apiUrl = getApiUrl() + "/api/ai-search-stream";

            const requestBody = {
                thread: thread,
                question: aiQuestion,
                question_uid: quid,
            };

            try {
                let buffer = "";
                let openBracesCount = 0;

                await fetchEventSource(apiUrl, {
                    method: 'POST', // Use POST for sending data
                    headers: {
                        'Content-Type': 'application/json',
                        ...accountInfo
                    },
                    body: JSON.stringify(requestBody), // Send request body
                    onopen: async (response: Response): Promise<void> => {
                        if (!response.ok) {
                            throw new Error(`Failed to connect: ${response.statusText}`);
                        }
                        console.log('Connection opened');
                    },
                    onmessage: (event) => {
                        const parsedData = JSON.parse(event.data);
                        // Check if the event is of type "thread.message.delta"
                        if (parsedData.event === "thread.message.delta") {
                            setLoading(false);
                            setTyping(true);
                            const delta = parsedData.data?.delta;

                            // Check if delta.content[0].text exists
                            if (delta?.content && delta.content[0]?.type === "text" && delta.content[0].text?.value) {
                                const textValue = delta.content[0].text.value;

                                // Append text value to the buffer
                                buffer += textValue;

                                // Regular expressions to extract keys and their partial or full values
                                const keyRegexes = {
                                    summary: /"summary"\s*:\s*"([^"]*)/,
                                    answer: /"answer"\s*:\s*"([^"]*)/,
                                    key_points: /"key_points"\s*:\s*\[([^\]]*)/,
                                    sources: /"sources"\s*:\s*\[([^\]]*)/,
                                };

                                // Check each key for matches in the current buffer
                                for (const [key, regex] of Object.entries(keyRegexes)) {
                                    const match = buffer.match(regex);
                                    if (match) {
                                        updateAnswerState(key, match[1])
                                    }
                                }
                                if (typingRef.current) {
                                    const element = typingRef.current;
                                    const rect = element.getBoundingClientRect();
                                    const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
                              
                                    if (!isVisible) {
                                      element.scrollIntoView({ behavior: "smooth", block: "end" });
                                    }
                                  }
                                
                            }
                        } else if( parsedData.event === "thread.run.completed" || parsedData.status === "Stream completed") {
                            setTyping(false);
                        }
                        
                    },
                    onerror: (error) => {
                        console.error('Stream error:', error);
                         setTimeout(() => {
                            searchFromAi();
                         },2000)
                        throw error; // Stop reconnection attempts
                    },
                    onclose: () => {
                        console.log('Connection closed by server');
                    },
                });
            } catch (error) {
                console.error('Error during streaming:', error);
            }

        } else {
            try {
                const result = await aiSearchCall({ question: aiQuestion, question_uid: quid, thread: thread }, accountInfo);
                if (aiQuestionUidRef.current === result.question_uid) {
                    isFirstRender.current = true;
                    dispatch(setAiAnswer(result?.data));
                    setLoading(false);
                }
            } catch (error) {
                if (retry < 5) {
                    setRetry(prevRetry => prevRetry + 1);
                    searchFromAi();
                }
            }
        }


    }
    useEffect(() => {

        if (aiQuestion && thread && isFirstRender.current) {
            // Skip the first render
            isFirstRender.current = false;
            if (thread) {
                setRetry(0);
                searchFromAi();
            }
        }


    }, [aiQuestion, thread])
    console.log("AI ANSWER ", aiAnswer)
    // let answer = null;
    const extractJSON = (response: string) => {
        const match = response.match(/\{[\s\S]*\}/); // Find the first JSON block
        if (match) {
            try {
                return JSON.parse(match[0]); // Parse the extracted JSON
            } catch (error) {
                console.error("Invalid JSON:", error);
            }
        }
        return null; // Return null if no valid JSON found
    };
    const backPage = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate("/");
        }
    }
    const extractAndParseJSON = (rawString: string) => {
        try {
            // Remove surrounding triple backticks and "json" keyword if present
            const cleanedString = rawString.replace(/^```json\n/, '').replace(/\n```$/, '').trim();

            // Parse the cleaned JSON
            return JSON.parse(cleanedString);
        } catch (error) {
            console.error("JSON Parsing Error:", error);
            return extractJSON(rawString); // Handle error gracefully
        }
    };
    const isValidUrl = (path: string): boolean => {
        const regex = /^(https?:\/\/)/; // Check for http:// or https://
        return regex.test(path);
    }
    // if (aiAnswer?.content[0]?.text?.value) {
    //     answer = extractAndParseJSON(aiAnswer?.content[0]?.text?.value);
    // }
    // console.log("PARSED ANSWER ", answer)

    let sources = answer?.sources ? answer?.sources.filter((x:any) => (x.url_path === 'home' || x.url_path === '/home' || contents.find((y:any) => y.url_path === x.url_path.replace('/','') ))) : [];
    return (
        <div className="ms-ai-wrapper" key={aiQuestion}>
            <div className="ms-ai-container">
                {/* Header section starts */}
                <Header />
                {/* Header section ends */}
                {/* Inner Page Content Starts */}
                <div className="ms-ai-inner-page-wrapper">
                    <div className="ms-ai-inner-page-container ai-container">

                        {/* {(menuItems.length > 0) && <LeftBar tabindex={tabindex} />} */}
                        {(aiAnswer?.content[0]?.text?.value && !answer) && <div>{aiAnswer?.content[0]?.text?.value}</div>}
                        <div className="ms-ai-inner-page-right ms-ai-search-result">
                            <div className="ms-ai-search-back" onClick={backPage}>
                                <BackIcon />
                                <span className="ms-ai-search-back-text">Back</span>
                            </div>
                            {config.enableAISearch && <div className="ms-ai-search-wrapper">
                                <AiSearch />
                            </div>}

                            {aiQuestion && <div className="ms-ai-question">{aiQuestion}</div>}
                            {aiQuestion === '' && <div>Please enter any question</div>}

                            {answer?.summary && <div className="ms-ai-search-summary">
                                <div className="ms-ai-search-summary-heading">
                                    <DocumentIcon /> Summary
                                </div>
                                <div className="ms-ai-search-summary-desc">
                                    {answer.summary}{(typing && !answer?.answer) && <span  className="typing-cursor"></span>}
                                </div>
                            </div>}


                            {(answer?.key_points || answer?.answer) && <div className="ms-ai-search-summary">
                                {answer?.key_points && answer?.key_points.length > 0 && <div className="ms-ai-search-summary-heading">
                                    <StarIcon /> Key Points
                                </div>}
                                {answer?.answer && <div className="ms-ai-search-summary-desc">
                                    {answer?.answer}{(typing && !answer?.key_points) && <span className="typing-cursor"></span>}
                                </div>}
                                {answer?.key_points &&
                                    <ol>
                                        {answer?.key_points.map((item: { title: string, description: string }) => {
                                            return (
                                                <li>
                                                    <div className="ms-ai-search-list-title">{item.title}</div>
                                                    <div className="ms-ai-search-list-desc">
                                                        {item.description}
                                                    </div>
                                                </li>
                                            )
                                        })}

                                    </ol>
                                }
                                {(typing && !answer?.sources) && <span className="typing-cursor"></span>}

                            </div>}
                            <br />
                            {answer?.sources && answer?.sources.length > 0 && <hr />}
                            {sources.length > 0 && <div className="ms-ai-search-summary">
                                <div className="ms-ai-search-summary-heading">
                                    <SearchIcon /> Sources
                                </div>

                                <ol className="ms-ai-search-result-list">
                                    {sources.map((item: { title: string, url_path: string }, index: any) => {
                                        let url = item.url_path;

                                        if (!isValidUrl(item.url_path)) {
                                            if (item.url_path === 'home' || item.url_path === '/home') {
                                                url = BASE_URL;
                                            } else {
                                                url = BASE_URL + '/' + item.url_path;
                                            }
                                        }
                                        return (
                                            <li>
                                                <div className="ms-ai-search-title">
                                                    <div className="ms-ai-list-count">{index+1}.</div>
                                                    <div className="ms-ai-icon"><MSIcon /></div>
                                                    <div className="ms-ai-search-title-link">
                                                        <a href={url} target="_blank" rel="noreferrer" >{item.title}</a>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}

                                </ol>
                                
                            </div>}
                            {(typing && answer?.sources) && <span  className="typing-cursor"></span>}

                            <span ref={typingRef}  className="typing-cursor-end"></span>

                            {loading && <div className="ms-ai-stream-loader"><Loader /></div>}



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}