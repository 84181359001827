import axios from 'axios';
import {config} from '../config';

const apiUrl = config.apiUrl;

export async function loginVerify(params: any) {
    return axios({
        method: 'post',
        url: apiUrl + 'user/login',
        headers: {'Content-Type': 'application/json'},
        data: params
    });
}

export async function tryLogin(params: { email: string, profile_path: string }) {
    return axios({
        method: 'get',
        url: apiUrl + 'user/check-email-exist?email=' + params.email + '&profile_path=' + params.profile_path,
        headers: {'Content-Type': 'application/json'},
    });
}

