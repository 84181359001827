interface IProps {
    data: any
    pageDetails: any
    index: number
}

export const TitleDescription = (props: IProps) => {
    const {data, pageDetails, index} = props;
    const contentTitle = () => {
        let title = data?.title;
        if(index === 0 && pageDetails?.title) {
            title = pageDetails?.title;
        }
        return title;
    }
    return (
        <div>
            {contentTitle() && <div className={`${data.classname ? data.classname : "ms-ai-inner-page-main-title"}`}
                                dangerouslySetInnerHTML={{__html: contentTitle()}}></div>}
            {data.items.map((itm: { subtitle?: string, description?: string }) => {
                return (
                    <>
                        <div className="ms-ai-inner-page-main-desc">
                            <p className="innerpage-sub-title">{itm.subtitle && <div dangerouslySetInnerHTML={{__html: itm.subtitle}}/> }</p>
                            <p>
                                {itm.description && <div dangerouslySetInnerHTML={{__html: itm.description}}/>}
                            </p>
                            </div>
                    </>
                )
            })}
            {(data.subtitle || data.description) && <div className="ms-ai-inner-page-main-desc">
                {data.subtitle && <p className="innerpage-sub-title">{data.subtitle && <div dangerouslySetInnerHTML={{__html: data.subtitle}}/>}</p>}
                {data.description && <p>
                    <div dangerouslySetInnerHTML={{__html: data.description}}/>
                </p>}
            </div>}
        </div>
    )
}