import { geturlPrefix } from "@src/utils/functions";
import React from "react";
import {Link} from "react-router-dom";
import AiSearch from "../aiserach";
import { config } from "@src/config";
import { SlideDataType } from "@src/types";
import { useSelector } from "react-redux";
import { RootState } from "@src/store/store";

interface IProps {

}

let urlPrefix = geturlPrefix();

export const Banner = (props: IProps) => {
    let slideData: null | SlideDataType = null;
    const homeData = useSelector((state: RootState) => state.common.homeData);
    if(homeData?.slider_data && homeData?.slider_data.length > 0) {
        slideData = homeData?.slider_data[0];
    }
    return (
        <div className="ms-ai-hero-wrapper">
            <div className="ms-ai-hero-container">
                <div className="ms-hero-bg-img">
                    <img src={urlPrefix + "assets/images/main-large-new.png"} alt=""/>
                </div>
                <div className="ms-ai-hero-slider-wrapper ai-container">
                    <div className="ms-ai-hero-slider-container">
                        <div className="ms-ai-hero-slider-item">
                            <div className="ms-cat-small-title">{slideData?.top_title ? slideData?.top_title : "Microsoft AI"}</div>
                            <div className="ms-page-big-title">{slideData?.title ? slideData?.title : "AI Design Win"}</div>
                            {/* <div className="ms-page-small-title">Material Science Discovery
                                    Usecase
                                </div> */}
                            <div className="ms-page-desc">
                            {slideData?.subtitle ? slideData?.subtitle : "Transform customer interactions, boost efficiency, and drive sales forward with Microsoft AI."}
                            </div>
                            {config.enableAISearch && <AiSearch />}
                            {/* <Link className="ms-ai-btn"
                                  to="aidesignsub">Learn
                                more</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
