import { RootState } from "@src/store/store";
import { geturlPrefix } from "@src/utils/functions";
import React from "react";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/styles.css";

interface DataItem {
    key: string;
    path?: string[];
    title?: string;
    layout?: string;
    thumbnail?: string;
    content?: any; // You might want to define this more precisely depending on what it contains
    items?: DataItem[];
    prev?: string,
    next?: string
}

interface IProps {
    data: any;
    pageData: any
    tabindex: number
}

export const ImageSlider = (props: IProps) => {
    const {data, pageData} = props;
    const menuItems = useSelector((state: RootState) => state.common.menu_items);
    const contents = useSelector((state: RootState) => state.common.contents);
    let {tabindex} = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const items = data.items ? data.items : [];
    const navigate = useNavigate();
    const checkPageData = (itemkey: string) => {
        navigate("/" + itemkey);
    }
    const loadPage = (technicalPatterns:any, index:number) => {
        if(index < technicalPatterns.length) {
            const indexVal = technicalPatterns[index];
            
            if(indexVal) {
                const contentInfo = contents.find((x:any) =>  x._id === indexVal.content_id)
                
                if(contentInfo) {
                    window.location.href = "/"+contentInfo.url_path
                }
            }

        }
        
    }
    let urlPrefix = geturlPrefix();
    let technicalPatterns:any = [];
    if(pageData?.details?.technical_pattern === true) {
        let activeId = pageData?.details?._id;
        const menuInfo = menuItems.find((x:any) => x.content_id === activeId);
        if(menuInfo) {
            const parentId = menuInfo.parent_id;
            if(parentId) {
                 technicalPatterns = menuItems.filter((x:any) => x.parent_id === parentId && x.technical_pattern);
            }
        }
    }
    const techIndex = technicalPatterns.findIndex((x:any) => x.content_id === pageData?.details?._id)

    return (
        <div className={`ms-ai-blog-img-wrapper ${(technicalPatterns.length > 1) ? '' : 'ms-ai-no-navigation'}`}>
            {items.map((item: any, index: number) => {
                //tabindex++;
                let imageUrl = urlPrefix + 'assets/images/usecases/img.png';
                if(item.thumbnail) {
                    if(item.thumbnail.startsWith("http")) {
                        imageUrl = item.thumbnail;
                    } else {
                        imageUrl = urlPrefix + item.thumbnail;
                    }
                }
                
                return (
                    <div tabIndex={tabindex} className="ms-ai-blog-img-container" key={"image-item-" + index}>
                        <img src={imageUrl} alt="" onClick={() => setOpen(true)}/>
                        <Lightbox
                            carousel={{finite: true}}
                            open={open}
                            close={() => setOpen(false)}
                            slides={[
                                {src: urlPrefix + item.thumbnail},
                            ]}
                            plugins={[Download]}
                        />
                    </div>
                )
            })}


            <div className="ms-ai-blog-next-prev-btn-wrapper">
                <a tabIndex={tabindex} aria-label="Prev" href="#" className={techIndex > 0 ? '' : "ms-ai-btn-disabled"}
                 onClick={() => technicalPatterns.length > 1 && techIndex > 0 && loadPage(technicalPatterns,techIndex-1)}>
                    <svg
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="0.669355"
                            y="0.669355"
                            width="37.4839"
                            height="37.4839"
                            rx="18.7419"
                            stroke="#2A446F"
                            strokeWidth="1.33871"
                        ></rect>
                        <g clip-path="url(#clip0_186_169)">
                            <path
                                d="M25.4354 18.9934C25.4354 19.1067 25.394 19.2047 25.3112 19.2875C25.2284 19.3703 25.1304 19.4117 25.0171 19.4117H13.4799L18.6177 24.5561C18.7005 24.6389 18.7419 24.7369 18.7419 24.8502C18.7419 24.9635 18.7005 25.0616 18.6177 25.1444C18.5349 25.2272 18.4368 25.2686 18.3235 25.2686C18.2102 25.2686 18.1122 25.2272 18.0294 25.1444L12.1921 19.3071C12.1442 19.2592 12.1083 19.2112 12.0843 19.1633C12.0603 19.1154 12.0483 19.0587 12.0483 18.9934C12.0483 18.928 12.0603 18.8713 12.0843 18.8234C12.1083 18.7755 12.1442 18.7275 12.1921 18.6796L18.0294 12.8423C18.1122 12.7596 18.2102 12.7182 18.3235 12.7182C18.4368 12.7182 18.5349 12.7596 18.6177 12.8423C18.7005 12.9251 18.7419 13.0232 18.7419 13.1365C18.7419 13.2498 18.7005 13.3478 18.6177 13.4306L13.4799 18.575H25.0171C25.1304 18.575 25.2284 18.6164 25.3112 18.6992C25.394 18.782 25.4354 18.8801 25.4354 18.9934Z"
                                fill="#2A446F"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_186_169">
                                <rect
                                    width="13.3871"
                                    height="13.3871"
                                    fill="white"
                                    transform="matrix(1 0 0 -1 12.0483 26.105)"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a tabIndex={tabindex} aria-label="Next" href="#" className={pageData.next ? '' : "ms-ai-btn-disabled"}
                   onClick={() => loadPage(technicalPatterns,techIndex+1)}>
                    <svg
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="0.846723"
                            y="0.669355"
                            width="37.4839"
                            height="37.4839"
                            rx="18.7419"
                            stroke="#2A446F"
                            strokeWidth="1.33871"
                        ></rect>
                        <g clip-path="url(#clip0_186_172)">
                            <path
                                d="M20.6765 25.2686C20.5632 25.2686 20.4651 25.2272 20.3823 25.1444C20.2995 25.0616 20.2581 24.9635 20.2581 24.8502C20.2581 24.7369 20.2995 24.6389 20.3823 24.5561L25.5201 19.4117H13.9829C13.8696 19.4117 13.7716 19.3703 13.6888 19.2875C13.606 19.2047 13.5646 19.1067 13.5646 18.9934C13.5646 18.8801 13.606 18.782 13.6888 18.6992C13.7716 18.6164 13.8696 18.575 13.9829 18.575H25.5201L20.3823 13.4306C20.2995 13.3478 20.2581 13.2498 20.2581 13.1365C20.2581 13.0232 20.2995 12.9251 20.3823 12.8423C20.4651 12.7596 20.5632 12.7182 20.6765 12.7182C20.7898 12.7182 20.8878 12.7596 20.9706 12.8423L26.8079 18.6796C26.8558 18.7275 26.8918 18.7755 26.9157 18.8234C26.9397 18.8713 26.9517 18.928 26.9517 18.9934C26.9517 19.0587 26.9397 19.1154 26.9157 19.1633C26.8918 19.2112 26.8558 19.2592 26.8079 19.3071L20.9706 25.1444C20.8878 25.2272 20.7898 25.2686 20.6765 25.2686Z"
                                fill="#2A446F"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_186_172">
                                <rect
                                    width="13.3871"
                                    height="13.3871"
                                    fill="white"
                                    transform="matrix(1 0 0 -1 13.5646 26.105)"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
        </div>
    )
}