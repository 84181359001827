import React from "react";

const DocumentIcon: React.FC = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0377 7.73154L15.5762 1.27C15.4904 1.18431 15.3885 1.11636 15.2765 1.07003C15.1644 1.0237 15.0443 0.999905 14.9231 1H3.84615C3.35652 1 2.88695 1.19451 2.54073 1.54073C2.1945 1.88695 2 2.35652 2 2.84615V23.1538C2 23.6435 2.1945 24.1131 2.54073 24.4593C2.88695 24.8055 3.35652 25 3.84615 25H20.4615C20.9512 25 21.4207 24.8055 21.767 24.4593C22.1132 24.1131 22.3077 23.6435 22.3077 23.1538V8.38462C22.3078 8.26336 22.284 8.14327 22.2377 8.03122C22.1913 7.91916 22.1234 7.81733 22.0377 7.73154ZM15.8462 4.15115L19.1565 7.46154H15.8462V4.15115ZM20.4615 23.1538H3.84615V2.84615H14V8.38462C14 8.62943 14.0973 8.86422 14.2704 9.03733C14.4435 9.21044 14.6783 9.30769 14.9231 9.30769H20.4615V23.1538ZM16.7692 13.9231C16.7692 14.1679 16.672 14.4027 16.4989 14.5758C16.3258 14.7489 16.091 14.8462 15.8462 14.8462H8.46154C8.21672 14.8462 7.98194 14.7489 7.80883 14.5758C7.63571 14.4027 7.53846 14.1679 7.53846 13.9231C7.53846 13.6783 7.63571 13.4435 7.80883 13.2704C7.98194 13.0973 8.21672 13 8.46154 13H15.8462C16.091 13 16.3258 13.0973 16.4989 13.2704C16.672 13.4435 16.7692 13.6783 16.7692 13.9231ZM16.7692 17.6154C16.7692 17.8602 16.672 18.095 16.4989 18.2681C16.3258 18.4412 16.091 18.5385 15.8462 18.5385H8.46154C8.21672 18.5385 7.98194 18.4412 7.80883 18.2681C7.63571 18.095 7.53846 17.8602 7.53846 17.6154C7.53846 17.3706 7.63571 17.1358 7.80883 16.9627C7.98194 16.7896 8.21672 16.6923 8.46154 16.6923H15.8462C16.091 16.6923 16.3258 16.7896 16.4989 16.9627C16.672 17.1358 16.7692 17.3706 16.7692 17.6154Z"
        fill="#181C32"
      />
    </svg>
  );
};

export default DocumentIcon;
