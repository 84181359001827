import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Right } from "../arrows/Right";
import { Left } from "../arrows/Left";
import { geturlPrefix } from "@src/utils/functions";
import { useSelector } from "react-redux";
import { RootState } from "@src/store/store";
import { label } from "yet-another-react-lightbox";
import { getRelatedUsecases } from "@src/utils/api";

interface IProps {
    data: any;
    key: string
    tabindex: number
}


export const CardSlider = (props: IProps) => {
    const { data, key } = props;
    let items = data.items ? data.items : [];
    let { tabindex } = props;
    const navigate = useNavigate();
    const contents = useSelector((state: RootState) => state.common.contents);
    const pageData = useSelector((state: RootState) => state.common.pageData);
    const accountInfo = useSelector((state: RootState) => state.common.accountInfo);
    const mainDivRef = React.useRef<HTMLDivElement>(null);
    const [divHeight, setDivHeight] = useState<number | null>(null);
    const [showAll, setShowAll] = useState<boolean>(false);
    let sliderRef = React.useRef<any>(null);
    const checkPageData = (slide: any) => {
        if (slide.content_type === "External Link" && slide.link && slide.link !== "#") {
            window.open(slide.link, '_blank')
        } else if (slide.content_type === "External Link" && slide.link && slide.link === "#") {
            return false;
        } else if (slide.content_id) {
            const contentItem = contents.find((x: any) => x._id === slide.content_id)
            if (contentItem) {
                navigate("/" + contentItem.url_path);
            }

        }

    }
    const fetchRelatedUsecases = async (content_id: string) => {
        const result = await getRelatedUsecases({ content_id: content_id }, accountInfo);
        if (result && result?.data) {
            setItemsFiltered(result?.data)
        }
    }


    const [itemsFiltered, setItemsFiltered] = useState<any>(items);
    const [selectedTag, setSelectedTag] = useState<string>('All');
    let urlPrefix = geturlPrefix();
    const tags: { label: string, value: string }[] = [
        {
            label: "All",
            value: 'All'
        },
        {
            label: "AIDW Verified",
            value: "AIDW Verified"
        },
        {
            label: "Emerging Use Case",
            value: "Emerging Use Case"
        },
        {
            label: "Hero Use Case",
            value: "Hero Use Case"
        }
    ];
    useEffect(() => {
        if (data?.content_type === "related-use-cases") {
            let mainId = pageData?.details?._id;
            if (pageData?.details?.content_id) {
                const contentId = pageData?.details?.content_id;
                const contentInfo = contents.find((x: any) => x._id === contentId);
                mainId = contentInfo?.content_id ? contentInfo?.content_id : contentId;
            }

            fetchRelatedUsecases(mainId);
        }
        if (mainDivRef.current) {
            const currentHeight = mainDivRef.current.offsetHeight;
            setDivHeight(currentHeight);
        }
    }, [])
    useEffect(() => {
        let itemsdata = items;
        if (selectedTag !== "All") {
            itemsdata = itemsdata.filter((x: any) => x.tags && x.tags.find((y: any) => y.value === selectedTag))
        }
        setItemsFiltered(itemsdata)
    }, [selectedTag])
    const isRelatesUsecases = data?.content_type === "related-use-cases";
    return (
        <div className={`ms-ai-slider-wrapper ${data.classname ? data.classname : ""} `}>
            <div className="ms-ai-slider-container">
                {(data.title.toLowerCase() !== 'priority use cases' && !isRelatesUsecases) && <div className="ms-ai-item-group-block-title-area padding-b-40">
                    <div className="ms-ai-card-slider-block-title">{data.title}</div>
                    {data.subtitle && <div className="ms-ai-card-slider-subtitle">{data.subtitle}</div>}
                </div>}
                {data.title.toLowerCase() === 'priority use cases' && <div className="innerpage-sub-title ms-ai-title-small">{data.title}</div>}
                <div className="ms-ai-card-cat-title-btn-block pb-37">
                {data.title.toLowerCase() === 'priority use cases' ? <div className="ms-ai-slider-tags">
                    {tags.map((tag: { label: string, value: string }) => {
                        const dataExist = tag.label === "All" || items.find((x: any) => x.tags && x.tags.find((y: any) => y.value === tag.value));
                        return <span className={`ms-ai-tag-filter-item  ${selectedTag === tag?.value && 'active'}`} onClick={() => setSelectedTag(tag.value)}>{tag.label}</span>
                    })}
                </div> : <div></div>}
                {itemsFiltered.length > 4 && <div className="ms-ai-card-cat-show-all-container">
                    <button className="ms-ai-card-cat-show-all-btn" onClick={() => setShowAll(!showAll)}>{showAll ? "Show Less" : "Show All"}</button>
                </div>}
            </div>
            <div className="ms-ai-slider-block">
                <div className={`${showAll ? "ms-ai-show-as-grid" : "slider-container slide-paddings home-slider-cards horizontal-scroll-container"} ${itemsFiltered.length === 0 && 'card-data-slider-no-data'} ${(data.title.toLowerCase() !== 'priority use cases' && itemsFiltered.length < 5) && 'ms-justify-center'}`} ref={mainDivRef} style={{ minHeight: divHeight ? `${divHeight}px` : undefined }}>
                    {itemsFiltered.length === 0 && <div className="ms-ai-no-data-container"><div className="ms-ai-no-data-item">No data available</div></div>}
                    {itemsFiltered.map((slide: any, slideindex: number) => {
                        let imageUrl = urlPrefix + 'assets/images/usecases/img.png';

                        if (slide.thumbnail) {
                            if (slide.thumbnail.startsWith("http")) {
                                imageUrl = slide.thumbnail;
                            } else {
                                imageUrl = urlPrefix + slide.thumbnail;
                            }

                        }
                        return (
                            <div key={"slide-card-" + slideindex} className={`ms-ai-slider-item ${slide.classname ? slide.classname : ''} ${(slide?.image_fit === 'Contain' || (!slide?.image_fit && data.title.toLowerCase() !== 'priority use cases')) ? 'ms-ai-slider-img-contain' : ''}`}
                                onClick={() => slide && checkPageData(slide)}>

                                <div className="ms-ai-slider-img-block">
                                    <img src={imageUrl} alt="" />
                                </div>
                                <div className="ms-ai-slider-title">
                                    {slide.content_data?.title ? slide.content_data?.title : slide.title}
                                </div>
                                <div className="ms-ai-slider-desc">
                                    <div dangerouslySetInnerHTML={{ __html: slide.description }} />
                                </div>
                                {(data.title.toLowerCase() === 'priority use cases' && slide?.tags) && <div className="ms-ai-tags-container">
                                    {slide.tags.map((tag: any) => {
                                        return <span className="ms-ai-tag-item">{tag.label}</span>
                                    })}
                                </div>}
                                {(slide.link || slide.key) && <a className="ms-ai-learnmore" href={slide.link !== '' && slide?.link !== "#" && slide.link}
                                    target={slide.link === "#" ? "_self" : "_blank"}>
                                    <span>
                                        <svg
                                            width={26}
                                            height={27}
                                            viewBox="0 0 26 27"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.459839"
                                                y="0.789062"
                                                width="25.0611"
                                                height="25.2778"
                                                rx="5.77778"
                                                fill="#0078D4"
                                            />
                                            <g clipPath="url(#clip0_126_3085)">
                                                <path
                                                    d="M10.5709 17.1517C10.5709 17.06 10.6044 16.9807 10.6714 16.9137L14.1521 13.4277L10.6714 9.94181C10.6044 9.87481 10.5709 9.79546 10.5709 9.70378C10.5709 9.61209 10.6044 9.53274 10.6714 9.46574C10.7384 9.39874 10.8178 9.36523 10.9095 9.36523C11.0012 9.36523 11.0805 9.39874 11.1475 9.46574L14.8715 13.1897C14.9385 13.2567 14.972 13.336 14.972 13.4277C14.972 13.5194 14.9385 13.5988 14.8715 13.6658L11.1475 17.3897C11.0805 17.4567 11.0012 17.4902 10.9095 17.4902C10.8178 17.4902 10.7384 17.4567 10.6714 17.3897C10.6044 17.3227 10.5709 17.2434 10.5709 17.1517Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_126_3085">
                                                    <rect
                                                        width="10.6167"
                                                        height="10.8333"
                                                        fill="white"
                                                        transform="matrix(1 0 0 -1 10.5709 18.8447)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span>{slide?.linkName}</span>
                                </a>}

                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
        </div >
    )
}