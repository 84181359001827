import "../../assets/css/maintenance.css";
import maintenanceImage from "../../assets/images/maintenance.png"

export const Maintenance = () => {


    return (
        <div className={`tc-msft-maintenance-mode`}>
            <div className="tc-msft-container">
                <img
                    src={maintenanceImage}
                    alt="Maintenance Mode"
                    className="tc-msft-icon"
                />
                <h1 className="tc-msft-text">We'll Be Back Soon!</h1>
                <p>
                    Our website is currently undergoing scheduled maintenance to improve your experience.
                </p>
                <p> We expect to be back online shortly. </p>
                <p>
                    Thank you for your patience!
                </p>
            </div>
        </div>
    )
}