import React from 'react';
import '../../../assets/css/storiesteam.css';

const TeamPage = () => {
  return (
    <div className="mi-ai-stories-win-team-container">
      <h1 className="mi-ai-stories-win-team-title">Winning Team</h1>
      <p>This victory would not have been possible without our united front as One Microsoft. Let me say Thanks to:</p>
      <div className="mi-ai-stories-win-team-sections">
        <div className="mi-ai-stories-win-team-section">
          <ul className="mi-ai-stories-win-team-list">
            <li><b>Account Executive</b>, Nicolas Herzog</li>
            <li><b>Account Technology Strategist</b>, Adrian Cervellieri</li>
            <li><b>Data & AI Specialist</b>, Thomas Schärli</li>
            <li><b>Specialist Sales Manager App Innovation</b>, Joseba Urzelai Intza</li>
            <li><b>Specialist Sales Manager Data&AI</b>, Franziska-Juliette Klebôn</li>
            <li><b>Specialist Sales Manager Data&AI</b>, Christian Markoc</li>
            <li><b>CSA Manager App Innovation</b>, Albert Noll</li>
            <li><b>Ap Manatir Datou lui Architect</b>, Maxim Gross</li>
            <li><b>Data&AI Cloud Solution Architect</b>, Nicolas Schlegel</li>
            <li><b>Data&AI Cloud Solution Architect</b>, Meinrad Weiss</li>
            <li><b>Data&AI Cloud Solution Architect</b>, Kristian Bubalo</li>
            <li><b>Global Black Belt AI</b>, Manuel Zorn</li>
            <li><b>Customer Success Account Manager</b>, Priska Jaeggi</li>

          </ul>
        </div>
        <div className="mi-ai-stories-win-team-section">
          <ul className="mi-ai-stories-win-team-list">
            <li><b>Principal PM Manager - ACS</b>, Richard Cole</li>
            <li><b>Principal Group Product Manager - ACS</b>, Ankur Agarwal</li>
            <li><b>Group Principal Product Manager</b>, Sundereshwaran Raman</li>
            <li><b>Technical Specialist Data</b>, Patrik Borosch</li>
            <li><b>Senior Product Manager - ACS</b>, Boris Bazilevskiy</li>
            <li><b>Technical Program Manager - ACS</b>, Kristen Kutser</li>
            <li><b>Senior Program Manager - ACS</b>, Kunaal Punjabi</li>
            <li><b>CSA Manager Data&AI</b>, Zoran Draganic</li>
            <li><b>Global Black Belt TSP AI</b>, Konstantinos Mavrodis</li>
            <li><b>Global Black Belt TSP AI</b>, Evgeny Minkevich</li>
            <li><b>Global Black Belt AI</b>, Nir Evron</li>
            <li><b>Global Black Belt AI</b>, Alberto Gallo</li>
            <li><b>Customer Success Account Manager Mgmt</b>, Monika Borowinska</li>
            <li><b>Customer Success Account Manager</b>, Johannes Gappmaier</li>
          </ul>
        </div>

      </div>

      <div className="mi-ai-stories-win-team-resources">
        <h3>Winwire Resources</h3>
        <ul className="mi-ai-stories-win-team-list">
          <li><a href="#">Subscribe to Al Design Win - WinWire/LiveWire</a></li>
          <li><a href="#">Submit an AI Design Win - WinWire/LiveWire</a></li>
          <li><a href='%'>Al Design Win - WinWire Template</a></li>
          <li><a href='#'>Al Design Win - LiveWire</a></li>
        </ul>
      </div>
    </div>
  );
};

export default TeamPage;

