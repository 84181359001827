import {useNavigate} from "react-router-dom";

interface IProps {
    data: any;
    tabindex: number
}

export const SmallCard = (props: IProps) => {
    let {data, tabindex} = props;
    const navigate = useNavigate();
    const checkPageData = (itemkey: string) => {
        navigate("/" + itemkey);
    }
    const items = data.items ? data.items : [];
    return (
        <div className="ms-ai-slider-wrapper ms-ai-small-card">
            <div className="ms-ai-slider-container">
                <div className="innerpage-sub-title">
                    {data.title}
                </div>
                <div className="ms-ai-slider-block">

                    {items.map((slide: any, slideindex: number) => {
                        //tabindex++;
                        return (
                            <div tabIndex={tabindex} key={"slide-item=" + slideindex} className="ms-ai-slider-item"
                                 onClick={() => slide?.key && checkPageData(slide?.key)}>
                                {slide.title && <div className="ms-ai-slider-title">
                                    {slide.title}
                                </div>}
                                {slide.description && <div className="ms-ai-slider-desc">
                                    <div dangerouslySetInnerHTML={{__html: slide.description}}/>
                                </div>}
                                {slide.link &&
                                    <a className="ms-ai-learnmore" target={slide.link === "#" ? "_self" : "_blank"}
                                       href={slide.link}>
                                    <span>
                                        <svg
                                            width={26}
                                            height={27}
                                            viewBox="0 0 26 27"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.459839"
                                                y="0.789062"
                                                width="25.0611"
                                                height="25.2778"
                                                rx="5.77778"
                                                fill="#0078D4"
                                            />
                                            <g clipPath="url(#clip0_126_3085)">
                                                <path
                                                    d="M10.5709 17.1517C10.5709 17.06 10.6044 16.9807 10.6714 16.9137L14.1521 13.4277L10.6714 9.94181C10.6044 9.87481 10.5709 9.79546 10.5709 9.70378C10.5709 9.61209 10.6044 9.53274 10.6714 9.46574C10.7384 9.39874 10.8178 9.36523 10.9095 9.36523C11.0012 9.36523 11.0805 9.39874 11.1475 9.46574L14.8715 13.1897C14.9385 13.2567 14.972 13.336 14.972 13.4277C14.972 13.5194 14.9385 13.5988 14.8715 13.6658L11.1475 17.3897C11.0805 17.4567 11.0012 17.4902 10.9095 17.4902C10.8178 17.4902 10.7384 17.4567 10.6714 17.3897C10.6044 17.3227 10.5709 17.2434 10.5709 17.1517Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_126_3085">
                                                    <rect
                                                        width="10.6167"
                                                        height="10.8333"
                                                        fill="white"
                                                        transform="matrix(1 0 0 -1 10.5709 18.8447)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                        <span>Learn more</span>
                                    </a>}

                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}