import React from "react";
import {useForm} from 'react-hook-form';
import {Loader} from "../loader";
import "../../assets/css/login.css";
import {useMsal} from "@azure/msal-react";

interface IProps {
    setLoginVerified: (verified: boolean) => void
    noAccess?: boolean
}

export const SSOLogin = (props: IProps) => {
    const {instance, accounts, inProgress} = useMsal();
    const {setLoginVerified, noAccess} = props;
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loginStatus, setLoginStatus] = React.useState<string>('login');
    const formRef = React.useRef<HTMLFormElement>(null);


    const {
        handleSubmit,
    } = useForm();

    const submitForm = async (data: any) => {
        await instance.loginPopup()
        setLoading(false);

    }

    /*if (accounts.length > 0) {
        console.log(accounts)
    }*/
    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }

    return (
        <div className={`tc-msft-main-container tc-login-wrapper sso`}>
            {loading && <Loader/>}

            {loginStatus == 'login' && <div className="tc-login-small">
                <div className="tc-msft-upload-container">

                    {/* <h2>Microsoft AI Use Case Explorer</h2> */}
                    <form ref={formRef} onSubmit={handleSubmit((data) => submitForm(data))}>
                        {/* <input disabled={loading} type='submit' className="di-btn company-btn submit-btn"
                               value={"Login with Microsoft"}/> */}

                        <img onClick={() => formRef.current?.requestSubmit()}
                             className={loading ? 'login-img-disabled' : ''}
                             src={urlPrefix + "assets/images/AI_Explorer_Splash.png"}/>

                        {noAccess &&
                            <p className='error'>
                                <svg width="30px" height="30px" viewBox="0 0 24 24"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" stroke="#bf1650" strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M12,3 L22,21 L2,21 L12,3 Z M12,9 L12,15 M12,16 L12,18"/>
                                </svg>
                                Sorry, you don't have access.
                            </p>
                        }
                    </form>
                </div>
            </div>
            }


        </div>
    )
}