import { useState } from "react";
import { ContentsArea } from "../../contentarea/contents";
import podcast from "@src/datas/podcasts";
import "./groupblock-two.css";
import AudioPlayer from "@src/components/audioplayer";

interface IProps {
    data: any
    pageData: any
    index: number
}


export const GroupTwoBlock = (props: IProps) => {
    const { data, index, pageData } = props;

    let content = pageData?.content ? pageData?.content : [];
    const pageDetails = pageData?.details ? pageData?.details : [];
    const subContentsLeft = content.filter((x: any) => (x?.data.group_id === data?.data?.cc_id && x?.data?.position == 'left'));
    const subContentsRight = content.filter((x: any) => (x?.data.group_id === data?.data?.cc_id && x?.data?.position == 'right'));

    const contentTitle = () => {
        let title = subContentsLeft[0]?.title;
        if (pageDetails?.title) {
            title = pageDetails?.title;
        }

        return title;
    }

    const title = contentTitle();
    const podcastExist = podcast.find((x: any) => x.key === title.toLowerCase());
    return (
        <div className="ms-ai-item-group-two-block">
            <div className="ms-ai-item-group-two-block-title-area">
                <div className="ms-ai-item-group-two-block-title">{data?.data?.title}</div>
                {title && <div className="ms-ai-item-group-two-block-subtitle">{title}</div>}
            </div>
            <div className="ms-ai-item-group-two-block-contents">
                <div className="ms-ai-item-group-two-block-left">
                {subContentsLeft.map((content: any, index: number) => {
                    return (
                        <div key={'tab-content' + index} className={`ms-ai-item-group-two-block-content-item`}>
                              <ContentsArea index={index} content={content} pageData={pageData} pageDetails={pageDetails} />
                        </div>
                    )
                })}
                </div>
                <div className="ms-ai-item-group-two-block-right">
                <div className="ms-ai-item-group-two-block-podcast">{(!data?.group_id && index === 0 && podcastExist) && <AudioPlayer podcast={podcastExist} title={title} />}</div>
                {subContentsRight.map((content: any, index: number) => {
                    return (
                        <div key={'tab-content' + index} className={`ms-ai-item-group-two-block-content-item`}>
                              <ContentsArea index={index} content={content} pageData={pageData} pageDetails={pageDetails} />
                        </div>
                    )
                })}
                </div>
                

            </div>

        </div>
    )
}