import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import ArchitectureDiagram from "./Architechture";
import TeamPage from "./TeamPage";
// import ArchitectureDiagram from "./TomtomArchitechture";
// import TeamPage from "./TomtomTeamPage";
export const Canadian = () => {

    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }

    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">Canadian Tire and Microsoft Strategic Partnership Leads to Azure Open AI Shopping Copilot Conversational Commerce Solution</div>

                <div className="innerpage-sub-title">Win summary & Customer Impact</div>
                <p>Canadian Tire Corporation Ltd. (CTC) is one of Canada’s most iconic and trusted retailers with over 1,700 locations coast-to-coast. Canadian Tire recently celebrated 100 years in Canada and is known as “Canada’s Store”. CTC’s diverse range of offerings encompasses automotive, hardware, sports, leisure, and housewares sectors, catering to the needs of countless customers.</p>
                <p>In 2022, CTC and Microsoft began exploring a strategic partnership touching on four pillars:
                </p>
                <p>
                    <ol className="ms-ai-ol-list">
                        <li>
                            Modernizing the business
                        </li>
                        <li>
                            Business Innovation
                        </li>
                        <li>
                            Upskilling the team
                        </li>
                        <li>Cost management</li>
                    </ol>
                </p>
                <div>
                    <p>CTC’s leadership stated that they did not want “just a technology vendor”, but also a partner who could help them shift the company’s culture.  They saw how Microsoft has successfully shifted our corporate culture around AI transformation, with our growth mindset approach illustrating this culture at every interaction with CTC during the journey to partnership.  In June 2023, <b>CTC entered into a <a href="https://nam06.safelinks.protection.outlook.com/?url=https://news.microsoft.com/en-ca/2023/06/22/canadian-tire-corporation-and-microsoft-partner-to-advance-retail-innovation-in-canada/&data=05|02|ardiez@microsoft.com|7339994a2d75479bcfc608dc866b159b|72f988bf86f141af91ab2d7cd011db47|1|0|638533041861211034|Unknown|TWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0=|0|||&sdata=8eS4d4CArmrVettb+S/MR/WzmY934ZavIYT9IJ66F+0=&reserved=0" target="_blank">7-year strategic partnership</a> with Microsoft</b> that in part involves migrating the majority of CTC’s IT infrastructure to the Azure platform.</p>
                    <p>
                        Exploring co-innovation opportunities around AI is another core area of our partnership, and since May 2023 Canadian Tire has been working on <b>Shopping Copilot</b> alongside the Microsoft ISE team.  CTC chose to start with their most difficult product line of Tires—complex due to various degrees of search and fitment needed for each product—because each conversion is high-value for CTC ($800+ Canadian dollars for every set of tires).  The resulting Shopping Copilot solution, called CeeTee, launched in March 2024 and is accessed by shoppers within the Canadian Tire app.
                    </p>
                </div>
                <div className="mi-ai-stories-container">
                    <div className="mi-ai-stories-content">
                        <div className="mi-ai-stories-win-snapshot">
                            <h2>Win Snapshot</h2>
                            <div className="mi-ai-stories-win-content">
                                <strong>Go-Live Date:</strong> <p>March 2024</p>
                                <strong>Area:</strong><p>Canada</p>
                                <strong>Country:</strong><p>Canada</p>
                                <strong>Segment:</strong> <p>Enterprise commercial, Strategic</p>
                                <strong>Industry:</strong> <p>Retail and Consumer Goods</p>
                                <strong>Technical Pattern:</strong><p>
                                    <ul className="mi-ai-stories-list">
                                        <li>Personalized recommendations</li>
                                    </ul>
                                </p>
                                <strong>Azure Impact ($ Montly):</strong>
                                <p>
                                    <ul className="mi-ai-stories-list">
                                        <li>200 PTU running GPT 4 </li>
                                        <li>AOAI ACR $45k/month</li>
                                        <li>App ACR $5k/month</li>
                                        <li>Data ACR $20k/month</li>
                                    </ul>

                                </p>
                                <strong>Opportunity ID:</strong><p>7-35T3WNR4JN</p>
                                <strong>Programs/Offers:</strong> <p>ISE Investment</p>
                                <strong>Partner/ISV:</strong> <p>EPAM</p>
                                <strong>Compete:</strong> <p>Google/GCS</p>
                            </div>
                        </div>
                        <div className="mi-ai-stories-main-content">
                            <div className="mi-ai-stories-solution">
                                <h2>Solution</h2>
                                <p>Shopping Copilot solution called CeeTee, deployed in the Canadian Tire app and initially focused on the tire shopping journey; supports text and voice interaction.  Azure components include:</p>

                                <ul className="mi-ai-stories-list">
                                    <li>Azure OpenAI</li>
                                    <li>Azure Kubernetes Services</li>
                                    <li>Azure Functions</li>
                                    <li>Azure API Management</li>
                                    <li>Cosmos DB</li>
                                    <li>Azure Databricks Analytics Environment (pre-existing)</li>
                                </ul>
                                <p>
                                The system is currently linked with their on-prem automotive product search deployment
                                </p>
                                <p>
                                Canadian Tire has purchased <b>200 PTU running GPT 4 (0613)</b> (auto-renew) for their Prod and Stage environments 

                                </p>
                                <p>
                                Pilot solution originally leveraged GPT 3.5 Turbo but decided

                                </p>
                                <p>
                                to <b>explore GPT 4 for better response times.</b>  The AI Pod GBB, working with CTC, ISE and CSU resources, refined the code/model choices/API calls to <b>reduce latency from 40-60+ seconds for some calls, down to an average of 7 seconds</b>, with the solution moving to  PTU from PAYGO; currently using a hybrid model approach to balance latency and cost.

                                </p>
                            </div>
                            <div className="mi-ai-stories-why-we-won">
                                <h2>Why we won?</h2>
                                <p><b>Technology and culture</b> is why CEO chose MSFT over Google: AI requires fundamental culture change, CTC is fascinated by how MSFT has transformed its own culture for AI transformation</p>
                                <p>
                                Game-changing moment was <b>EBC with C-Suite</b>, where primary focus was on culture

                                </p>
                                <p>
                                    <b>Strategic Pursuits Team</b> also engaged early on and was instrumental in helping pull together the overall partnership deal
                                </p>
                                <p>
                                March 2023, Microsoft conducted a <b>workshop with CTC C-level executives</b> based 5 of their own AI use cases, then worked to select Shopper Assistant use case in May 2023
                                </p>
                               
                            </div>
                            <div className="mi-ai-stories-business-impact">
                                <h2>Business Impact</h2>
                                <p>
                                Though only in limited release currently, <b>early results show significant lift in conversion</b> for a high value purchase ($800+) and <b>very positive customer experience</b> as indicated by large majority of conversations leading to recommendation
                                </p>
                            </div>
                            <div className="mi-ai-stories-repeatable-ip-pattern">
                                <h2>Repeatable IP & Pattern</h2>
                                <p>
                                Canadian Tire’s own IT Digital Group built the solution alongside Microsoft ISE team, so all IP is CTC proprietary
                                </p>
                            </div>
                            <div className="mi-ai-stories-expansion">
                                <h2>Expansion</h2>
                                <ul className="mi-ai-stories-list">
                                    <li>Canadian Tire is working to <b>expand CeeTee from Tires to all Automotive and eventually General Merchandise</b>, including kitchen and other appliances, power tools, camping equipment, climate control, paints, and bicycles</li>
                                    <li>Many vendors sell their products through the CTC web site, <b>moving from POC to Pilot (May 2024) on an AI solution to accelerate creation of the product detail pages</b> through translation and refinement of product copy generation, image metadata tagging, and search engine optimization; CTC estimate they currently lose $1M Canadian weekly because not all products are online</li>
                                    <li>Exploring how to <b>improve the CTC loyalty program</b> through GenAI </li>
                                    <li> Microsoft Team conducting workhops with core AI Platform leaders a (SVP Digital and Connected Retail, VP of Analytics and AI, VP of IT Digital) and CTC Business Executives to identify meanful business use cases for <b>additional/future GenAI solutions, including the future of retail search with semantic capability</b></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

                <ArchitectureDiagram />
                <TeamPage />


            </div>

        </div>
    )
}