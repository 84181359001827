import React from "react";
import {Context} from "../../context/menu";
import { geturlPrefix } from "@src/utils/functions";

interface IProps {

}

export const Header = (props: IProps) => {
    const {isClicked, setIsClicked} = React.useContext(Context);
    const onMenuClicked = () => {
        setIsClicked(!isClicked)
    }
    let urlPrefix = geturlPrefix();
    return (
        <div className="ms-ai-header">
            <div className="ms-ai-header-container ai-container">
                {/* ADD CLASS: ms-ai-open-nav-menu */}
                <div tabIndex={1} className="ms-ai-mob-harmburger" onClick={() => onMenuClicked()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="cross" d="M21 6H3" stroke="black" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path className="cross-one" d="M21 12H3" stroke="black" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path className="cross-two" d="M21 12H3" stroke="black" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path className="cross" d="M21 18H3" stroke="black" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </div>
                {/* <div className="ms-ai-logo">
                    <img src={urlPrefix + "assets/images/ms_logo.png"} alt=""/>
                </div> */}
                {/* <div className="ms-ai-nav-wrapper">
                    <ul>
                        <li><Link className="active" to={"/"}>Microsoft AI Use Case
                            Explorer</Link></li> */}
                {/* <li><a href="#">Why AI <span><svg width={9} height={8} viewBox="0 0 9 8"
                                                                          fill="none"
                                                                          xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_107_81)">
                              <path
                                  d="M8.41992 1.82422L8.77148 2.17578L4.8457 6.10156L0.919922 2.17578L1.27148 1.82422L4.8457 5.39844L8.41992 1.82422Z"
                                  fill="#262626"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_107_81">
                                <rect width={8} height={8} fill="white" transform="matrix(1 0 0 -1 0.919922 8)"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </span></a></li>
                                        <li><a href="#">Copilot Products<span><svg width={9} height={8}
                                                                                   viewBox="0 0 9 8" fill="none"
                                                                                   xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_107_81)">
                              <path
                                  d="M8.41992 1.82422L8.77148 2.17578L4.8457 6.10156L0.919922 2.17578L1.27148 1.82422L4.8457 5.39844L8.41992 1.82422Z"
                                  fill="#262626"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_107_81">
                                <rect width={8} height={8} fill="white" transform="matrix(1 0 0 -1 0.919922 8)"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </span></a></li>
                                        <li><a href="#">Solutions<span><svg width={9} height={8} viewBox="0 0 9 8"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_107_81)">
                              <path
                                  d="M8.41992 1.82422L8.77148 2.17578L4.8457 6.10156L0.919922 2.17578L1.27148 1.82422L4.8457 5.39844L8.41992 1.82422Z"
                                  fill="#262626"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_107_81">
                                <rect width={8} height={8} fill="white" transform="matrix(1 0 0 -1 0.919922 8)"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </span></a></li>
                                        <li><a href="#">Responsible AI<span><svg width={9} height={8} viewBox="0 0 9 8"
                                                                                 fill="none"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_107_81)">
                              <path
                                  d="M8.41992 1.82422L8.77148 2.17578L4.8457 6.10156L0.919922 2.17578L1.27148 1.82422L4.8457 5.39844L8.41992 1.82422Z"
                                  fill="#262626"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_107_81">
                                <rect width={8} height={8} fill="white" transform="matrix(1 0 0 -1 0.919922 8)"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </span></a></li>
                                        <li><a href="#">Resources<span><svg width={9} height={8} viewBox="0 0 9 8"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_107_81)">
                              <path
                                  d="M8.41992 1.82422L8.77148 2.17578L4.8457 6.10156L0.919922 2.17578L1.27148 1.82422L4.8457 5.39844L8.41992 1.82422Z"
                                  fill="#262626"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_107_81">
                                <rect width={8} height={8} fill="white" transform="matrix(1 0 0 -1 0.919922 8)"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </span></a></li> */}
                {/* </ul> */}
                {/* <div className="ms-all-microsoft-dd">
                                        <a href="#">All Microsoft<span><svg width={9} height={8} viewBox="0 0 9 8"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_107_81)">
                            <path
                                d="M8.41992 1.82422L8.77148 2.17578L4.8457 6.10156L0.919922 2.17578L1.27148 1.82422L4.8457 5.39844L8.41992 1.82422Z"
                                fill="#262626"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_107_81">
                              <rect width={8} height={8} fill="white" transform="matrix(1 0 0 -1 0.919922 8)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </span></a>
                                    </div> */}
                {/* </div> */}
            </div>
        </div>
    );
};
