import React from 'react';

interface IProps {
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
    onClick?: React.MouseEventHandler<any> | undefined;
    currentSlide?: number | undefined;
    slideCount?: number | undefined;
}


export const Left = (props: IProps) => {
    const {className, style, onClick} = props;

    return (
        <button type="button" data-role="none" className={className} aria-label="Prev"
                style={{...style, display: "block"}}
                onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="72"
                fill="none"
                viewBox="0 0 72 72"
            >
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="6"
                    d="M45 54L27 36l18-18"
                ></path>
            </svg>
        </button>
    );
}