interface IProps {
    data: any
    tabindex: number
}

export const ListView = (props: IProps) => {
    let {data, tabindex} = props;
    const items = data.items ? data.items : [];
    return (
        <div>
            {!data?.group_id && <div className={`${data.classname ? data.classname : "innerpage-sub-title-light"}`}>
                {data.title}
            </div>}
            <div className="ms-ai-services-wrapper ms-ai-list-view-container">
                {items.map((item: any, index: number) => {
                    //tabindex++;
                    return (
                        <div tabIndex={tabindex} className="ms-ai-services-block" key={"icon-text-" + index}>
                            <div className="ms-ai-services-item">
                                <div className="ms-ai-services-title">{item.content_data?.title ? item.content_data?.title : item.title}</div>
                            </div>
                            {/* <div className="ms-ai-services-progressbar">
                                <span className="ms-ai-bg-green ms-bg-green"/>
                            </div> */}
                        </div>
                    )
                })}

            </div>
        </div>
    )
}