import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import ArchitectureDiagram from "./Architechture";
import TeamPage from "./TeamPage";
// import ArchitectureDiagram from "./TomtomArchitechture";
// import TeamPage from "./TomtomTeamPage";
export const Softbank = () => {

    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }

    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">SoftBank's "Zero to One" Al moment: Full Al Customer Support Center powered by
AOAI and LLM-based adaptive workflow</div>
               
                <div className="innerpage-sub-title">Win summary & Customer Impact</div>
                <p>SoftBank is a Japanese multinational conglomerate specializing in technology, telecommunications, and investment, known for its
Vision Fund investments in various tech companies worldwide. Among the Group's holdings is SoftBank Corporation, Japan's third-largest wireless carrier which also delivers critical services in communication infrastructure, fintech, and energy to millions of users.
The Group's stated vision is that "we believe that wise applications of Al and breakthrough technologies will result in a more connected, empowered, and joyful world"</p>
                        <div className="mi-ai-stories-center-quote">
                            <div className="mi-ai-stories-center-quote-title">"I'm ready to spend big on generative Al, and I'm thinking about using Microsoft" or Google</div>
                            <div className="mi-ai-stories-center-quote-subtitle">Masayoshi Son, Chairman and CEO of SoftBank Group, September 2023</div>
                        </div>
                        <div>
                            <p>Since March 2023, SoftBank had been working to create a "Full Al Customer Support Center" solution, without success. Assembling a One Microsoft approach (ATU, STU, GPS, ISD, CSU, GPS IA and SPT, and Executives) to engage with Son and his executive leadership group, the team quickly diagnosed that the root issue with SoftBank's internal GenAl Call Center efforts lay in the solution approach of trying to solve for each of 10K+ support business processes with fixed workflow descriptions and scripts.
<b>Within two weeks, the Microsoft Team orchestrated by the Al Pod GBB built a proof of concept protoype that successfully utilized an adaptive Al design</b> with the LLM handling all function</p>
<p>The prototype proved a true "zero to one" moment for SoftBank's leadership, demonstratina the possibilities of Full Al Customer
Support bowered bv "an LLM-based autonomous thinkina svstem" utilizina SoftBank's extensive service data. The Full Al Customer
Support Center solution will beain rollina out in shases in Julv 2024. leveraaina Microsoft's Azure OpenAl Service to enhance customer satisfaction through reduced wait times and consistent responses. This advanced call center will optimize workflows,
improving productivity and customer experience.</p>
<p>
The Microsoft team's commitment to effective communication, its technical expertise, and contractual diligence played a significant role in fostering customer trust and securing the project. The successful execution emphasized the importance of teamwork, leadership, and building strong customer relationships. Moving forward, the insights and experiences gained will guide future efforts to deliver exceptional results via a strategic partnership between SoftBank and Microsoft Japan to drive digital transformation through Al adoption throughout the country, incuding plans to offer the Full Al Customer Support Center solution
to corporate clients.
</p>
                        </div>
                        <div className="mi-ai-stories-container">
                            <div className="mi-ai-stories-content">
                                <div className="mi-ai-stories-win-snapshot">
                                    <h2>Win Snapshot</h2>
                                    <div className="mi-ai-stories-win-content">
                                        <strong>Win Date:</strong> <p>February 14, 2024</p>
                                        <strong>Area:</strong><p>Japan</p>
                                        <strong>Country:</strong><p>Japan</p>
                                        <strong>Segment:</strong> <p>Strategic - Commercial</p>
                                        <strong>Industry:</strong> <p>Telecommunications and Media</p>
                                        <strong>Technical Pattern:</strong><p>
                                            <ul className="mi-ai-stories-list">
                                                <li>Chat with your data</li>
                                            </ul>
                                        </p>
                                        <strong>Azure Impact ($ Montly):</strong>
                                        <p>
                                            <ul className="mi-ai-stories-list">
                                                <li>AI/PTU ACR: $3M/month</li>
                                                <li>Data ACR : $83K/month</li>
                                                <li>App ACR $83K/month</li>
                                            </ul>
                                            <small>(service-in in 4 years)</small>
                                        </p>
                                        <strong>Opportunity ID:</strong><p>7-37W42TS412</p>
                                        <strong>Programs/Offers:</strong> <p>MACC/ECIF</p>
                                        <strong>Partner/ISV:</strong> <p>ISD</p>
                                        <strong>Compete:</strong> <p>Google/GCS</p>
                                    </div>
                                </div>
                                <div className="mi-ai-stories-main-content">
                                    <div className="mi-ai-stories-solution">
                                        <h2>Solution</h2>
                                        <p>The solution is <b>a human-like Full Al Customer Support Center autonomous thinking system:</b></p>
                                       
                                        <ul className="mi-ai-stories-list">
                                            <li>SoftBank leverages Azure OpenAI services to automate their call center operations. The LLM-based system efficiently handles over 10,000 tasks by analyzing customer inquiries and referencing necessary data sources for accurate responses. By utilizing Azure AI Search and RAG, it automates tasks like inquiries, contract details, and change information, reducing customer inquiry wait times and enhancing customer satisfaction.</li>
                                            <li>LLM-based flow design vs traditional decision-tree architecture approach addresses the cause of problems in designing automation that can lead to Artificial General Intelligence (AGI): Generative AI determines the best solution for each customer and support scenario</li>
                                            <li>Key lessons include the significance of thorough communication, learning from mistakes, and being able to define and establish a strong use case and enable phased future investments. This experience reaffirmed the importance of teamwork, leadership, and customer-centric approaches in driving success.</li>
                                        </ul>

                                        
                                    </div>
                                    <div className="mi-ai-stories-why-we-won">
                                        <h2>Why we won?</h2>
                                        <p>The Team were constantly competing with Google during the proposal phase, which included direct communication between SoftBank executives and Google CEO, Sundar Pichai. Microsoft won the business because:</p>
                                        <ul className="mi-ai-stories-list">
                                           <li>The Al solutions centered around Azure's AOAI (PTUs), as well as the <b>technical capabilities of Al Pod GBB, CSU, and ISD,</b> played a significant role in convincing the customer's management team that Microsoft was the best Solution and Al Partner</li>
                                            <li><b>Understanding SoftBank's business model,</b> the team solidified
SoftBank's foundation as Customer Zero and created a strategic offer that incorporates a collaborative external sales strategy, achieving differentiation. This was made possible through leadership in Industry Advisor and GPS.</li>
                                            <li>On the Corporate and Legal Risk side, Japanese law stipulates the
"confidentiality of communications" for all call center conversations; it was essential to <b>utilize AOAl within Japan,</b> and PTU offered the advantage of being able to specify the region, purchase the large-scale environment they required, and optimize the cost of using AOAl</li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-business-impact">
                                        <h2>Business Impact</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li>Realization of an AI Call Center that operates with <b>100% human-like efficiency</b></li>
                                            <li>Optimization of the annual operation of in-house call center, approximately $150M</li>
                                            <li>Extended coverage for Customer Care Center: humans operated 12 hours/day; <b>AI Chat operates 24 hours/day</b></li>
                                            <li><b>Improving customer satisfaction</b> by reducing customer wait times and standardizing responses while receiving optimal answers to their questions</li>
                                            <li>Long-term, an AI Call Center solution for SoftBank's corporate clients to adopt and deploy in their organizations</li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-repeatable-ip-pattern">
                                        <h2>Repeatable IP & Pattern</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li><a href="#">LLMCal|CenterSolution (github.com).</a></li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-expansion">
                                        <h2>Expansion</h2>
                                        <ul className="mi-ai-stories-list">
                                           <li>SoftBank will expand the scope of the Al call center's support from providing guidance on inquiries and confirming contract details to gradually expanding it to include contract modification procedures.</li>
                                           <li>SoftBank is also considering the following applications as the next projects for its Al Call Center:</li>
                                           <li>Complete automation of mobility such as automobiles</li>
                                           <li>Complete supply-demand matching using various data in retail and food service industries</li>
                                            <li>Understanding customer emotions and providing optimal responses in call centers</li>
                                            <li>Executing optimal investment strategies from trillions of simulations</li>
                                            <li>Personalized medicine through individual genetic analysis in the medical field</li>
                                            <li>SoftBank has formed a strategic partnership with Microsoft Japan to develop their call center solution as an offer to mutual corporate clients</li>

                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>

                    <ArchitectureDiagram />
                    <TeamPage/>
                    
                    
            </div>
                    
    </div>
    )
}