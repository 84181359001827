import React from 'react';
import '../../../assets/css/storiesarch.css';

const ArchitectureDiagram = () => {
    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }
    return (
        <div className="mi-ai-stories-arch-container">
            <section className="mi-ai-stories-arch-section">
                <div className="mi-ai-stories-arch-content">
                <h1 className="mi-ai-stories-arch-title">Architecture Diagram</h1>
                <div className='mi-ai-stories-arch-image-container-santender'><img src={urlPrefix + 'assets/images/stories/santender-architecture.jpg'} alt="TomTom Digital Cockpit Architecture" className="mi-ai-stories-arch-image" /></div>
                </div>
            </section>
        </div>
    );
};

export default ArchitectureDiagram;
