export const geturlPrefix = () => {
    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000") || (window as any).location.origin.includes("localhost:3001")) {
        urlPrefix = '/assets/user/';
    }
    return urlPrefix;
}

export const getApiUrl = () => {
    let apiUrl = '';
    if ((window as any).location.origin.includes("localhost:3000") || (window as any).location.origin.includes("localhost:3001")) {
        apiUrl = 'http://localhost:3000';
    } else if((window as any).location.origin.includes("localhost:44320")) {
        apiUrl = 'http://localhost:3000';
    }
    return apiUrl;
}

export const generateRandomString = (length = 4) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
};

export const removeKeyIfExists = (obj: { [key: string]: any }, key: string): { [key: string]: any } => {
    if (obj.hasOwnProperty(key)) {
      const newObj = { ...obj };
      delete newObj[key];
      return newObj;
    }
    return obj;
  };