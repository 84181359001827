interface IProps {
    data: any
    tabindex: number
}

export const ResourceLinks = (props: IProps) => {
    let {data, tabindex} = props;
    const items = data.items ? data.items : [];
    return (
        <div className="ms-ai-download-wrapper">
            <div className="ms-ai-download-container">
                <div className="ms-ai-inner-page-sub-headeing">{data.title}</div>
                {items.map((item: any, itemindex: number) => {
                    //tabindex++;
                    return (
                        <div tabIndex={tabindex} key={"resource-item-" + itemindex} className="ms-ai-download-item">
                            <a href={item.link && item.link !== "#" ? item.link : undefined} target="_blank" rel="noreferrer">{item.title}<span><svg width="11"
                                                                                                        height="16"
                                                                                                        viewBox="0 0 11 16"
                                                                                                        fill="none"
                                                                                                        xmlns="http://www.w3.org/2000/svg"><path
                                d="M0.5 9C0.5 8.86458 0.549479 8.7474 0.648438 8.64844C0.747396 8.54948 0.864583 8.5 1 8.5C1.13542 8.5 1.2526 8.54948 1.35156 8.64844L5 12.2891V0.5C5 0.364583 5.04948 0.247396 5.14844 0.148438C5.2474 0.0494795 5.36458 0 5.5 0C5.63542 0 5.7526 0.0494795 5.85156 0.148438C5.95052 0.247396 6 0.364583 6 0.5V12.2891L9.64844 8.64844C9.7474 8.54948 9.86458 8.5 10 8.5C10.1354 8.5 10.2526 8.54948 10.3516 8.64844C10.4505 8.7474 10.5 8.86458 10.5 9C10.5 9.13542 10.4505 9.2526 10.3516 9.35156L5.875 13.8281C5.81771 13.8854 5.76172 13.9284 5.70703 13.957C5.65234 13.9857 5.58333 14 5.5 14C5.41667 14 5.34766 13.9857 5.29297 13.957C5.23828 13.9284 5.18229 13.8854 5.125 13.8281L0.648438 9.35156C0.549479 9.2526 0.5 9.13542 0.5 9ZM0.5 15H10.5C10.6354 15 10.7526 15.0495 10.8516 15.1484C10.9505 15.2474 11 15.3646 11 15.5C11 15.6354 10.9505 15.7526 10.8516 15.8516C10.7526 15.9505 10.6354 16 10.5 16H0.5C0.364583 16 0.247396 15.9505 0.148438 15.8516C0.0494792 15.7526 0 15.6354 0 15.5C0 15.3646 0.0494792 15.2474 0.148438 15.1484C0.247396 15.0495 0.364583 15 0.5 15Z"
                                fill="#0078D4"></path></svg></span></a>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}