import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import ArchitectureDiagram from "./Architechture";
import TeamPage from "./TeamPage";
// import ArchitectureDiagram from "./TomtomArchitechture";
// import TeamPage from "./TomtomTeamPage";
export const Schindler = () => {

    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }

    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">Increasing Global Call Center efficiency by 30% using Azure Al driven Voice
                    Assistant</div>

                <div className="innerpage-sub-title">Win summary & Customer Impact</div>
                <p>
                    Schindler Group is a Swiss multinational company founded in 1874. They manufacture elevators, escalators, and moving walkways worldwide, serving residential, commercial, and high-rise buildings. With over 66,000 employees across 140 countries, they engineer vertical mobility for more comfortable, inclusive, and sustainable urban spaces.
                </p>
                <p><b>Business Challenge:</b> Schindler's customer service challenges encompass a complex, multi-tiered complaint process that is both time-consuming and frustrating for customers. The accuracy of data collection is compromised when equipment details and personal information are gathered over the phone, leading to service delays. Additionally, a high volume of calls often exceeds the capacity of customer service agents, causing extended wait times and necessitating follow-up callbacks.</p>
                <p>
                    <strong>Business Impact:</strong>
                    <ul className="mi-ai-stories-list">
                        <li>66% time reduction per interaction</li>
                        <li>30% of routine customer calls covered</li>
                        <li>CHF 2.7 mio yearly cost savings</li>
                        <li>CSAT and Call Backlog Improvements</li>
                        <li>Improved Job Satisfaction for Customer Care</li>
                    </ul>
                </p>
                <div className="mi-ai-stories-container">
                    <div className="mi-ai-stories-content">
                        <div className="mi-ai-stories-win-snapshot">
                            <h2>Win Snapshot</h2>
                            <div className="mi-ai-stories-win-content">
                                <strong>Win Date:</strong> <p>June 2024</p>
                                <strong>Country:</strong><p>Switzerland</p>
                                <strong>Area:</strong><p>Switzerland</p>
                                <strong>Segment:</strong> <p>Enterprise commercial</p>
                                <strong>Industry:</strong> <p>Manufacturing</p>
                                <strong>Technical Pattern:</strong><p>
                                    <ul className="mi-ai-stories-list">
                                        <li>Chat with your data</li>
                                        <li>Knowledge mining</li>
                                        <li>Document processing and summarization</li>
                                        <li>Build your own AI Assistant</li>
                                        <li>Content generation</li>
                                        <li>Personalized recommendations</li>
                                        <li>Forecasting</li>
                                    </ul>
                                </p>
                                <strong>Azure Impact:</strong>
                                <p>
                                    <ul className="mi-ai-stories-list">
                                        <li>AI: $18K Monthly</li>
                                        <li>Apps: $7K Monthly</li>
                                        <li>Data: $12K Monthly</li>
                                    </ul>
                                </p>
                                <strong>Global roll-out::</strong><p>$100k/monthly</p>
                                <strong>Opportunity ID:</strong> <p>7-36ONIYNAWE</p>
                                <strong>Programs/Offers:</strong> <p>PTU Offer</p>
                                <strong>Partner/ISV:</strong> <p>LTI Mindtree</p>
                            </div>
                        </div>
                        <div className="mi-ai-stories-main-content">
                            <div className="mi-ai-stories-solution">
                                <h2>Solution</h2>
                                <p>Schindler introduces the Schindler Al Voice Agent, an innovative solution aimed at transforming customer interactions by capturing essential information and recording callbacks, thereby enhancing agent focus on critical customer conversations.</p>
                                <p>
                                    <strong>Azure Services:</strong>
                                    <ul className="mi-ai-stories-list">
                                        <li><b>Azure OpenAI</b></li>
                                        <li><b>Azure Communication Services</b></li>
                                        <li><b>Application Insights</b></li>
                                        <li><b>Cosmos DB</b></li>
                                        <li><b>Azure App Service: Represented as</b> "call automation app" in the architecture diagram used to orchestrate the requests and to communicate with SAP See the AI Voice Agent in action in the below Video:
                                            <div className='mi-ai-stories-arch-image-container-santender'><img src={urlPrefix + 'assets/images/stories/schindler-1.jpg'} alt="Schindler" className="mi-ai-stories-arch-image" /></div>
                                            
                                        </li>
                                    </ul>
                                </p>
                                <p><a href="#">Video link</a></p>
                                <p>The Voice Agent is capable of <b>managing nearly 30% of routine customer calls, understanding and responding in over 70 languages</b>, and even stepping in for emergency calls to assess situations, thus allowing human agents to concentrate on real emergencies.</p>
                            </div>
                            <div className="mi-ai-stories-why-we-won">
                                <h2>Why we won?</h2>
                                <ul className="mi-ai-stories-list">
                                    <li><b>Business Led Strategic Use Case.</b> Engaging GBB and Engineering directly with the customer to envision the opportunity and co create shared vision with measurable business impact continues to underpin the investment in the project including PTU planning.</li>
                                    <li><b>One Microsoft Approach.</b> Engaging key Microsoft resources to demonstrate Microsoft's capabilities. Strong involvement from the local teams in the PoC to success enabling Production enablement: ATU, STU, CSU, GBB, Engineering</li>
                                    <li><b>Executive Sponsorship.</b> Leveraging the strong partnership between Schindler and Microsoft on executive level, helped accelerating the initiative and bringing in key resources</li>
                                </ul>
                            </div>
                            <div className="mi-ai-stories-business-impact">
                                <h2>Business Impact</h2>
                                <ul className="mi-ai-stories-list">
                                    <li><b>Enhanced Customer Experience:</b> Customers enjoy a streamlined complaint process, with reduced waiting times and a choice between AI and human interaction.</li>
                                    <li><b>Improved Service Efficiency:</b> With generated request summaries, service technicians receive clear, actionable information, allowing for quicker dispatch and problem resolution.</li>
                                    <li><b>Customer Assurance:</b> After each interaction, customers are reassured that their complaint has been officially logged and that help is on the way, fostering trust and satisfaction with Schindler</li>
                                </ul>
                            </div>
                            <div className="mi-ai-stories-repeatable-ip-pattern">
                                <h2>Repeatable IP & Pattern</h2>
                                <strong>Existing VBDs used:</strong>
                                <ul className="mi-ai-stories-list">
                                    <li>Architecture Design and Review Session for Innovating Apps with Azure OpenAl</li>
                                    <li>Production Deployment Oversight for Building and Modernizing Al Apps for Developers</li>
                                </ul>
                                <strong>Existing GitHub repos used:</strong>
                                <ul className="mi-ai-stories-list">
                                    <li><a target="_blank" href="https://github.com/Azure-Samples/communication-services-Al-customer-service-sample">https://github.com/Azure-Samples/communication-services-Al-customer-service-sample</a></li>
                                    <li><a target="_blank" href="https://github.com/Azure/azure-openai-benchmark">https://github.com/Azure/azure-openai-benchmark</a></li>
                                    <li><a target="_blank" href="#">Prompt Shields in Azure Al Content Safety - Azure Al services</a></li>
                                </ul>
                                <strong>Documentation and deck used:</strong>
                                <ul className="mi-ai-stories-list">
                                    <li><a target="_blank" href="#">Deploying AOAl solutions in prod</a></li>
                                    <li><a target="_blank" href="#">Connect Azure Communication Services to Azure AI services - An Azure Communication Services how to document</a></li>
                                </ul>
                            </div>
                            <div className="mi-ai-stories-expansion">
                                <h2>Expansion</h2>
                                <ul className="mi-ai-stories-list">
                                    <li>Initial Target markets: Switzerland, Germany, India and France</li>
                                    <li>Continous global roll out in Europe, Americas and Asia</li>
                                    <li>Beyond voice interactions, Schindler AI processes millions of customer emails, classifies them, extracts key information, and ensures contextually accurate responses by accessing recordings of customer calls.</li>
                                    <li>It will also analyse sentiment, sort out calls, and predict customer churn, enabling sales teams to act proactively</li>
                                    <li>Categorize E-Mails received and respond with contextualized information <br/>Status: Currently they are able to categorize with a 90% success rate. Response capabilities still need to be built</li>
                                    <li>Ability to look at historical voice logs and categorize it to enhance internal knowledge regarding the types of problems customers are calling about and build the internal knowledge base accordingly</li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

                <ArchitectureDiagram />
                <TeamPage />


            </div>

        </div>
    )
}