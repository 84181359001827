import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import ArchitectureDiagram from "./Architechture";
import TeamPage from "./TeamPage";
// import ArchitectureDiagram from "./TomtomArchitechture";
// import TeamPage from "./TomtomTeamPage";
export const Swiss = () => {

    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }

    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">Swiss Re's Life Guide Platform leverages Advanced Analytics and Azure OpenAI PTU to empower Insurers with Generative AI Underwriting Assistant</div>

                <div className="innerpage-sub-title">Win summary & Customer Impact</div>
                <p>The Swiss Re Group is a leading wholesale provider of Reinsurance, Insurance and other Insurance-based forms of risk transfer. Dealing directly and working through brokers, their global client base consists of insurance companies, mid-to-large-sized corporations, and public sector clients.</p>

                <p>Life reinsurance accounts for a substantial portion of Swiss Re's revenue and plays a crucial role in its overall strategy and financial performance. Swiss Re’s growth strategy includes scaling out their life solutions business and enhancing their capabilities with Advanced Analytics & AI, particularly to offer faster and more accurate risk assessments for policyholders. However, Swiss Re was reluctant to use Gen AI on the Life Insurance business line as they were concerned that compliance and legal would be key challenges in this domain.</p>

                <p>The Microsoft Team took a holistic approach to addressing Swiss Re’s concerns, employing a “Teach, Tailor, Take Control” methodology to build trust with the company and its core stakeholders. The Team then helped Swiss Re-establish a Center of Excellence (CoE) for Data & AI and worked closely with the CoE to deploy a trusted framework upon which to design and deploy GenAI solutions. With this foundation in place, we collaborated to build a GenAI use case for life insurance underwriting, complete with defined business impact and complexity of implementation.</p>

                <p>Swiss Re’s Life Guide is the world’s #1 digital life insurance underwriting tool for automating life risk evaluation, serving 12,000 customers around the globe every year while answering 23 million questions. However, even with this tool, underwriters invest significant time to find and review information using standard search tools to identify relevant sources and facts. The Swiss Re and Microsoft Generative AI-powered solution is an underwriting assistant called Life Guide Scout: now within seconds, the underwriter can enter a professional, natural-language query and receive an AI-generated answer that includes the source of information. A pilot program for Life Guide Scout launched in April 2024, and a wider roll-out is planned throughout the year.</p>

                <div className="mi-ai-stories-center-quote">
                    <div className="mi-ai-stories-center-quote-title">“We are excited to bring Generative AI to our clients . . .  to improve their underwriting process . . . in an even more efficient and user-friendly way.”</div>
                    <div className="mi-ai-stories-center-quote-subtitle">Julien Descombes, Swiss Re Chief Underwriting Officer</div>
                </div>
            </div>
            <div className="mi-ai-stories-container">
                <div className="mi-ai-stories-content">
                    <div className="mi-ai-stories-win-snapshot">
                        <h2>Win Snapshot</h2>
                        <div className="mi-ai-stories-win-content">
                            <strong>Go-Live Date:</strong> <p>April 2024</p>
                            <strong>Area:</strong><p>Switzerland</p>
                            <strong>Country:</strong><p>Switzerland</p>
                            <strong>Segment:</strong> <p>Strategic </p>
                            <strong>Industry:</strong> <p>Financial Services/Insurance</p>
                            <strong>Technical Pattern:</strong><p>
                                <ul className="mi-ai-stories-list">
                                    <li>Build your own AI Assistant</li>
                                </ul>
                            </p>
                            <strong>Azure Impact (USD/Month):</strong>
                            <p>
                                <ul className="mi-ai-stories-list">
                                    <li>AOAI ACR: $62,4K/month </li>
                                    <li>PTUs: 200</li>
                                    <li>AI ACR: $4,2K/month</li>
                                    <li>Data ACR: $9,9K/month<br/>(increase from $7K/month, <br/>so $2,9K net new per month)</li>
                                    <li>App ACR: $2K/month</li>
                                </ul>

                            </p>
                            <strong>Opportunity ID:</strong><p>7-37OOC5EBKT</p>
                            <strong>Programs/Offers:</strong> <p>Azure Open AI Go Big Plan</p>
                            <strong>Partner/ISV:</strong> <p>D-One</p>
                            <strong>Compete:</strong> <p>GCP/ Gemini, AWS</p>
                        </div>
                    </div>
                    <div className="mi-ai-stories-main-content">
                        <div className="mi-ai-stories-solution">
                            <h2>Solution</h2>
                            <p>Life Guide Scout is a GenAI-powered underwriting assistant that aims to help increase the efficiency and quality of life insurance underwriting by generating swift answers compiled from curated expert knowledge in response to questions asked by the underwriter in natural language.  Azure components include:</p>

                            <ul className="mi-ai-stories-list">
                                <li>Azure Open AI</li>
                                <li>Azure Synapse Analytics</li>
                                <li>Azure Cosmos DB</li>
                                <li>Azure API Management</li>
                                <li>Azure App Services</li>

                            </ul>
                            <p>To guarantee the quality of services expected by users of an augmented version of Life Guide, Microsoft provided implementation guidance to benchmark various models and settled on GPT-4-32K, selected AI Search as the knowledge base to store 500,000 clinical codes, and baked in LLMOps using prompt flow; to further avoid reputational risks, a PTU model was chosen for low latency and high available throughput.</p>

                            <p>The solution uses Cosmos DB as an operational state store with the first Life Guide Scout solution using between $500-$700/month in Cosmos ACR. The Cosmos DB state store is established as a blueprint with the CoE team for Gen AI solutions and is set to scale as the Swiss Re team onboards more Gen AI use cases.</p>

                            <p>Swiss Re has committed to Reserved Instances for Synapse for $359K for 3 years to synthesize and transform data from disparate sources for upstream Gen AI use cases where all the risks are stored and used for Life Guide (Structured Data).</p>

                        </div>
                        <div className="mi-ai-stories-why-we-won">
                            <h2>Why we won?</h2>
                            <p>Helped Swiss Re to establish a Center of Excellence (CoE) for Data & GenAI and worked together to create a compliant architecture that caters to all use cases (Unstructured and Structured data).</p>

                            <p>Coordinated collaboration between Swiss Re Legal & Compliance and Microsoft Legal to address the regulatory aspects of the insurance industry; built trust in approach with education on Microsoft Responsible AI Standard and completeness of story (especially compared to Google/GCP).</p>

                            <p>Employed a Microsoft One Team approach, with close collaboration between ATU, STU, GBB (Analytics & AI), and CSU (VBD’s) to bring the right expertise to the client at the right time.</p>

                            <p>Followed a “Teach, Tailor, Take Control” methodology to build credibility with the company and its core stakeholders.</p>

                            <p>Impressed Swiss Re with connected ecosystem of Microsoft tools and the consistent landscape from which to pull data for GenAI solution.</p>

                            <p>Demonstrated Microsoft’s ability to develop and host enterprise-ready GenAI solutions now, while Palantir (on AWS) still has difficulty showing this.</p>

                            <p>Utilized the Azure Open AI Go Big Plan, which gave us Post-sales ECIF. We used that ECIF to fund technical consulting activities of our CSAs to guide the Life Guide Scout team at Swiss Re adopt best data science lifecycle management practices on the sensitive domain and also make the application layer robust and scalable to variable demands on the system. Swiss Re appreciates this co-creation and the CoE can now act as an internal consultant scaling other use cases, following our best practices.</p>

                        </div>
                        <div className="mi-ai-stories-business-impact">
                            <h2>Business Impact</h2>
                            <ul className="mi-ai-stories-list">
                                <p>Faster, more precise underwriting decisions: the Life Guide data foundation with advanced AI analytics capabilities unlocks deeper insights for improved and quicker human decision making.</p>

                                <p>Compliance and Legal risk mitigation: compliant platform allowing Swiss Re to share their risk insights with their underwriting clients.</p>

                                <p>Reduced Life insurance portfolio risk: because underwriting clients have quicker and deeper access to data to better understand current and future risks, they can make more informed decisions and build strong, sustainable portfolios.</p>

                                <p>Anticipated Swiss Re top-line growth: by increasing the interaction with underwriters, the company can gain a competitive edge in AI solutions.</p>

                                <p>Reduced Insurance quote turnaround times: The rate-quote-bind journey of a life insurance portfolio involves a lot of manual interventions to extract data and apply actuarial risk analysis; by automating both of these and having an expert in the loop, Swiss Re reduced turnaround times and therefore increased the potential to handle larger volumes in the future.</p>

                            </ul>

                        </div>
                        <div className="mi-ai-stories-repeatable-ip-pattern">
                            <h2>Repeatable IP & Pattern</h2>
                            <p>Existing IP Leveraged by Team</p>
                            <ul className="mi-ai-stories-list">
                                <li>Microsoft Responsible AI Standard, v2 (external link)</li>
                                <li>Microsoft Responsible AI Impact Assessment Template (external link)</li>
                                <li>Guidelines for Human-AI Interaction (HAX) (external link)</li>
                                <li>Transparency Note for Azure OpenAI Service (external link)</li>

                                <li>New IP Created by Team</li>
                                <li>AOAI Privacy and Security Deep Dive (internal link)</li>
                                <li>Generative AI Center of Excellence Guidance (internal link)</li>
                                <li>GenAI Compete Deck (internal link)</li>
                                <li>Load Balancing for OpenAI Endpoints and Azure API Management (GitHub, Microsoft Community Hub)</li>
                                <li>Azure OpenAI Endpoints Traffic Monitoring and Processing (GitHub)</li>
                            </ul>
                        </div>
                        <div className="mi-ai-stories-expansion">
                            <h2>Expansion</h2>
                            <p>The pilot launch is only in English language, with a wider rollout coming later in 2024.</p>

                            <p>Swiss Re is now building use cases by leveraging structured and unstructured data and will leverage this architecture to deploy nine additional use cases.</p>

                            <p>Following the success of Life Guide Scout, Swiss Re is exploring additional Gen AI use cases. One use case focuses on leveraging Gen AI to find and highlight differences in insurance policies, as they evolve with addendums over each renewal cycle. This use case is set to be a productivity gain for both the underwriters and legal teams, allowing them to focus on more creative tasks of formulating the risks of exclusions or inclusions in the policies that Gen AI identified.</p>

                            <p>As a side effect of funding CSA consulting hours through Go Big, our partner on the ground, D-ONE, working with Swiss Re, learned to leverage Red teaming frameworks for compliant and ethical Gen AI and is now doing roadshows in Switzerland to spread the knowledge within the FSI industry.</p>

                        </div>

                    </div>
                </div>
            </div>

            <ArchitectureDiagram />
            <TeamPage />


        </div>

    )
}