import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import ArchitectureDiagram from "./Architechture";
import TeamPage from "./TeamPage";
// import ArchitectureDiagram from "./TomtomArchitechture";
// import TeamPage from "./TomtomTeamPage";
export const Lg = () => {

    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }

    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">CHATDA: A Generative AI-Based Big Data Analysis Solution Utilizing Azure OpenAI for Effective Customer Needs Identification</div>

                <div className="innerpage-sub-title">Win summary & Customer Impact</div>
                <p>LG Electronics strives to provide customers with a better life based on the ‘Life’s Good’ brand philosophy. It is comprised of the business divisions of H&A (Home Appliance & Air Solution), HE (Home Entertainment), VS (Vehicle component Solutions), and BS (Business Solutions). It is a global leader that leads technological innovation in various fields such as home appliances, IT, automobile parts, and signage, conducting business in over 130 business sites around the world.</p>

                <p>By introducing CHATDA (Chat based Data Analytics), we are solving the bottleneck in the use of big data, and through this, we are bringing innovation to the data work required for planning and development of products and services. By utilizing Azure OpenAI, it is possible to analyze consumer behavior and efficiently understand their needs while maintaining the safety and security of data. Through this, LG Electronics' H&A Business Division is focusing on increasing the value of home appliances and providing better experiences to consumers, and is carrying out its recently announced vision of becoming a smart home solution company.</p>

                <div className="mi-ai-stories-center-quote">
                    <div className="mi-ai-stories-center-quote-title">“ChatGPT broke away from the perception that artificial intelligence is expensive and difficult and showed that anyone can get what they want through conversation with artificial intelligence, and was judged to have made the popularization of the technology a reality. In particular, we paid attention to the fact that ChatGPT can understand natural language and create appropriate software code.” -Woo Jeong-hoon, Managing Director: H&A Business Division </div>
                </div>
            </div>
            <div className="mi-ai-stories-container">
                <div className="mi-ai-stories-content">
                    <div className="mi-ai-stories-win-snapshot">
                        <h2>Win Snapshot</h2>
                        <div className="mi-ai-stories-win-content">
                            <strong>Win Date:</strong> <p>April 30, 2024</p>
                            <strong>Segment:</strong> <p>S500</p>
                            <strong>Industry:</strong> <p>Manufacturing (Home & Appliance)</p>
                            <strong>Technical Pattern:</strong><p>
                                <ul className="mi-ai-stories-list">
                                    <li>Chat with your own data </li>
                                    <li>Knowledge mining</li>
                                    <li>Build your own AI Assistant</li>
                                    <li>Code generation and documentation</li>
                                </ul>
                            </p>
                            <strong>Azure Impact:</strong>
                            <p>
                                <ul className="mi-ai-stories-list">
                                    <li>AOAI ACR $40K/Month</li>
                                    <li>App ACR $5K/Month</li>
                                    <li>Data ACR $10K/Month</li>
                                    <li>Analytics ACR $7K/Month</li>
                                    <li>Infra ACR $6K/Month</li>
                                </ul>

                            </p>
                            <strong>Opportunity ID:</strong><p>7-364A53TWLX<br />7-3A2KIVSOOI</p>
                            <strong>Programs/Offers:</strong> <p>Azure Innovate </p>
                            <strong>Partner/ISV:</strong> <p>IN4U</p>
                            <strong>Compete:</strong> <p> AWS</p>
                        </div>
                    </div>
                    <div className="mi-ai-stories-main-content">
                        <div className="mi-ai-stories-solution">
                            <h2>Solution</h2>
                            <p>The CHATDA solution is a generative AI-based big data analysis solution that utilizes Azure OpenAI to effectively identify customer needs. This is the first case of LG Electronics' AI-based data analysis platform on Full Azure, including MS AI solution and Fabric, a data solution, and is expanding into an exemplary case within LG Electronics.</p>
                            <ul className="mi-ai-stories-list">
                                <li>The priority target for LG Electronics Find is home appliance data accumulated through Internet of Things (IoT) technology. Based on data on users' usage habits for each home appliance, such as refrigerators and washing machines, it uses Azure Open AI's GPT-based chatbot to convert orders in natural language into code. The analysis results produced by this code are also converted into natural language. MS Fabric was applied to the database using SQL pools.</li>
                                <li>Using Azure AI and MS Fabric as Database with SQL pools, Data handling time has become incomparably faster. Data analysis work, which previously took 3 to 4 days or up to a week, can be answered within 2 to 3 minutes by entering the desired data type into the CHATDA input window, and then reprocessing it and extracting it in a different form. Even if you repeat the submission process, it only takes a few minutes for the MS Fabric-based database. In fact, in about 20 to 30 minutes, developers were able to look at data from various aspects, and in just an hour, they were able to have confidence in the data.</li>
                                <li>Complex data processing screens and applications for checking results were integrated into one CHATDA application window to provide user convenience and intuitive service. By expanding the service to the Teams channel, access convenience for internal users was also improved.</li>
                                <li>Users utilize the service and analyze and share the results through the CHATDA Teams window in the internal technical data integrated with MS Fabric. Through this, users can easily obtain and utilize analysis results that can not only generate LLM-based SQL code but also provide analysis insights on the desired data. This technology is serviced as a web app linked to a container registry, and AKS is being applied.</li>
                            </ul>
                        </div>
                        <div className="mi-ai-stories-why-we-won">
                            <h2>Why we won?</h2>
                            <ul className="mi-ai-stories-list">
                                <li>LG Electronics' pursuit of opportunities to introduce generative AI in the home appliance business dates back to the commercialization of Open AI's 'Chat GPT'. When ChatGPT, launched in late November 2022 and showed the marketability of AI-based chatbots, LG Electronics contacted Microsoft, which had invested in Open AI, and sought collaboration. It was about a month after the launch of ChatGPT. Commissioner Woo said, “We started the proof-of-concept (POC) of the AI-based data solution created in February last year and confirmed the results in May.” He added, “After investing and building solutions, we launched Findda in January of this year.” This means customer satisfaction. This is the result of need and MS's technology quickly leading the market.</li>
                                <li>From the business planning stage, a user-friendly data processing environment (MS Fabric) and MS AOAI-based LLM technology allow for integrated business service planning/performance that can dramatically improve customers' analysis requirements, a requirement that can overwhelm competitors.</li>
                            </ul>
                        </div>
                        <div className="mi-ai-stories-business-impact">
                            <h2>Business Impact</h2>
                            <ul className="mi-ai-stories-list">
                                <li>Last January, LG Electronics introduced ‘CHATDA’, an AI-based big data analysis solution generated by the H&A Business Division, a home appliance business division. By using Microsoft's platform, Azure, the time it took to derive in-house data was shortened from 3 to 5 days to 20 to 30 minutes. This is the result of OpenAI’s collaboration with Microsoft about a month after launching the ‘ChatGPT’ service.</li>
                                <li>“Users can retrieve the data they want through the chatbot without any coding knowledge,” observed the CHATDA senior developer. “GPT is a method of transmitting code rather than the data itself, so there is no risk of data leakage.”</li>
                            </ul>
                            <p>
                                From Dana-Faber perspective, It is too early to determine if GPT4DFCI will improve patient outcomes, staff well-being, equity in clinical care and research, or cost-effectiveness. However, by allowing their workforce to test LLMs in compliance with policies and laws, and in a fully auditable manner, DFCI aims to manage risks while advancing their discovery and clinical missions to better serve patients.
                            </p>
                        </div>
                        <div className="mi-ai-stories-repeatable-ip-pattern">
                            <h2>Repeatable IP & Pattern</h2>
                            <ul className="mi-ai-stories-list">
                                <li>CHATDA's solution base will be expanded to the AICC area of customer response within LG Electronics, and it will continue to expand into areas such as the LGU+ AI-based customer data code generation area within affiliates and LG Ensol's AI-based battery analysis, through which business users can expand easy data accessibility and MS AOAI-based AI Agent common analysis service.</li>
                                <li>Base code was provided by our partner IN4U, and the final enhancements and added features are the customer's assets, so IP is proprietary.</li>
                            </ul>
                        </div>
                        <div className="mi-ai-stories-expansion">
                            <h2>Expansion</h2>
                            <ul className="mi-ai-stories-list">
                                <li>We are also pursuing expanded application of CHATDA per LG Electronics plans to reflect data received through voice of customer (VOC), such as consumer requests, into the data analysis category within this year. Commissioner Woo said, “Improving customer value and protecting personal information are our top priorities, and we plan to improve and apply data solutions. This is an expansion of AI analysis services from the internal B2B area to the B2C service area.</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <ArchitectureDiagram />
            <TeamPage />


        </div>

    )
}