import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import ArchitectureDiagram from "./TomtomArchitechture";
import TeamPage from "./TomtomTeamPage";
export const Tomtom = () => {


    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">TomTom Enhances Digital Cockpit with Al-Powered Innovations Using Azure OpenAl PTUs and Fabric</div>
                <div className="innerpage-sub-title">Win summary & Customer Impact</div>
                <p>
                    Tom Tom, a leading provider of maps, navigation, and digital cockpit solutions. TomTom is redefining what it means to map the world, creating a trusted foundation for innovators, automakers, developers, businesses big and small, to build tomorrow's products. TomTom and Microsoft started working together in 2017, with TomTom powering Azure Maps location services and the companies later expanding their partnership to mapping data and services for Microsoft's first-party maps. As a natural evolution of their relationship, our companies are now collaborating on automotive solutions, driving innovation for generative Al-powered automotive solutions, and developing an in-vehicle digital cockpit and infotainment solution with cloud analytics. Tom Tom partners with Microsoft to make its mission to 'inspire innovation with the world's most useful map' come to life. TomTom and Microsoft started their partnership in 2017 and we have been working on innovation ever since. The GenAl revolution has resulted in a set of market-making opportunities:
                </p>
                <p>
                    <ol className="ms-ai-ol-list">
                        <li>
                            TomTom fully embraces <b>Azure OpenAl technology via PTU</b> commitment for Al powered automotive solutions and developing in-vehicle digital cockpit and infotainment solutions with cloud analytics with Microsoft Fabric.
                        </li>
                        <li>
                            TomTom starts moving to <b>Azure Marketplace</b> with solutions as Traffic Stats, Junction Analytics
                        </li>
                        <li>
                            Tom Tom & Microsoft develop <b>joint Go-To-Market</b> at CES, Tom Tom joins forces with Microsoft to bring Generative Al into the vehicle. Automobile manufacturers can customize it to the look and feel of their brand, cutting costs by up to 80% with a more efficient, sustainable approach.
                        </li>
                    </ol>
                </p>
                <p>
                        Since its debut in December 2022, ChatGPT has highlighted the transformative potential of Generative Al. In less than a year's investment in GenAl innovation, <b>TomTom has introduced to the world a ChatGPT location plugin, an Al assistant for in-car digital cockpits, and has launched several internal GenAl applications that simplify mapmaking and location technology development.</b> Tom Tom achieved these without substantial additional investments or a large influx of new hires. This experience provided valuable insights and helped crystalize our strategy. In this engineering blog, TomTom is pulling back the curtain on their generative Al adventure.
                </p>

                        <div className="mi-ai-stories-container">
                            <div className="mi-ai-stories-content">
                                <div className="mi-ai-stories-win-snapshot">
                                    <h2>Win Snapshot</h2>
                                    <div className="mi-ai-stories-win-content">
                                        <strong>Win Date:</strong> <p> March 6, 2024</p>
                                        <strong>Segment:</strong> <p>Strategic Commercial</p>
                                        <strong>Technical Pattern:</strong> <p>Build your own AI assistant</p>
                                        <strong>Azure Impact:</strong><p>
                                            <ul className="mi-ai-stories-list">
                                                <li>PTU: 50</li>
                                                <li>AOU AC: $16/t1/month</li>
                                                <li>App Services AC: $1 G1K/month</li>
                                                <li>Database ACR: $291-d/month</li>
                                                <li>Analytics ACR: $411-t/month</li>
                                            </ul>
                                        </p>

                                        <strong>Opportunity ID:</strong> <p>7-3BHK6P2AK</p>
                                        <strong>Offers/Program Used:</strong> <p>VBD/designated engineering</p>
                                        <strong>Partners(s)/ISV:</strong> <p>N/A</p>
                                        <strong>Compete:</strong><p>AWS</p>
                                    </div>
                                </div>
                                <div className="mi-ai-stories-main-content">
                                    <div className="mi-ai-stories-solution">
                                        <h2>Solution</h2>
                                        <p>TomTom's Digital Cockpit, an immersive in-car infotainment system is leveraging Microsoft's advancements in AI. In this Win we are extending the solution to use PTUs and Fabric.</p>
                                        <p>TomTom has developed a fully integrated AI-powered conversational automotive assistant that enables more sophisticated voice interaction with infotainment, location search, and vehicle command systems. Drivers can converse naturally with their vehicle and ask the AI-powered assistant to navigate to a certain location, find specific stops along their route, and vocally control onboard systems to, for instance, turn up the temperature, open windows, or change radio stations. All with a single interaction.</p>
                                        <ul className="mi-ai-stories-list">
                                            <li>TomTom Develops an advanced AI-Powered Voice Assistant for drivers based on Microsoft Azure OpenAI Service.</li>
                                            <li>The solution is built into TomTom’s Digital Cockpit and can be integrated into other automotive infotainment systems. Automobile manufacturers can customize it to the look and feel of their brand, cutting costs by up to 80% with a more efficient, sustainable approach.</li>
                                            <li>GPT (Generative Pre-Trained) access to their 12,500-member workforce.</li>
                                        </ul>
                                        <p>Since its debut in December 2022, ChatGPT has highlighted the transformative potential of Generative AI. In less than a year's investment in GenAI innovation, <b>TomTom has introduced to the world a ChatGPT location plugin, an AI assistant for in-car digital cockpits, and has launched several internal GenAI applications that simplify mapping and location technology development.</b> TomTom achieved these without substantial additional investments or a large influx of new hires. This experience provided valuable insights and helped crystalize our strategy. In this engineering blog, TomTom is pulling back the curtain on their generative AI adventure.</p>
                                    </div>
                                    <div className="mi-ai-stories-why-we-won">
                                        <h2>Why we won?</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li><strong>Executive Sponsorship:</strong> connect our Tom Tom and Microsoft executives to create the support and sponsorship for co-create solutions and joint go-to-market activities.</li>
                                            <li><strong>Ideate on Co-Innovation:</strong> early face to face ideation sessions, with remote follow up sessions.</li>
                                            <li><strong>Communication:</strong> close communication and collaborating with the customer.</li>
                                            <li><strong>Working Group:</strong> for these initiatives we identified key contributors, product groups to collaborate in a recurrent cadence.</li>
                                            <li><strong>Early Feedback:</strong> early feedback and quick iterations drove clear understanding of the potential and limitation of the technology.</li>
                                            <li><strong>Industry Events:</strong> co-presenting at cross-industry events like CES, Smart City and more, with teams from Tom Tom and Microsoft.</li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-business-impact">
                                        <h2>Business Impact</h2>
                                        <p>The solution offers significant business benefits:</p>
                                        <ul className="mi-ai-stories-list">
                                            <li><strong>Improved User Experience:</strong> The AI assistant allows drivers to converse naturally with their vehicle. They can ask the AI-powered assistant to navigate to a certain location, find specific stops along their route, and vocally control onboard systems. This enhances the driving experience and can lead to higher customer satisfaction.</li>
                                            <li><strong>Cost Efficiency:</strong> The solution can cut costs by up to 80%, providing a more efficient and sustainable approach. This is achieved through the integration of the solution into TomTom's Digital Cockpit and other automotive infotainment systems.</li>
                                            <li><strong>Brand Customization:</strong> Automobile manufacturers can customize the AI assistant to match the look and feel of their brand. This allows for a unique and personalized user experience, which can enhance brand loyalty and customer satisfaction.</li>
                                            <li><strong>Time-to-Market:</strong> The solution can expedite car manufacturers' time-to-market with a customizable interface. This can give them a competitive edge in the rapidly evolving automotive market.</li>
                                            <li><strong>Workforce Empowerment:</strong> By providing GPT (Generative Pre-Trained) access to their 12,500-member workforce, TomTom can leverage the power of AI across its operations. This can lead to improved efficiency, innovation, and productivity.</li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-repeatable-ip-pattern">
                                        <h2>Repeatable IP & Pattern</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li>The implementation leverages the automotive messaging, data & analytics reference architecture.</li>
                                            <li><strong>Digital Cockpit + Voice AI Assistant:</strong> OEMs across the globe are showing interest and plan to explore TomTom's Voice AI Assistant for deployment.</li>
                                            <li><strong>Digital Cockpit + Fabric Cloud Analytics:</strong> The solution architecture with Fabric will be published as a reference architecture to support OEMs on capture insights from in-vehicle user-experiences.</li>
                                            <li><strong>Connected Fleet & Logistics:</strong> Joint go-to-market activities ongoing with cross-industry interest from early adopters in Government, Transportation & Logistics, Healthcare, Retail.</li>
                                            <li>The turn-by-turn navigation component from TomTom is available as a PCF component for Power Platform and can be easily integrated with Dynamics 365 applications like Field Service, and Power Platform components like Power Apps, Power Portals, and Power BI. The component leverages the automotive connected fleets reference architecture.</li>
                                        </ul>
                                    </div>
                                    <div className="mi-ai-stories-expansion">
                                        <h2>Expansion</h2>
                                        <ul className="mi-ai-stories-list">
                                            <li>TomTom and Microsoft are collaborating to develop new automotive solutions that leverage AI capabilities, cloud analytics, and Microsoft Automotive reference architectures.</li>
                                            <li><strong>Microsoft Technology Center Assets:</strong> Intend to infuse the TomTom & Microsoft solutions as MTC assets across the globe.</li>
                                            <li><strong>AI Innovation:</strong> TomTom operates at the edge of innovation as they are looking to explore the open-source LLM and SLM space in partnership with Microsoft.</li>
                                            <li><strong>Joint go-to-market:</strong> Active collaboration on creating GTM material.</li>
                                            <li><strong>Azure Marketplace:</strong> Move TomTom product portfolio to Azure Marketplace.</li>
                                            <li><strong>Executive Briefing Center:</strong> Organize an EBC to inspire TomTom on Microsoft Innovations.</li>
                                            <li><strong>Industry Events:</strong> Align on key 2024 events, TomTom presented at Microsoft Build 2024.</li>
                                            <li><strong>Partners:</strong> Introduce TomTom to our Microsoft Service Delivery/ISV Partners.</li>
                                            <li><strong>Government:</strong> Establish awareness and co-sell in Government industry (Transportation/Smart City).</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>

                    <ArchitectureDiagram />
                    <TeamPage />
                    
                    
            </div>
                    
    </div>
    )
}