interface IProps {
    data: any
    tabindex: number
}

export const BlogText = (props: IProps) => {
    let {data, tabindex} = props;
    const items = data.items ? data.items : [];
    return (
        <div className="ms-ai-blog-content">
            {items.map((item: any, index: number) => {
                //tabindex++;
                return (
                    <div tabIndex={tabindex} key={"blog-item-" + index}>
                        {item.title && <div className="ms-ai-blog-content-title">
                            {item.title}
                        </div>}
                        <div className="ms-ai-blog-content-paragraph"
                             dangerouslySetInnerHTML={{__html: item.description}}/>
                    </div>
                )
            })}
        </div>
    )
}