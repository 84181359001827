import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { geturlPrefix } from "../../utils/functions";
import { fetchCategories, fetchContents } from "../../utils/api";
import { setCategories, setContents } from "../../store/reducers/commonReducer";
// import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";

import { Right } from '../page/htmlelements/arrows/Right';
import { Left } from '../page/htmlelements/arrows/Left';


export const Cards = () => {
    let tabindex = 0;
    const hasMounted = React.useRef(false);
    const dispatch = useDispatch();
    const homeData = useSelector((state: RootState) => state.common.homeData);
    const categories = useSelector((state: RootState) => state.common.categories);
    const contents = useSelector((state: RootState) => state.common.contents);
    const accountInfo = useSelector((state: RootState) => state.common.accountInfo);
    const showArray = categories.map((x:any) => { return {id: x._id, showAll: false } });
    const [showAllArray, setShowAllArray] = useState<Array<{id: string,showAll: boolean}>>(showArray);
    const title = homeData?.title ? homeData?.title : "";
    const subTitle = homeData?.description ? homeData?.description : "";
    let urlPrefix = geturlPrefix();
    const loadCategoriesAndContents = () => {
        if (categories.length === 0) {
            loadCategories();
        }
        if (contents.length === 0) {
            loadContents();
        }

    }
    useEffect(() => {
        const showArray = categories.map((x:any) => { return {id: x._id, showAll: false } });
        setShowAllArray(showArray)
    },[categories])
    const setShowAllFor = (id: string) => {
        setShowAllArray(prevState => 
            prevState.map(item =>
                item.id === id ? { ...item, showAll: !item.showAll } : item
            )
        );
    };
    const loadCategories = async () => {
        const result = await fetchCategories(accountInfo);
        if (result) {
            dispatch(setCategories(result));
        }
    }
    const loadContents = async () => {
        const result = await fetchContents(accountInfo);
        if (result?.data) {
            dispatch(setContents(result.data));
        }
    }
    useEffect(() => {
        if (hasMounted.current) return;

        hasMounted.current = true;
        loadCategoriesAndContents();
    }, []);
    const navigate = useNavigate();
    return (
        <div className="ms-ai-card-list-wrapper ms-ai-card-list-wrapper-home">
            <div className="ms-ai-card-list-container ai-container">
                <div className="ms-ai-card-cat-block">
                    <div className='ms-ai-home-title-container'>
                        <div className="ms-ai-inner-page-main-title">
                            {title}
                        </div>
                        <div className="ms-ai-inner-page-main-desc">
                            {subTitle}
                        </div>
                    </div>
                    {categories && categories.length > 0 && categories.map((data: any, index: number) => {
                        const subItems = contents.filter((x: any) => x.category_id === data._id);
                        if (!data.hide_from_page) {
                            // const settingsnew: any = formatSettingsinfinity(subItems.length);
                            const showAllData = showAllArray.find((x:any) => x.id === data?._id);
                            const showAll = showAllData ? showAllData.showAll :  false;
                            return (
                                <div className='ms-ai-card-cat-block ms-ai-slider-container'
                                    key={"main-category-" + index}>
                                    <div className="ms-ai-card-cat-title-btn-block">
                                        <div className="ms-ai-card-cat-title">{data.title}</div>
                                        {subItems.length > 4 && <div className="ms-ai-card-cat-show-all-container">
                                            <button className="ms-ai-card-cat-show-all-btn" onClick={() => setShowAllFor(data?._id)}>{showAll ? "Show Less" : "Show All"}</button>
                                        </div>}
                                    </div>
                                    <div className="ms-ai-card-block ">
                                        <div
                                            className={`${showAll ? "ms-ai-show-as-grid" : "slider-container slide-paddings home-slider-cards"} `}>
                                            {/* <Slider {...settings}> */}
                                            {subItems.map((subItem: any, index: number) => {
                                                let url = subItem.comingsoon ? '#' : "/" + subItem.url_path;
                                                if (subItem.content_type === "External Link") {
                                                    url = subItem.link;
                                                }
                                                //tabindex++;
                                                return (
                                                    <Link aria-label={data.title}
                                                        to={url}
                                                        target={(subItem.content_type === "External Link" && subItem?.newtab === true) ? "_blank" : "_self"}
                                                        key={"ms-ai-card-item-" + index}
                                                        className={`${subItem.comingsoon ? 'coming-soon-item' : ''}`}>
                                                        <div className="ms-ai-card-container">
                                                            <div tabIndex={tabindex}
                                                                onKeyDown={(event: any) => {
                                                                    if (event?.code === "Space" || event.key === "Enter") {
                                                                        navigate("/" + subItem.key)
                                                                    }
                                                                }
                                                                } className="ms-ai-card-item"
                                                            >
                                                                <div
                                                                    className="ms-ai-card-bg-img">
                                                                    <img
                                                                        src={urlPrefix + (subItem.image_url ? subItem.image_url : 'assets/images/usecases/transparent.png')}
                                                                        alt="" />
                                                                </div>

                                                            </div>
                                                            <div
                                                                className="ms-ai-card-name">
                                                                <div className="ms-ai-card-item-category">{data.title}</div>
                                                                <span className="ms-ai-card-item-title">{subItem.title}</span>
                                                            </div>
                                                            <div className="ms-ai-card-item-learn-more" >
                                                                <span className="ms-ai-card-btn-arrow">
                                                                    <svg
                                                                        width="10"
                                                                        height="10"
                                                                        viewBox="0 0 15 15"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M4.59375 12.5531C4.59375 12.4287 4.63921 12.3211 4.73013 12.2301L9.45308 7.50001L4.73013 2.76989C4.63921 2.67897 4.59375 2.5713 4.59375 2.44689C4.59375 2.32247 4.63921 2.21481 4.73013 2.12389C4.82104 2.03297 4.92871 1.98751 5.05312 1.98751C5.17754 1.98751 5.28521 2.03297 5.37612 2.12389L10.4292 7.17701C10.5202 7.26793 10.5656 7.3756 10.5656 7.50001C10.5656 7.62443 10.5202 7.73209 10.4292 7.82301L5.37612 12.8761C5.28521 12.9671 5.17754 13.0125 5.05312 13.0125C4.92871 13.0125 4.82104 12.9671 4.73013 12.8761C4.63921 12.7852 4.59375 12.6776 4.59375 12.5531Z"
                                                                            fill="white"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span className="ms-ai-card-btn-text">Learn more</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })}
                                            {/* </Slider> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}


                </div>

            </div>
        </div>
    )
}