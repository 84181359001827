import { RootState } from "@src/store/store";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"

interface IProps {
    item: any
}



export const MenuItem = (props : IProps) => {
    const contents = useSelector((state: RootState) => state.common.contents);
    const menuItems = useSelector((state: RootState) => state.common.menu_items);
    const path = useSelector((state: RootState) => state.common.path);
    const { item } = props;
    let subItems = menuItems.filter((x: any) => (!x.hide_from_menu && (x.parent_id === item._id || x.parent_id === item.content_id)))
    const isTechnicalPattern = subItems.find((x:any) => x.technical_pattern === true);
    if(isTechnicalPattern) {
        subItems = subItems.slice(0,1);
    }
    let contentData = contents.find((x:any) => x._id === item.content_id)
    if(!contentData && subItems.length > 0) {
        const nextId = subItems[0].content_id;
        contentData = contents.find((x:any) => x._id === nextId)
    }
    return (
        <li key={"menu-item-"}>
            <Link to={contentData?.url_path ? "/" + contentData?.url_path : "#"}
                className={`${path.includes(item._id) ? "active open-menu" : ""}`}>{item.technical_pattern ? "Technical Pattern" : ((contentData?.title && !item.category_id) ? contentData?.title : item.name)}
                <span className="">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.5284 11.376C11.7888 11.6364 12.2109 11.6364 12.4712 11.376C12.7316 11.1157 12.7316 10.6936 12.4712 10.4332L8.47124 6.43322C8.21089 6.17287 7.78878 6.17287 7.52843 6.43322L3.52843 10.4332C3.26808 10.6936 3.26808 11.1157 3.52843 11.376C3.78878 11.6364 4.21089 11.6364 4.47124 11.376L7.99984 7.84743L11.5284 11.376Z"
                            fill="black" />
                    </svg>
                </span>
            </Link>
            <ul>
            {subItems.map((subItem:any) => {
                return <MenuItem item={subItem} />
            })}
            </ul>
            
        </li>
    )
}