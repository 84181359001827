import React from 'react';
import '../../../assets/css/storiesteam.css';

const TeamPage = () => {
  return (
    <div className="mi-ai-stories-win-team-container">
      <h1 className="mi-ai-stories-win-team-title">Winning Team</h1>
      <div className="mi-ai-stories-win-team-sections">
        <div className="mi-ai-stories-win-team-section">
          <strong>STU</strong>
          <ul className="mi-ai-stories-win-team-list">
              <li>Ken Otani (Data & Al Lead)</li>
              <li>Yoshihiko Tomoyama (Data & Al MGR)</li>
              <li>Tetsuya Yamauchi (Data & Al SSP)</li>
          </ul>
          <strong>Al Pod GBB</strong>
          <ul className="mi-ai-stories-win-team-list">
              <li>Jixin Jia (AI SS)</li>
              <li>Alex Shen (Al SS)</li>
              <li>Tetsuya Yamauchi (Data & Al SSP)</li>
              <li>Kenichiro Nakamura (Al App Dev TS)</li>
              <li>Nobu Tanahashi (Al TS),</li>
          </ul>
          <strong>ATU</strong>
          <ul className="mi-ai-stories-win-team-list">
              <li>Tomoya Hara (Director)</li>
              <li>Yoshiki Nimura (AE).</li>
              <li>Tomoyoshi Nakamura (ATS)</li>
          </ul>
        </div>

        <div className="mi-ai-stories-win-team-section">
          <strong>CSU</strong>
          <ul className="mi-ai-stories-win-team-list">
              <li>Hirosato Gamo (Data & Al CSA)</li>
              <li>Rieko Endo (CSAM)</li>
          </ul>
          <strong>GPS</strong>
          <ul className="mi-ai-stories-win-team-list">
              <li>Sou Kohsaka</li>
          </ul>
          <strong>Industry Advisor</strong>
          <ul className="mi-ai-stories-win-team-list">
              <li>Yoshihiro Kozuki</li>
          </ul>
          <strong>ISD</strong>
          <ul className="mi-ai-stories-win-team-list">
              <li>Takashi Isogai</li>
          </ul>
        </div>


        <div className="mi-ai-stories-win-team-section">
          <strong>SPT</strong>
          <ul className="mi-ai-stories-win-team-list">
              <li>Mandar Wavde</li>
              <li>Megumi leda</li>
          </ul>
          <strong>Deal Desk</strong>
          <ul className="mi-ai-stories-win-team-list">
              <li>Sandeep Uttam</li>
          </ul>
          <strong>Executives</strong>
          <ul className="mi-ai-stories-win-team-list">
            <li>Miki Tsusaka (CVP)</li>
            <li>Jon Tinter (CVP)</li>
            <li>Eric Boyd (CVP)</li>
            <li>Ali Dalloul (VP)</li>
            <li>Nobuhiko Watanabe (ATU Lead)</li>
            <li>Tadashi Okazaki (STU Lead)</li>
            <li>Atsushi Maekawa (ISD Lead)</li>

          </ul>
        </div>

      </div>

      <div className="mi-ai-stories-win-team-resources">
        <h3>Winwire Resources</h3>
        <ul className="mi-ai-stories-win-team-list">
          <li><a href="#">Subscribe to Al Design Win - WinWire/LiveWire</a></li>
          <li><a href="#">Submit an AI Design Win - WinWire/LiveWire</a></li>
          <li><a href='%'>Al Design Win - WinWire Template</a></li>
          <li><a href='#'>Al Design Win - LiveWire</a></li>
        </ul>
      </div>
    </div>
  );
};

export default TeamPage;

