import { BlogCards } from "../htmlelements/blog-cards";
import { BlogText } from "../htmlelements/blog-text";
import { CardSlider } from "../htmlelements/card-slider";
import { IconText } from "../htmlelements/icon-text";
import { ImageCard } from "../htmlelements/image-card";
import { ImageSlider } from "../htmlelements/image-slider";
import { ResourceLinks } from "../htmlelements/resource-link";
import { SmallCardSlider } from "../htmlelements/small-card-slider";
import { SmallCard } from "../htmlelements/small-card";
import { TextTag } from "../htmlelements/text-tag";
import { TitleDescription } from "../htmlelements/title-description";
import { LogoImageCard } from "../htmlelements/logo-image-card";
import AiSearch from "@src/components/aiserach";
import { config } from "@src/config";
import { GroupBlock } from "../htmlelements/group-block";
import { GroupTwoBlock } from "../htmlelements/group-block/group-two-column";
import { ListView } from "../htmlelements/list";

interface IProps {
    pageData: any
    tabindex: number
}

export const ContentArea = (props: IProps) => {
    let { pageData, tabindex } = props;
    let pageContent = pageData?.content ? pageData?.content : [];
    const pageDetails = pageData?.details ? pageData?.details : [];
    let titleDecSectionCount = 0;
    let pageTitleItem = false;
    pageContent = pageContent.filter((x: any) => !x?.data?.group_id)
    return (
        <div className="ms-ai-inner-page-right">
            {config.enableAISearch && <div className="ms-ai-search-wrapper">
                <AiSearch />
            </div>}
            <div className={`${(pageDetails.technical_pattern === true || pageDetails.technical_pattern === 1) ? "ms-ai-blog-page" : ""}`}>
                {pageContent.map((content: any, index: number) => {
                    if (content?.data.items) {
                        //tabindex+=content?.data.items.length;
                    }
                    tabindex = 0;
                    if (content?.htmlType === "title-description") {
                        titleDecSectionCount++;
                        if (pageDetails?.technical_pattern && titleDecSectionCount === 2) {
                            pageTitleItem = true;
                        } else if (!pageDetails?.technical_pattern && titleDecSectionCount === 1) {
                            pageTitleItem = true;
                        } else {
                            pageTitleItem = false;
                        }
                    }
                    return (
                        <div key={"content-area-" + index}>
                            {(content?.htmlType === "group-block" && content.data) &&
                                <GroupBlock index={index} key={pageData.key} data={content} pageData={pageData} />}
                            {(content?.htmlType === "two-column-layout" && content.data) &&
                                <GroupTwoBlock index={index} key={pageData.key} data={content} pageData={pageData} />}
                            {(content?.htmlType === "title-description" && content.data && ((!pageDetails?.technical_pattern) || (pageDetails?.technical_pattern && index > 0))) &&
                                <TitleDescription index={index} pageTitleItem={pageTitleItem} key={pageData.key} data={content.data} pageDetails={pageDetails} />}
                            {(content?.htmlType === "card-slider" && content.data && content?.data.items.length > 0) &&
                                <CardSlider tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "small-card-slider" && content.data && content?.data.items.length > 0) &&
                                <SmallCardSlider tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "small-card" && content.data && content?.data.items.length > 0) &&
                                <SmallCard tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "text-tag" && content.data && content?.data.items.length > 0) &&
                                <TextTag tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "icon-text" && content.data && content?.data.items.length > 0) &&
                                <IconText tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "list" && content.data && content?.data.items.length > 0) &&
                                <ListView tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "image-card" && content.data && content?.data.items.length > 0) &&
                                <ImageCard tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "logo-image-card" && content.data && content?.data.items.length > 0) &&
                                <LogoImageCard tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "resource-link" && content.data && content?.data.items.length > 0) &&
                                <ResourceLinks tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "image-slider" && content.data && content?.data.items.length > 0) &&
                                <ImageSlider tabindex={tabindex} key={pageData.key} data={content.data}
                                    pageData={pageData} />}
                            {(content?.htmlType === "blog-text" && content.data) &&
                                <BlogText tabindex={tabindex} key={pageData.key} data={content.data} />}
                            {(content?.htmlType === "blog-cards" && content.data && content?.data.items.length > 0) &&
                                <BlogCards tabindex={tabindex} key={pageData.key} data={content.data} />}
                        </div>
                    )
                })}

            </div>

        </div>
    )
}