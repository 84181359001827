import React from 'react';

const MSIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 16.00 16.00" fill="none">

        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
        <g id="SVGRepo_iconCarrier">
            <path fill="#F35325" d="M1 1h6.5v6.5H1V1z"/>
            <path fill="#81BC06" d="M8.5 1H15v6.5H8.5V1z"/>
            <path fill="#05A6F0" d="M1 8.5h6.5V15H1V8.5z"/>
            <path fill="#FFBA08" d="M8.5 8.5H15V15H8.5V8.5z"/>
        </g>

    </svg>
    
);

export default MSIcon;
