import podcastAudio from "@src/assets/podcast/Manufacturing Podcast_v3.mp3";
import podcastAudioAFA from "@src/assets/podcast/AI Factory Assistant Podcast-Updated.mp3";
import podcastAFSA from "@src/assets/podcast/AI Field Service Assistant.mp3";
import podcastCSS from "@src/assets/podcast/Customer Service and Support.mp3";
import podcastEAR from "@src/assets/podcast/Energy and Resources.mp3";
import podcastGov from "@src/assets/podcast/Government.mp3";
import podcastMed from "@src/assets/podcast/HLS MedTech.mp3";
import podcastPha from "@src/assets/podcast/HLS Pharma.mp3";
import podcastUni from "@src/assets/podcast/Universal.mp3";
import podcastVAD from "@src/assets/podcast/Visual Inspection and Anomaly Detection.mp3";

const podcast = [
    {
        key: "manufacturing",
        audio : podcastAudio,
        duration: 4
    },{
        key: "ai factory assistant",
        audio : podcastAudioAFA,
        duration: 6
    },
    {
        key: "ai field service assistant",
        audio: podcastAFSA,
        duration: 6
    },
    {
        key: "customer service and support",
        audio: podcastCSS,
        duration: 6
    },
    {
        key: "energy and resources",
        audio: podcastEAR,
        duration: 6
    },
    {
        key: "pubsec:: government",
        audio: podcastGov,
        duration: 5
    },
    {
        key: "government",
        audio: podcastGov,
        duration: 5
    },
    {
        key: "hls :: medtech",
        audio : podcastMed,
        duration: 6
    },
    {
        key: "hls :: pharma",
        audio: podcastPha,
        duration: 6
    },
    {
        key: "universal",
        audio: podcastUni,
        duration: 7
    },
    {
        key: "visual inspection and anomaly detection",
        audio: podcastVAD,
        duration: 5
    }
]

export default podcast;