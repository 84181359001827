import { getApiUrl, removeKeyIfExists } from './functions';

const apiUrl = getApiUrl();
interface accountInfoType {
    idtoken?:  string
    tenantid?: string
  }
// Function to create headers with authorization token
export const createHeaders = (headerData:any): HeadersInit => {
    return {
        'Content-Type': 'application/json',
        ...headerData
    };
};

export const fetchHomeSettings = async (accountInfo:null|accountInfoType): Promise<any> => {
    const response = await fetch(apiUrl+'/api/get-home-page-settings', {
        method: 'GET',
        headers: createHeaders(accountInfo),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};

export const fetchCategories = async (accountInfo:null|accountInfoType): Promise<any> => {
    const response = await fetch(apiUrl+'/api/get-categories', {
        method: 'GET',
        headers: createHeaders(accountInfo),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};

export const fetchContents = async (accountInfo:null|accountInfoType): Promise<any> => {
    const response = await fetch(apiUrl+'/api/get-all-contents', {
        method: 'GET',
        headers: createHeaders(accountInfo),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};


export const fetchPage = async (data: {slug: string},accountInfo:null|accountInfoType): Promise<any> => {
    const response = await fetch(apiUrl+'/api/fetch-data', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: createHeaders(accountInfo),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};

export const fetchAllMenu = async (accountInfo:null|accountInfoType): Promise<any> => {
    const response = await fetch(apiUrl+'/api/get-menuitems', {
        method: 'GET',
        headers: createHeaders(accountInfo),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};