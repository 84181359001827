import React, { useCallback, useEffect, useState } from 'react';
import './assets/css/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from 'universal-cookie';
import { Page } from "./components/page";
import { config } from './config';
import { jwtDecode } from "jwt-decode";
import { Context, initialContext } from "./context/menu";
import { Banner } from "./components/banner";
import { Login } from './components/login';
import { Loader } from './components/loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SSOLogin } from "./components/login/sso";

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import { fetchAllMenu, fetchHomeSettings, fetchPage } from './utils/api';
import { setAccountInfo, setHomeData, setMenuItems, setPage, setPageData, setPath } from './store/reducers/commonReducer';
import { Cards } from './components/home/Cards';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { Maintenance } from './components/maintenance';

interface DataItem {
    key: string;
    path?: string[];
    title?: string;
    layout?: string;
    thumbnail?: string;
    content?: any; // You might want to define this more precisely depending on what it contains
    items?: DataItem[];
}

interface IdTokenClaims {
    exp: number;
    [key: string]: any;
}

function App() {
    const dispatch = useDispatch();
    const { instance, accounts, inProgress } = useMsal();
    const [offlineMode, setOfflineMode] = useState<boolean>(false);
    const isAuthenticated = useIsAuthenticated();
    const needsAuthentication = config.needsAuthentication;
    const [loginChecked, setLoginChecked] = React.useState<boolean>(true);
    const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
    const [loginVerified, setLoginVerified] = React.useState<boolean>(false);
    // const [page, setPage] = React.useState<string>('');
    // const [pageData, setPageData] = React.useState<any>('');
    const hasMounted = React.useRef(false);
    const page = useSelector((state: RootState) => state.common.page);
    const pageData = useSelector((state: RootState) => state.common.pageData);
    const menuItems = useSelector((state: RootState) => state.common.menu_items);
    const contents = useSelector((state: RootState) => state.common.contents);
    const accountInfo = useSelector((state: RootState) => state.common.accountInfo);

    const TOKEN_REFRESH_INTERVAL = 60 * 1000; // Check every minute
    const TOKEN_EXPIRY_THRESHOLD = 5 * 60; // 5 minutes

    const [isClicked, setIsClicked] = React.useState(initialContext.isClicked);
    const cookies = new Cookies();
    let { key }: any = useParams();
    const navigate = useNavigate();



    const loadMenu = async () => {
        const result = await fetchAllMenu(accountInfo);
        if (result && !result.offline_mode) {
            dispatch(setMenuItems(result));
        }
    }
    const getParentId = (path: any, parent_id: string) => {
        let menuItem = menuItems.find((x: any) => x._id === parent_id || x.content_id === parent_id);
        if (menuItem) {
            path.push(menuItem._id);
            if (menuItem?.parent_id) {
                path = getParentId(path, menuItem?.parent_id);
            }
        }
        return path;
    }
    const isTokenExpiringSoon = (token: IdTokenClaims) => {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds since epoch
        return token.exp < (currentTime + TOKEN_EXPIRY_THRESHOLD);
    };
   
    const acquireNewIdTokenInteractively = useCallback(async (request: any) => {
        try {
            // Add forceRefresh to the request object
            const silentRequest = {
                ...request,
                forceRefresh: true
            };

            // Try to acquire the token silently first
            const response = await instance.acquireTokenSilent(silentRequest);

            // If successful, update the account info
            const accInfo = {
                idtoken: response.idToken,
                tenantid: response.account?.tenantId,
                name: response.account?.name,
                email: response.account?.username,
            };
            dispatch(setAccountInfo(accInfo));

            return response;
        } catch (error) {
            console.error("Error acquiring token silently:", error);

            // If silent acquisition fails, fall back to interactive method
            try {
                // Use acquireTokenRedirect for interactive acquisition
                await instance.acquireTokenRedirect(request);
                // The response will be handled in the useEffect that manages redirect
            } catch (redirectError) {
                console.error("Error initiating token acquisition via redirect:", redirectError);
                // Optionally handle the error, such as prompting the user to retry
            }
        }
    }, [instance, dispatch]);

    useEffect(() => {
        const handleRedirectResponse = async () => {
            if (inProgress === "none" && accounts.length > 0) {
                try {
                    const result = await instance.handleRedirectPromise();
                    if (result) {
                        const newIdToken = result.idToken;
                        const accInfo = {
                            idtoken: newIdToken,
                            tenantid: result.tenantId,
                            name: accounts[0].name,
                            email: accounts[0].username,
                        };
                        dispatch(setAccountInfo(accInfo));
                    }
                } catch (error) {
                    console.error("Error handling redirect:", error);
                }
            }
        };

        handleRedirectResponse();
    }, [instance, accounts, inProgress, dispatch]);
    useEffect(() => {
        const acquireToken = async () => {
            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                const acc = accounts[0];
                const request = {
                    scopes: ["openid", "profile", "User.Read", "offline_access"],
                    account: acc,
                };

                try {
                    await instance.initialize();
                    let tokenResponse = await instance.acquireTokenSilent(request);
                    const idTokenClaims = tokenResponse.idTokenClaims as IdTokenClaims;
                    // If token is expiring soon, refresh it
                    if (isTokenExpiringSoon(idTokenClaims)) {
                        await acquireNewIdTokenInteractively(request);
                    } else {
                        const newIdToken = tokenResponse.idToken;
                        const accInfo = {
                            idtoken: newIdToken,
                            tenantid: acc?.tenantId
                        };
                        dispatch(setAccountInfo(accInfo));
                        console.log("New idToken acquired silently:", newIdToken);
                    }
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        // If silent token acquisition fails, fall back to interactive method
                        await acquireNewIdTokenInteractively(request);
                    } else {
                        console.error("Error acquiring token silently:", error);
                    }
                }
            }
        };

        // Set an interval to check token expiration periodically
        const intervalId = setInterval(() => {
            if (instance && isAuthenticated) {
                acquireToken();
            }
        }, TOKEN_REFRESH_INTERVAL);

        // Initial token acquisition
        if (instance) {
            acquireToken();
        }

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, [instance, isAuthenticated]);

    useEffect(() => {
        let path: any = [];
        let activeId = pageData?.details?._id;
        let menuItem = menuItems.find((x: any) => x.content_id === activeId);
        if (menuItem) {
            path.push(menuItem._id);
            if (menuItem?.parent_id) {
                path = getParentId(path, menuItem?.parent_id);
            }
        }
        dispatch(setPath(path));


    }, [key, menuItems, contents, pageData])
    useEffect(() => {
        if (dataLoaded) {

            if (hasMounted.current) return;

            hasMounted.current = true;
            if (!needsAuthentication)
                loadMenu();

            let url = config.uhfUrl;
            fetch(url)
                .then(response => response.text())
                .then((data: any) => {
                    function parseHTML(html: any) {
                        var t = document.createElement('template');
                        t.innerHTML = html;
                        return t.content;
                    }

                    const xml = new DOMParser().parseFromString(data, "text/xml")
                    const jsData = xml.getElementsByTagName("javascriptIncludes");
                    const headerData = xml.getElementsByTagName("headerHtml");
                    const footerData = xml.getElementsByTagName("footerHtml");
                    const cssData = xml.getElementsByTagName("cssIncludes");
                    if (cssData && cssData[0].textContent) {
                        const cssIncludes = cssData[0].textContent;
                        (document as any).getElementsByTagName("head")[0].append(parseHTML(cssIncludes));
                    }
                    if (jsData && jsData[0].textContent) {
                        const jsIncludes = jsData[0].textContent;
                        (document as any).getElementsByTagName("body")[0].append(parseHTML(jsIncludes));
                    }
                    if (headerData && headerData[0]?.textContent && (document as any).getElementById("customHeader")) {
                        const headerHTML = headerData[0].textContent;
                        (document as any).getElementById("customHeader").innerHTML = headerHTML;
                    }
                    if (footerData && footerData[0]?.textContent && (document as any).getElementById("customFooter")) {
                        const footerHTML = footerData[0].textContent;
                        (document as any).getElementById("customFooter").innerHTML = footerHTML;
                    }

                })

        }
    }, [dataLoaded])
    const loadHomeData = async () => {
        try {
            const homeSettings = await fetchHomeSettings(accountInfo);
            if (homeSettings?.offline_mode) {
                setOfflineMode(true);
            }
            dispatch(setHomeData(homeSettings[0]));
            setDataLoaded(true);
        } catch (e) {
            console.log("Error ", e)
            setTimeout(() => {
                loadHomeData();
            }, 3000)
        }

    }
    useEffect(() => {
        if (!needsAuthentication)
            loadHomeData();
    }, [])
    useEffect(() => {
        if(key && accountInfo) {
            loadPageData(key);
        }
    },[key,accountInfo])
    useEffect(() => {
        if (accountInfo) {
            
            loadMenu();
            loadHomeData();
        }

    }, [accountInfo])
    const setTitle = (title: string) => {
        const el = document.querySelector('title');
        if (el)
            el.innerText = title ? `Microsoft Use Case Explorer for AI Design Wins | ${title}` : `Microsoft Use Case Explorer for AI Design Wins`;
    };

    const setDescription = (desc: string) => {
        const el = document.querySelector("meta[name='description']");
        if (el)
            el.setAttribute('content', desc)
    }
    React.useEffect(() => {
        if (pageData && pageData.title) {
            setTitle(pageData.title)
        } else {
            setTitle(config.defaultTitle)
        }
        if (pageData && pageData.content && pageData.content.length > 0 && !pageData.customcomponent) {
            const findDescription = pageData.content.find((x: any) => x.htmlType === "title-description" && x.data?.description);
            if (findDescription) {
                setDescription(findDescription.data.description)
            } else {
                setDescription(config.defaultDescription)
            }
        } else if (pageData && pageData.description) {
            setDescription(pageData.description)
        } else {
            setDescription(config.defaultDescription)
        }
    }, [pageData])
    const loadPageData = async (key: string) => {
        const result = await fetchPage({ slug: key }, accountInfo);
        if (result.success) {
            dispatch(setPageData(result.data));
            dispatch(setPage(key));

        }
    }

    React.useEffect(() => {
        if (key && key !== "") {
            if (!needsAuthentication) {
                loadPageData(key);
            }
            

            window.scrollTo(0, 0);

        } else {
            dispatch(setPageData(null));
            dispatch(setPage(""));
        }
    }, [key]);

    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }
    let tabindex = 0;
    return (
        <Context.Provider
            value={{
                isClicked,
                setIsClicked
            }}
        >
            <div id="customHeader"></div>
            {offlineMode === true ?
                <Maintenance />
                : <>
                    {(!loginChecked || (isAuthenticated && !dataLoaded)) && <Loader />}
                    {(loginChecked && needsAuthentication && !loginVerified && !config.sso) &&
                        <Login setLoginVerified={setLoginVerified} />
                    }

                    {(loginChecked && needsAuthentication && !isAuthenticated && config.sso) &&
                        <SSOLogin setLoginVerified={setLoginVerified} />
                    }

                    {(loginChecked && needsAuthentication && (isAuthenticated && (!instance.getActiveAccount()?.username?.includes('@touchcast.com') && !instance.getActiveAccount()?.username?.includes('@microsoft.com'))) && config.sso) &&
                        <SSOLogin setLoginVerified={setLoginVerified} noAccess={true} />
                    }

                    {(loginChecked && (!needsAuthentication || loginVerified || (accountInfo && isAuthenticated && (instance.getActiveAccount()?.username?.includes('@touchcast.com') || instance.getActiveAccount()?.username?.includes('@microsoft.com'))))) && (
                        <div>
                            {page === '' &&
                                <>
                                    {/* Hero section starts */}
                                    {dataLoaded && <Banner />}
                                    {/* Hero section ends */}
                                    <div className="ms-ai-wrapper">

                                        <div className="ms-ai-container">

                                            {/* Header section starts */}
                                            {/* <Header/> */}
                                            {/* Header section ends */}

                                            {/* Card block starts */}

                                            {dataLoaded && <Cards />}
                                            {/* Card block ends */}
                                        </div>
                                    </div>
                                </>
                            }
                            {(page !== '' && pageData) && <Page key={page} tabindex={tabindex} pageData={pageData} />}

                        </div>)}
                </>}
            <div id="customFooter"></div>
        </Context.Provider>
    );
}

export default App;
