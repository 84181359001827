import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import App from '../App';
import {Tomtom} from "../components/stories/Tomtom";

const AppRoutes: React.FC = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<App/>}/>
            <Route path="/story/tomtom" element={<App/>}/>
            <Route path="/:key" element={<App/>}/>
        </Routes>
    </BrowserRouter>;
}

export {AppRoutes};