import React from 'react';
import '../../../assets/css/storiesteam.css';

const TeamPage = () => {
  return (
    <div className="mi-ai-stories-win-team-container">
      <h1 className="mi-ai-stories-win-team-title">Winning Team</h1>
      <div className="mi-ai-stories-win-team-subtitle">(Alphabetical order)</div>
      
      <div className="mi-ai-stories-win-team-sections">
        <div className="mi-ai-stories-win-team-section">
          <h3 className="mi-ai-stories-win-team-heading">Core Team</h3>
          <ul className="mi-ai-stories-win-team-list">
            <li>Mathias Devis (AE)</li>
            <li>Florian Borget (TSP Power BI)</li>
            <li>Mario Ortegon Cabrera (MCI SDV & Mobility PM)</li>
            <li>Jochem Claes (SSP Data & AI)</li>
            <li>Ilaria La Femina (SSP Data & AI)</li>
            <li>Jonathan D'haene (TSP Buss. App)</li>
            <li>Davy De Roeck (TSP Low-Code)</li>
            <li>Amir Ozdoev (CE)</li>
            <li>Rudy Pelgrims (CSAM)</li>
            <li>John Stenlake (Dir. Vehicle Innovation & Mobility)</li>
            <li>Johan Torts (Belux ISV Lead)</li>
            <li>Cindy Verbruggen (ATS)</li>
            <li>Jorge Garcia Ximenez (Belux AI Lead, CSA)</li>
          </ul>
        </div>

        <div className="mi-ai-stories-win-team-section">
          <h3 className="mi-ai-stories-win-team-heading">Extended Team</h3>
          <ul className="mi-ai-stories-win-team-list">
            <li>Nathan Bijnens (CSA Man. Data & AI)</li>
            <li>Antoine Borremans (SSP Security)</li>
            <li>Jan Bouckaert (SSP Man. Core)</li>
            <li>Marthe Claes (SSP Buss. App)</li>
            <li>Dina Fatkulbayanova (CSA. Core)</li>
            <li>Kaivan Karimi (Global Mobility Partnership)</li>
            <li>Aaron Lee (Global Product Marketing Director, Automotive, Mobility)</li>
            <li>Sam Martins (TSP Man. Buss App)</li>
            <li>Raj Paul (Mobility Industry Leader, Americas)</li>
            <li>John Reed (Solution Leader, Manufacturing & Mobility)</li>
            <li>Ewoud Smets (TSP Core)</li>
            <li>Diego Trinciarielli (TSP Data & AI)</li>
            <li>Bert Vandebroek (SSP Applnno)</li>
            <li>Bart Vande Ghinste (TSP App Inno)</li>
            <li>Jan Verdickt (Belux Data & AI Lead)</li>
            <li>Beau Vingerhoets (SSP Core)</li>
          </ul>
        </div>

        <div className="mi-ai-stories-win-team-section">
          <h3 className="mi-ai-stories-win-team-heading">Executive Support</h3>
          <ul className="mi-ai-stories-win-team-list">
            <li>Fred Chong (PM Manager, MCI SDV & Mobility)</li>
            <li>Audrey Colle (Partner Manager, Manufacturing & Mobility)</li>
            <li>Jeremy Decuyper (Belux CFO)</li>
            <li>Sonia Glubisz (riling EMEA Deal Strategy)</li>
            <li>Hermien Heervaert (Belux STU Lead)</li>
            <li>Erik Kerkhofs (ry, JE ISV Lead)</li>
            <li>Matthew Kerner (CVP, Cloud for Industry)</li>
            <li>Nick Lee (CVP, Maps & Local)</li>
            <li>Cindy Rose (COO, CVP Global Ent. Sales)</li>
            <li>Harold Ryckaert (Belux Manuf Lead)</li>
            <li>Marijke Schroos (GM, Belux)</li>
            <li>Steven Stokmans (Belux Enterprise Lead)</li>
            <li>Rupert Stuetzele (EMEA leader, Manufacturing & Mobility)</li>
            <li>Mathieu Van den Mooter (CS Lead Belux)</li>
            <li>Jan Verdickt (Belux Data & AI Lead)</li>
            <li>Dominik Wee (CVP Manufacturing & Mobility)</li>
          </ul>
        </div>
      </div>

      <div className="mi-ai-stories-win-team-resources">
        <h3>Winwire Resources</h3>
        <ul>
          <li><a href="#">Subscribe to AI Design WinWires & LiveWires</a></li>
          <li><a href="#">Submit an AI Design Win - WinWire/LiveWire</a></li>
        </ul>
      </div>
    </div>
  );
};

export default TeamPage;

