import { useState } from "react";
import { ContentsArea } from "../../contentarea/contents";
import "./groupblock.css";

interface IProps {
    data: any
    pageData: any
    index: number
}


export const GroupBlock = (props: IProps) => {
    const { data, index, pageData } = props;

    let content = pageData?.content ? pageData?.content : [];
    const pageDetails = pageData?.details ? pageData?.details : [];
    let subContents = content.filter((x: any) => ((x?.data.group_id === data?.data?.cc_id ) && (x?.data?.items.length > 0 || x?.data?.content_type === "related-use-cases")));
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const changeTab = (tab: number) => {
        setSelectedIndex(tab);
    }
    subContents = subContents.filter((x:any) => (x?.htmlType !== "title-description" || (x?.htmlType === "title-description" && x?.data?.items && x?.data?.items.filter((y:any) => y.subtitle.trim() !== "" || y.description.trim() !== "").length > 0)))
    console.log("GROUP BLOCK ", subContents)
    return (
        <>
        {subContents.length > 0 && <div className="ms-ai-item-group-block">
            <div className="ms-ai-item-group-block-title-area">
                <div className="ms-ai-item-group-block-title">{data?.data?.title}</div>
                {data?.data?.subtitle && <div className="ms-ai-item-group-block-subtitle">{data?.data?.subtitle}</div>}
            </div>
            <div className="ms-ai-item-group-block-tabs">
                {subContents.map((tab: any, index: number) => {
                    return (
                        <div key={'tab' + index} className={`ms-ai-item-group-block-tab-item ${index === selectedIndex && 'active'}`} onClick={() => changeTab(index)}>
                            {tab?.data?.title}
                        </div>
                    )
                })}
            </div>
            <div className="ms-ai-item-group-block-tab-contents">
                {subContents.map((content: any, index: number) => {
                    return (
                        <div key={'tab-content' + index} className={`ms-ai-item-group-block-content-item ${index === selectedIndex && 'active'}`}>
                              <ContentsArea index={index} content={content} pageData={pageData} pageDetails={pageDetails} />
                        </div>
                    )
                })}
            </div>

        </div>}
        </>
    )
}