import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@src/store/store";
import { useState } from "react";

interface IProps {
    data: any;
    tabindex: number
}

export const SmallCardSlider = (props: IProps) => {
    let { data, tabindex } = props;
    const navigate = useNavigate();
    const [showAll, setShowAll] = useState<boolean>(false);
    const contents = useSelector((state: RootState) => state.common.contents);
    const checkPageData = (slide: any) => {
        if (slide.link && slide.link !== "#") {
            window.open(slide.link, '_blank', 'rel=noopener noreferrer')
        } else if (slide.content_id && slide?.content_type !== "External Link") {
            const contentItem = contents.find((x: any) => x._id === slide.content_id)
            if (contentItem) {
                navigate("/" + contentItem.url_path);
            }

        }
    }

    const items = data.items ? data.items : [];

    return (
        <div className="ms-ai-slider-wrapper ms-ai-small-card">
            {items.length > 0 && (<div className="ms-ai-slider-container">
                {!data?.group_id &&
                    <div className="ms-ai-item-group-block-title-area padding-b-40">
                        <div className="ms-ai-item-group-block-title">{data?.title}</div>
                        {data?.subtitle && <div className="ms-ai-item-group-block-subtitle">{data?.subtitle}</div>}
                    </div>
                }
                {items.length > 4 && <div className="ms-ai-card-cat-title-btn-block">
                    <div></div>
                    <div className="ms-ai-card-cat-show-all-container">
                        <button className="ms-ai-card-cat-show-all-btn" onClick={() => setShowAll(!showAll)}>{showAll ? "Show Less" : "Show All"}</button>
                    </div>
                </div>}
                <div className="ms-ai-slider-block">
                    <div className={`${showAll ? "ms-ai-show-as-grid" : "slider-container slide-paddings home-slider-cards horizontal-scroll-container"}  ${items.length < 5 && 'ms-justify-center'}`}>

                        {items.map((slide: any, slideindex: number) => {
                            let slideTitle = slide.content_data?.title ? slide.content_data?.title : slide.title;
                            return (

                                <div key={"slide-item=" + slideindex} className="ms-ai-slider-item"
                                    onClick={() => slide && checkPageData(slide)}>
                                    <span className="ms-ai-slider-item-icon"><svg
                                        width="35"
                                        height="35"
                                        viewBox="0 0 64 64"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect x="0.5" y="0.5" width="63" height="63" rx="7.5" fill="#FEFEFE" />
                                        <rect x="0.5" y="0.5" width="63" height="63" rx="7.5" stroke="#E6F2FB" />
                                        <g clipPath="url(#clip0)">
                                            <g clipPath="url(#clip1)">
                                                <path
                                                    d="M18.585 23.7142C18.585 23.1788 19.019 22.7448 19.5543 22.7448H39.5881C40.1235 22.7448 40.5575 23.1788 40.5575 23.7142C40.5575 24.2495 40.1235 24.6835 39.5881 24.6835H19.5543C19.019 24.6835 18.585 24.2495 18.585 23.7142ZM18.585 40.5166C18.585 39.9812 19.019 39.5473 19.5543 39.5473H33.1256C33.6609 39.5473 34.0949 39.9812 34.0949 40.5166C34.0949 41.052 33.6609 41.486 33.1256 41.486H19.5543C19.019 41.486 18.585 41.052 18.585 40.5166ZM19.5543 31.1461C19.019 31.1461 18.585 31.5801 18.585 32.1154C18.585 32.6507 19.019 33.0848 19.5543 33.0848H43.4656C44.001 33.0848 44.435 32.6507 44.435 32.1154C44.435 31.5801 44.001 31.1461 43.4656 31.1461H19.5543Z"
                                                    fill="#0078D4"
                                                />
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="31.02" height="32" fill="white" transform="translate(16 16)" />
                                            </clipPath>
                                            <clipPath id="clip1">
                                                <rect width="31.02" height="32" fill="white" transform="translate(16 16)" />
                                            </clipPath>
                                        </defs>
                                    </svg></span>
                                    <div className="ms-ai-slider-title-head">{data.title}</div>
                                    {slideTitle && <div className="ms-ai-slider-title">
                                        {slideTitle}
                                    </div>}
                                    {slide.description && <div className="ms-ai-slider-desc">
                                        <div dangerouslySetInnerHTML={{ __html: slide.description }} />
                                    </div>}
                                    {slide.link && <a className="ms-ai-learnmore"
                                        target={slide.link === "#" ? "_self" : "_blank"}
                                        href={slide.link && slide.link !== "#" ? slide.link : undefined}>
                                        <span>
                                            <svg
                                                width={26}
                                                height={27}
                                                viewBox="0 0 26 27"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    x="0.459839"
                                                    y="0.789062"
                                                    width="25.0611"
                                                    height="25.2778"
                                                    rx="5.77778"
                                                    fill="#0078D4"
                                                />
                                                <g clipPath="url(#clip0_126_3085)">
                                                    <path
                                                        d="M10.5709 17.1517C10.5709 17.06 10.6044 16.9807 10.6714 16.9137L14.1521 13.4277L10.6714 9.94181C10.6044 9.87481 10.5709 9.79546 10.5709 9.70378C10.5709 9.61209 10.6044 9.53274 10.6714 9.46574C10.7384 9.39874 10.8178 9.36523 10.9095 9.36523C11.0012 9.36523 11.0805 9.39874 11.1475 9.46574L14.8715 13.1897C14.9385 13.2567 14.972 13.336 14.972 13.4277C14.972 13.5194 14.9385 13.5988 14.8715 13.6658L11.1475 17.3897C11.0805 17.4567 11.0012 17.4902 10.9095 17.4902C10.8178 17.4902 10.7384 17.4567 10.6714 17.3897C10.6044 17.3227 10.5709 17.2434 10.5709 17.1517Z"
                                                        fill="white"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_126_3085">
                                                        <rect
                                                            width="10.6167"
                                                            height="10.8333"
                                                            fill="white"
                                                            transform="matrix(1 0 0 -1 10.5709 18.8447)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        <span>{slide.linkName ? slide.linkName : "Learn more"}</span>
                                    </a>}

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>)}
        </div>
    )
}