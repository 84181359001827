import Slider from "react-slick";
import {useNavigate} from "react-router-dom";
import { Right } from "../arrows/Right";
import { Left } from "../arrows/Left";
import { geturlPrefix } from "@src/utils/functions";

interface IProps {
    data: any
    tabindex: number
}

let settings = {
    infinite: false,
    // dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    // nextArrow: <Right/>,
    // prevArrow: <Left/>,
    // adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false
            }
        },
        {
            breakpoint: 1240,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false
            }
        },
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                centerMode: true,
                infinite: false,
            }
        },

    ]
};

export const LogoImageCard = (props: IProps) => {
    let {data, tabindex} = props;
    const navigate = useNavigate();
    const checkPageData = (itemkey: string) => {
        navigate("/" + itemkey);
    }
   
    const items = data.items ? data.items : [];
    let urlPrefix = geturlPrefix();
    return (
        <div className="ms-ai-card-cat-block ms-ai-logo-image-card">
            <div className="ms-ai-inner-page-sub-headeing">
                {data.title}
            </div>
            <div className="ms-ai-card-block">
                <div className="slider-container slide-paddings">
                    <Slider {...settings}>
                        {items.map((item: any, itemindex: number) => {
                            return (
                                <div className={`${item.comingsoon ? 'coming-soon-item' : ''}`} key={"image-card-item-" + itemindex}
                                     onClick={() => item?.content_key && checkPageData(item?.content_key)}>
                                    <div className="ms-ai-card-item" style={{backgroundColor: item.backgound ? item.backgound : "#0e2841",}}>
                                        <div className="ms-ai-card-bg-img ms-ai-image-card-logo">
                                            {item.thumbnail && <img src={urlPrefix + item.thumbnail} alt=""/>}
                                        </div>
                                        <div className="ms-ai-card-name"></div>
                                    </div>

                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    )
}