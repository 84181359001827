import { Header } from "../../header"
import { LeftBar } from "../../page/leftbar"
import "../../../assets/css/stories.css";
import TeamPage from "./TeamPage";

export const Nestle = () => {

    let urlPrefix = '';
    if ((window as any).location.origin.includes("localhost:3000")) {
        urlPrefix = '/assets/user/';
    }

    return (
        <div className="ms-ai-inner-page-right">
            <div className='layout-sub-item'>
                <div className="ms-ai-inner-page-main-title">IBM & Nestlé: AI-driven factory automation and compliance</div>

                <div className="innerpage-sub-title">Customer Situation</div>
                <p>Nestlé is an internationally recognized food enterprise that encompasses brands such as Gerber, Purina, and Stouffer’s. Food products must adhere to specific local legislations and regulations. Correct declaration of ingredients, allergens, and nutrients must be printed on the product package. A missing allergen statement could cause severe health problems, and a product recall could cost the manufacturer millions of US dollars in terms of operational costs, lost sales, and damaged brand identity.</p>

                <p>Nestlé’s product portfolio is complex with a high number of brands and constant product innovation and renovation, creating versions of recipes and artwork very often sold in multiple countries, hence carrying multi-lingual content.</p>

                <p>Nestlé wanted to establish automated and standardized quality checks prior to printing the packaging and publishing the data to customers.</p>

            </div>
            <div className="mi-ai-stories-container">
                <div className="mi-ai-stories-content">
                    <div className="mi-ai-stories-win-snapshot">
                        <h2>Win Snapshot</h2>
                        <div className="mi-ai-stories-win-content">
                            <strong>Transact Date:</strong> <p>April 2024</p>
                            <strong>Partner Area:</strong><p>EMEA</p>
                            <strong>Deal Value:</strong><p>$500k by end of CY24</p>
                            <strong>Solution Area(s): </strong> <p>Data & AI</p>
                            <strong>Industry:</strong> <p>Consumer Goods</p>
                            <strong>Key partner benefits leveraged:</strong><p>
                                <ul className="mi-ai-stories-list">
                                    <li>Strong MS/partner collaboration</li>
                                    <li>Build and Modernize AI Apps,<br/>AI/ML, and Analytics specializations</li>
                                </ul>
                            </p>

                        </div>
                    </div>
                    <div className="mi-ai-stories-main-content">
                        <div className="mi-ai-stories-solution">
                            <h2>Solution</h2>
                            <p>IBM helped Nestlé validate the feasibility and build the business case around increased efficiency in quality checks and reduction of reputational and regulatory risks. Once validated, IBM developed iReconcile, an application that utilizes AI Vision to automatically audit Nestlé products.</p>

                            <p>The iReconcile solution imports an image of the product label together with the respective XML file, which describes the characteristics of the product. An ML-pipeline is launched that qualifies the image and passes it to MVI for the detection of the areas of interest (i.e., ingredients, nutrients, allergens, product name). iReconcile then applies an optical character recognition (OCR) machine-learning model to identify the text and triggers text reconciliation based on a list of rules that are pre-defined by the business users. The inconsistencies are returned to the upstream application, iReconcile Frontend. A scale-out, significantly improved version that will use GPT-4 solution is preparing to roll-out on 5 more global markets.</p>

                            <ul className="mi-ai-stories-list">
                                <li>Azure Machine Learning</li>
                                <li>Document Intelligence</li>
                                <li>Azure AI Services - Computer Vision</li>
                                <li>Azure’s OCR</li>
                                <li>Azure Cognitive Services for Language</li>
                                <li>Azure OpenAI GPT 3.5/4.0 and trying GPT-4v and GPT-4o</li>
                                <li>Azure AI Search</li>
                                <li>Document Intelligence</li>
                                <li>SQL DB</li>
                                <li>AKS</li>
                            </ul>
                        </div>
                        <div className="mi-ai-stories-why-we-won">
                            <h2>Key Win Drivers</h2>
                            <ul className="mi-ai-stories-list">
                                <li><b>Strong Microsoft/partner collaboration:</b> cross-collaboration between Microsoft, IBM Consulting, and Nestlé has been paramount.</li>
                                <li><b>Azure specializations:</b> IBM holds Microsoft specializations in Build and Modernize AI Apps, Analytics, and AI/ML, making them one of the partners Nestlé chose to work with. IBM also invested in building Fabric competency with Microsoft partnership. IBM utilized the training and certification motion to enable 300+ consultants and certified 100+ on Fabric.</li>
                            </ul>
                        </div>
                        <div className="mi-ai-stories-business-impact">
                            <h2>Business Impact</h2>
                            <ul className="mi-ai-stories-list">
                                <li><b>Factory of the Future:</b> enhanced factory automation and audit reliability, thanks to Computer Vision and generative AI technology.</li>
                                <li><b>Increase in Azure consumption:</b> iReconcile will grow ACR up to 1.1 million by the end of FY25 and will scale up to 20 markets around the world.</li>
                                <li><b>Scalability:</b> iReconcile was the first use case for the customer to utilize GPT-4o. Due to its success, the customer now will be working with the Microsoft team to leverage this technology across 80 other use cases.</li>
                            </ul>
                        </div>
                        

                    </div>
                </div>
            </div>

            <TeamPage />


        </div>

    )
}