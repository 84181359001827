import AudioPlayer from "@src/components/audioplayer"
import { setPodcastInfo } from "@src/store/reducers/commonReducer"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@src/store/store";
import podcast from "@src/datas/podcasts";
import { useEffect, useState } from "react";

interface IProps {
    data: any
    pageDetails: any
    index: number
    pageTitleItem: boolean
}

export const TitleDescription = (props: IProps) => {
    const { data, pageDetails, index, pageTitleItem } = props;
    const dispatch = useDispatch();
    const podcastInfo = useSelector((state: RootState) => state.common.podcastInfo);
    const contents = useSelector((state: RootState) => state.common.contents);
    const menuItems = useSelector((state: RootState) => state.common.menu_items);
    const path = useSelector((state: RootState) => state.common.path);
    const [mainHead, setMainHead] = useState<string>('');


    const getFormattedDate = (): string => {
        const today = new Date();
        return today.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
        });
    };
    const contentTitle = () => {
        let title = data?.title;
        if (pageTitleItem && pageDetails?.title) {
            title = pageDetails?.title;
        }

        return title;
    }
    
    useEffect(() => {
        if(path.length > 1) {
            const menuId = path[1];
            const menuData = menuItems.find((x:any) => x._id === menuId);
            if(menuData) {
                if(menuData?.content_id) {
                    const contentData = contents.find((x:any) => x._id === menuData?.content_id);
                    if(contentData) {
                        setMainHead(contentData?.title)
                    } else {
                        setMainHead(menuData?.name)
                    }
                } else {
                    setMainHead(menuData?.name)
                }
            }
        }
    },[path])
    
    const title = contentTitle();
    const podcastExist = podcast.find((x: any) => x.key === title.toLowerCase());
    return (
        <div>
            {((index === 0 && !data?.group_id) || (pageDetails?.technical_pattern === true && index === 1)) && <span className="ms-ai-title-main-page-name">{mainHead}</span>}
            {(title && !data?.group_id)  && <div className="ms-ai-inner-page-title-btn-wrapper"><div className={`${data.classname ? data.classname : "ms-ai-inner-page-main-title"}`}
                dangerouslySetInnerHTML={{ __html: title }}></div>
                {/* {(podcastExist) ? <div className="ms-ai-podcast-btn-container"><button onClick={() => playAudio(podcastExist)} className="ms-ai-listen-btn"><img src={headset} width={13} />{`  Listen to Podcast  ${podcastExist?.duration ? podcastExist?.duration + 'm' : '5m'}`}</button><span className="ms-ai-podcast-beta"><img src={aiImage} width={13} /> Beta</span></div> : ''} */}
            </div>}

            {data.items.map((itm: { subtitle?: string, description?: string }, ind: number) => {
                return (
                    <div className={`ms-ai-inner-page-title-section ${(index === 0 && ind === 0 && !data?.group_id) && 'ms-ai-inner-page-main'}`}>
                        <div className="ms-ai-inner-page-main-desc ms-ai-left-area-description">
                            {itm.subtitle && <p className="innerpage-sub-title"> <div dangerouslySetInnerHTML={{ __html: itm.subtitle }} /></p>}
                            <p>
                                {itm.description && <div dangerouslySetInnerHTML={{ __html: itm.description }} />}

                            </p>

                        </div>
                        <div className="ms-ai-right-area-description">
                            {(!data?.group_id && index === 0 && ind === 0 && podcastExist) && <AudioPlayer podcast={podcastExist} title={title + ": Industry Vision"} />}
                        </div>

                    </div>
                )
            })}
            {(data.subtitle || data.description) && <div className="ms-ai-inner-page-main-desc">
                {data.subtitle && <p className="innerpage-sub-title">{data.subtitle && <div dangerouslySetInnerHTML={{ __html: data.subtitle }} />}</p>}
                {data.description && <p>
                    <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </p>}
            </div>}
        </div>
    )
}