import { BlogCards } from "../htmlelements/blog-cards";
import { BlogText } from "../htmlelements/blog-text";
import { CardSlider } from "../htmlelements/card-slider";
import { IconText } from "../htmlelements/icon-text";
import { ImageCard } from "../htmlelements/image-card";
import { ImageSlider } from "../htmlelements/image-slider";
import { ListView } from "../htmlelements/list";
import { LogoImageCard } from "../htmlelements/logo-image-card";
import { ResourceLinks } from "../htmlelements/resource-link";
import { SmallCardSlider } from "../htmlelements/small-card-slider";
import { TextTag } from "../htmlelements/text-tag";
import { TitleDescription } from "../htmlelements/title-description";

interface IProps {
    index: number
    content: any
    pageData: any
    pageDetails: any
}

export const ContentsArea = (props: IProps) => {
    const { index, content, pageData, pageDetails } = props;
    let titleDecSectionCount= 0;
    let pageTitleItem = false;

    let tabindex = 0;
    if (content?.htmlType === "title-description") {
        titleDecSectionCount++;
        if (pageDetails?.technical_pattern && titleDecSectionCount === 2) {
            pageTitleItem = true;
        } else if (!pageDetails?.technical_pattern && titleDecSectionCount === 1) {
            pageTitleItem = true;
        } else {
            pageTitleItem = false;
        }
    }
    let checkTitleSubValues = true;
    if(content?.htmlType === "title-description" && content.data) {
        if(content.data?.items.length > 0) {
            const datas = content.data?.items.filter((x:any) => x.subtitle.trim() !== "" || x.description.trim() !== "");
            if(datas.length === 0) {
                checkTitleSubValues = false;
            }
        } else {
            checkTitleSubValues = false;
        }
    }
    
    return (
            <div key={"content-area-" + index} className={` ${(content?.htmlType === "card-slider" ||content?.htmlType === "small-card-slider") && 'ms-ai-full-width'}`}>
                {(content?.htmlType === "title-description" && content.data && checkTitleSubValues) &&
                    <TitleDescription index={index} pageTitleItem={pageTitleItem} key={pageData.key} data={content.data} pageDetails={pageDetails} />}
                {(content?.htmlType === "card-slider" && content.data && (content?.data.items.length > 0 || content?.data?.content_type === "related-use-cases")) &&
                    <CardSlider tabindex={tabindex} key={pageData.key} data={content.data} />}
                {(content?.htmlType === "small-card-slider" && content.data && content?.data.items.length > 0) &&
                    <SmallCardSlider tabindex={tabindex} key={pageData.key} data={content.data} />}
                {(content?.htmlType === "text-tag" && content.data && content?.data.items.length > 0) &&
                    <TextTag tabindex={tabindex} key={pageData.key} data={content.data} />}
                {(content?.htmlType === "icon-text" && content.data && content?.data.items.length > 0) &&
                    <IconText tabindex={tabindex} key={pageData.key} data={content.data} />}
                {(content?.htmlType === "list" && content.data && content?.data.items.length > 0) &&
                    <ListView tabindex={tabindex} key={pageData.key} data={content.data}/>}
                {(content?.htmlType === "image-card" && content.data && content?.data.items.length > 0) &&
                    <ImageCard tabindex={tabindex} key={pageData.key} data={content.data} />}
                {(content?.htmlType === "logo-image-card" && content.data && content?.data.items.length > 0) &&
                    <LogoImageCard tabindex={tabindex} key={pageData.key} data={content.data} />}
                {(content?.htmlType === "resource-link" && content.data && content?.data.items.length > 0) &&
                    <ResourceLinks tabindex={tabindex} key={pageData.key} data={content.data} />}
                {(content?.htmlType === "image-slider" && content.data && content?.data.items.length > 0) &&
                    <ImageSlider tabindex={tabindex} key={pageData.key} data={content.data}
                        pageData={pageData} />}
                {(content?.htmlType === "blog-text" && content.data) &&
                    <BlogText tabindex={tabindex} key={pageData.key} data={content.data} />}
                {(content?.htmlType === "blog-cards" && content.data) &&
                    <BlogCards tabindex={tabindex} key={pageData.key} data={content.data} />}
            </div>
    )
}