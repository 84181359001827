import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { RootState } from '@src/store/store';
import { Link } from "react-router-dom";

const TopBar: React.FC = () => {
    const [activeMenu, setActiveMenu] = useState<string | null| undefined>(null);
    const [categories, setCategories] = useState<any[]>([]); // Store filtered categories separately
    const menuItems = useSelector((state: RootState) => state.common.menu_items);
    const contents = useSelector((state: RootState) => state.common.contents);
    const pageData = useSelector((state: RootState) => state.common.pageData);
    const path = useSelector((state: RootState) => state.common.path);

    const getCategoryId = (content_id: any): string | null => {
        if (!content_id) return null;
        const contentInfo = menuItems.find((x: any) => x._id === content_id || x.content_id === content_id);
        if (contentInfo?.category_id) {
            // setActiveMenu(contentInfo?.category_id); // Set active menu once
            return contentInfo.category_id;
        } else if (contentInfo?.parent_id) {
            return getCategoryId(contentInfo.parent_id);
        } else {
            return null;
        }
    };
    const getActiveUseCase = (menu_id: any, content_id: string|undefined) => {
        const menuInfo = menuItems.find((x) => x._id === menu_id || x.content_id === menu_id);
        if(menuInfo?.category_id) {
            setActiveMenu(content_id);
        } else if(menuInfo?.parent_id) {
            getActiveUseCase(menuInfo?.parent_id, menuInfo?.content_id)
        }
    }

    useEffect(() => {
        if (pageData?.details?._id) {
            const menuInfo = menuItems.find((x) => x.content_id === pageData?.details?._id)
            if(menuInfo && menuInfo?._id) {
                const category_id = getCategoryId(menuInfo._id);
                setCategories(contents.filter((x: any) => x.category_id === category_id));
                getActiveUseCase(menuInfo?._id, menuInfo?.content_id)
            }
            
        }
    }, [pageData?.details?._id, contents]);

    return (
        <div className="ms-ai-menu-container">
            <div className="ms-ai-menu">
                {categories.map((category: any) => (
                    
                    <Link to={category?.url_path ? "/" + category?.url_path : "#"}
                            key={category._id}
                            className={`ms-ai-menu-item ${activeMenu === category._id ? "ms-ai-menu-active" : ""}`}
                            onClick={() => setActiveMenu(category._id)}
                        >
                           
                            {category.title}
                            </Link>
                    
                   
                ))}
            </div>
        </div>
    );
};

export default TopBar;
