import React, { useEffect } from "react";
import {Header} from "../header";
import {ContentArea} from "./contentarea";
import { Tomtom } from "../stories/Tomtom";
import { Santender } from "../stories/Santender";
import { Marelli } from "../stories/Marelli";
import { Schindler } from "../stories/Schindler";
import { Softbank } from "../stories/Softbank";
import { Canadian } from "../stories/Canadian";
import { Dana } from "../stories/Dana";
import { Swiss } from "../stories/Swiss";
import { Lg } from "../stories/Lg";
import { Nestle } from "../stories/Nestle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/store/store";
import { fetchContents } from "@src/utils/api";
import { setContents } from "@src/store/reducers/commonReducer";
import TopBar from "./topbar";

interface IProps {
    pageData: any,
    tabindex: number
}

export const Page = (props: IProps) => {
    const {pageData, tabindex} = props;
    const dispatch = useDispatch();
    //let contentindex = tabindex+(datas.length+1)
    let contentindex = 0
    const contents = useSelector((state: RootState) => state.common.contents);
    const menuItems = useSelector((state: RootState) => state.common.menu_items);
    const accountInfo = useSelector((state: RootState) => state.common.accountInfo);
    const loadContents = async () => {
        const result = await fetchContents(accountInfo);
        if (result?.data) {
            dispatch(setContents(result.data));
        }
    }
    useEffect(() => {
        if(contents.length === 0) {
            loadContents();
        }
    },[accountInfo])
    return (
        <div className="ms-ai-wrapper">
            <div className="ms-ai-container">
                {/* Header section starts */}
                <Header/>
                {/* Header section ends */}
                {/* Inner Page Content Starts */}
                <div className="ms-ai-inner-page-wrapper">
                    <div className="ms-ai-inner-page-container ai-container">

                        {(contents.length > 0 && menuItems.length > 0) && <TopBar />}
                        {(pageData.customcomponent && pageData.content === 'tomtom' && <Tomtom /> )}
                        {(pageData.customcomponent && pageData.content === 'santender' && <Santender /> )}
                        {(pageData.customcomponent && pageData.content === 'marelli' && <Marelli /> )}
                        {(pageData.customcomponent && pageData.content === 'schindler' && <Schindler /> )}
                        {(pageData.customcomponent && pageData.content === 'softbank' && <Softbank /> )}
                        {(pageData.customcomponent && pageData.content === 'canadian-tire' &&  <Canadian /> )}
                        {(pageData.customcomponent && pageData.content === 'dana-farber' &&  <Dana /> )}
                        {(pageData.customcomponent && pageData.content === 'swiss' &&  <Swiss /> )}
                        {(pageData.customcomponent && pageData.content === 'lg' &&  <Lg /> )}
                        {(pageData.customcomponent && pageData.content === 'nestle' &&  <Nestle /> )}
                        {(pageData.customcomponent && pageData.component === 'tomtom' && <Tomtom /> )}
                        {(pageData.customcomponent && pageData.component === 'santender' && <Santender /> )}
                        {(pageData.customcomponent && pageData.component === 'marelli' && <Marelli /> )}
                        {(pageData.customcomponent && pageData.component === 'schindler' && <Schindler /> )}
                        {(pageData.customcomponent && pageData.component === 'softbank' && <Softbank /> )}
                        {!pageData.customcomponent && <ContentArea tabindex={contentindex} pageData={pageData}/>}
                       
                    </div>
                </div>
            </div>
        </div>
    )
}