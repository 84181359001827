import {BlogCards} from "../htmlelements/blog-cards";
import {BlogText} from "../htmlelements/blog-text";
import {CardSlider} from "../htmlelements/card-slider";
import {IconText} from "../htmlelements/icon-text";
import {ImageCard} from "../htmlelements/image-card";
import {ImageSlider} from "../htmlelements/image-slider";
import {ResourceLinks} from "../htmlelements/resource-link";
import {SmallCardSlider} from "../htmlelements/small-card-slider";
import {SmallCard} from "../htmlelements/small-card";
import {TextTag} from "../htmlelements/text-tag";
import {TitleDescription} from "../htmlelements/title-description";
import { LogoImageCard } from "../htmlelements/logo-image-card";


interface IProps {
    pageData: any
    tabindex: number
}

export const ContentArea = (props: IProps) => {
    let {pageData, tabindex} = props;
    const pageContent = pageData?.content ? pageData?.content : [];
    const pageDetails = pageData?.details ? pageData?.details : [];
    return (
        <div className="ms-ai-inner-page-right">
            <div className={`${(pageDetails.technical_pattern === true || pageDetails.technical_pattern === 1) ? "ms-ai-blog-page" : ""}`}>
                {pageContent.map((content: any, index: number) => {
                    if (content?.data.items) {
                        //tabindex+=content?.data.items.length;
                    }
                    tabindex = 0;
                    return (
                        <div key={"content-area-" + index}>
                            {(content?.htmlType === "title-description" && content.data) &&
                                <TitleDescription index={index} key={pageData.key} data={content.data} pageDetails={pageDetails} />}
                            {(content?.htmlType === "card-slider" && content.data) &&
                                <CardSlider tabindex={tabindex} key={pageData.key} data={content.data}/>}
                            {(content?.htmlType === "small-card-slider" && content.data) &&
                                <SmallCardSlider tabindex={tabindex} key={pageData.key} data={content.data}/>}
                            {(content?.htmlType === "small-card" && content.data) &&
                                <SmallCard tabindex={tabindex} key={pageData.key} data={content.data}/>}
                            {(content?.htmlType === "text-tag" && content.data) &&
                                <TextTag tabindex={tabindex} key={pageData.key} data={content.data}/>}
                            {(content?.htmlType === "icon-text" && content.data) &&
                                <IconText tabindex={tabindex} key={pageData.key} data={content.data}/>}
                            {(content?.htmlType === "image-card" && content.data) &&
                                <ImageCard tabindex={tabindex} key={pageData.key} data={content.data}/>}
                            {(content?.htmlType === "logo-image-card" && content.data) &&
                                <LogoImageCard tabindex={tabindex} key={pageData.key} data={content.data}/>}
                            {(content?.htmlType === "resource-link" && content.data) &&
                                <ResourceLinks tabindex={tabindex} key={pageData.key} data={content.data}/>}
                            {(content?.htmlType === "image-slider" && content.data) &&
                                <ImageSlider tabindex={tabindex} key={pageData.key} data={content.data}
                                             pageData={pageData}/>}
                            {(content?.htmlType === "blog-text" && content.data) &&
                                <BlogText tabindex={tabindex} key={pageData.key} data={content.data}/>}
                            {(content?.htmlType === "blog-cards" && content.data) &&
                                <BlogCards tabindex={tabindex} key={pageData.key} data={content.data}/>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}