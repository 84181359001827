import React from 'react';
import '../../../assets/css/storiesteam.css';

const TeamPage = () => {
  return (
    <div className="mi-ai-stories-win-team-container">
      <h1 className="mi-ai-stories-win-team-title">Winning Team</h1>
      <p>This victory would not have been possible without our united front as One Microsoft. Let me say Thanks to:</p>
      <div className="mi-ai-stories-win-team-sections">
        <div className="mi-ai-stories-win-team-section">
          <ul className="mi-ai-stories-win-team-list">
            <li>Maria Vittoria Tomani, Specialist Data & AI</li>
            <li>Carlo Tafuri, Azure Specialist</li>
            <li>Marco Guernieri, ISD</li>
            <li>Andrea Gandini, CSA Data & AI</li>
            <li>Marco Mille, CSAM</li>
            <li>Fabrizio Ruocco, AI Pod GBB</li>
            <li>Domenico Galbusera, CE</li>
            <li>Rosanna Carelli, DD</li>
            <li>Antonio Romeo, ATS</li>
            <li>Francesco Lo Iacono, AE</li>
            <li>Bill Bazzi, AE</li>
            <li>Mario Santagostino, ATU lead</li>
          </ul>
          <p>A special mention goes to <b>Andrea Condorelli</b> who has managed the Opportunity going way beyond his role of technical specialist but acting as trusted advisor.</p>
        </div>

      </div>

      <div className="mi-ai-stories-win-team-resources">
        <h3>Winwire Resources</h3>
        <ul className="mi-ai-stories-win-team-list">
          <li><a href="#">Subscribe to Al Design WinWires & LiveWires</a></li>
          <li><a href="#">Submit a Al Design Win - WinWire/LiveWire</a></li>
         
        </ul>
      </div>
    </div>
  );
};

export default TeamPage;

